import React from 'react';
import { Box } from '@mui/joy';
import { Tag } from '../Snippet';
import { useTranslation } from 'react-i18next';

const CellGeneric = ({ type, value, field }) => {

    const { t } = useTranslation()

    const label = value ? typeof value === 'string' ? value : value[field] : undefined

    return (
        <Box className={"list-element-cell cell-generics cell-generics-" + type}>
            {label
                ? <span className="name" title={label}>{label}</span>
                : <Tag>{t('missing')}</Tag>
            }
        </Box>
    )

}

export default CellGeneric;