import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellDate = ({ value, type }) => {
    const { formatDate } = useFormatter();
    
    let date;
    if (value && value !== undefined) {
        try {
            // è inutile capire il perchè non ce mai stato niente di spegabile
            //date = formatDate(new Date(value?.slice(0, -4) + 'Z'));
            date = formatDate(new Date(value));
        } catch (e) {
            date = '';
        }
    } else {
        date = '';
    }

    return <Box className={"list-element-cell cell-" + type}>{ date }</Box>
}

export default CellDate