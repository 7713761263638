import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { useFormatter } from 'src/mtska-frontend-app-component/utils';
import { LegalEntityAvatar, Tag } from '../Snippet';
import { Icon } from '../../assets';


const RelationshipCashFlowRecordGroup = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatCurrency, formatDate } = useFormatter();

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="coupled">
                    <Box className="label">{t('saltEdgeAccount')}</Box>
                    <Box className="value">{JSON.stringify(v)}</Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('totalValue')}</Box>
                    <Box className="value">{v?.amount ? formatCurrency(v?.amount) : '-'}</Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('transaction_at')}</Box>
                    <Box className="value">{formatDate(v?.transaction_at)}</Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('kind')}</Box>
                    <Box className="value">{v?.kind ? t(v?.kind) : <Tag color={"warning"} title={t("Unassigned")}><Icon icon="faWarning" />{t('Missing')}</Tag> }</Box>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipCashFlowRecordGroup



