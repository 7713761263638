import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/joy";
import InputIcon from '@mui/icons-material/Input';
import { AppContext } from "src/mtska-frontend-app-scaffolding";
import { useTranslation } from "react-i18next";
import { useDataProvider } from 'src/mtska-frontend-data-provider'


const Auth0LogoutButton = ({ variant = "plain", label = 'Logout', size = null, className = null }) => {
    const { appConfiguration } = useContext(AppContext);
    
    const { t } = useTranslation(); 
    const { logout } = useAuth0();
    const { doAction } = useDataProvider('auth/user');

    const handleClick = () => {
        doAction && doAction({
            action: "logout",
            model: 'auth/user',
            data: {
                clean:true
            }
        }).then(() => {
            sessionStorage.clear();
            logout({
                logoutParams: {
                    returnTo: process.env.REACT_APP_AUTH0_DEFAULT_LOGGEDOUT_URI || appConfiguration.auth0.DEFAULT_LOGGEDOUT_URI
                }
            })
        });
    }

    return (
        <Button
            className={className}
            size={size}
            onClick={handleClick}
            variant={variant}
        >
            {variant == 'plain' && (
                <InputIcon />
            )}
            {t(label)}
        </Button>
    );
};

export default Auth0LogoutButton;
