import React, { useMemo } from 'react'
import List from './List'

const Default = () => {
    return useMemo(() => <>
        <List />
    </>, [])
}

export default Default
