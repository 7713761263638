import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { List as ListComponent, useDrawer, DrawerButton, DownloadButton } from 'src/mtska-frontend-app-component'
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import ImportButton from '../../Import/components/ImportButton';

import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'auth/user';
const drawerRouteBase = 'users';
const moduleName = "Users";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();


    const apiRef = useGridApiRef();
    
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const { doAction, loadAll, items, total, loading, download, downloading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        <DrawerButton
                            drawerRoute={drawerRouteBase + '/detail'}
                            params={{
                                detailStack: params.detailStack ?? {},
                                relatedElements: params.relatedElements ?? {}
                            }}
                            label={t("New")}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.create']}
                        />,
                        <ImportButton // import
                            dataProviderName={dataProviderName}
                            callback={(data) => {
                                loadAll();
                            }}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.import']}
                        />,
                        <DownloadButton
                            dataProviderName={dataProviderName}
                            onClick={download}
                            downloading={downloading}
                            disabled={(userCapabilities && !userCapabilities[dataProviderName + '.export']) || downloading}
                        />
                    ]}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                    doAction={doAction}
                />
            )}
        </>
    );
};

export default List;


