import React, { useMemo, useState } from 'react'
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';

const Default = () => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState()

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            
        </Box>
        <List filters={filters} />
    </>, [filters])

}

export default Default;


