import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Detail as DetailComponent, InfoHeader, useDetailConfigOverrider } from 'src/mtska-frontend-app-component'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { AppContext } from 'src/mtska-frontend-app-scaffolding'

const dataProviderName = 'incomestatements/contoeconomicoattrrulegroup'
const model = 'incomestatements/contoeconomicoattributionrulegroup'
const modelExposedName = 'Regola di attribuzione Conto Economico'
const attrRuleDataProviderName = 'incomestatements/contoeconomicoattrrule'

const Create = (params) => {

    const {
        detailConfigOverride,
        filters,
        filtersFromList = [],
        handleClose = () => { },
        multipart,
        uid
    } = params || {}

    const { userCapabilities, existingModules } = useContext(AppContext)
    const { item: config } = useDataProvider('configs/config')
    const { doAction, item, loadOne, errorMessage, errors, saving } = useDataProvider(dataProviderName, multipart)
    const { overrideDetailFields } = useDetailConfigOverrider()
    const { t } = useTranslation()

    const { modelName } = existingModules
    const detailConfig = detailConfigOverride
        ? overrideDetailFields(existingModules[dataProviderName].detailConfig, detailConfigOverride)
        : existingModules[dataProviderName].detailConfig

    useEffect(() => {
        loadOne(filters?._id)
    }, [])

    const handleSave = async (data) => {
        const action = 'create'
        doAction && doAction({ action, model: model ? model : dataProviderName, data }).then((data) => {
            const { item } = data || {}
            item && handleClose(uid, item.data)
        })
    }

    const configFields = config?.data?.modules[attrRuleDataProviderName]?.detailConfig?.tabs[0]?.fieldsets[0]?.fields || {}
    const acceptedOptions = configFields.contoEconomicoRecordField?.options || []

    const contoEconomicoAttributionRules = filtersFromList
        .filter(({field, value}) => acceptedOptions.some(option => value && (option.value === field)))
        .map(({ field: contoEconomicoRecordField, operator, value: comparator }) => ({ comparator, contoEconomicoRecordField, operator }))

    return <>
        <DetailComponent
            values={{ ...item?.data, contoEconomicoAttributionRules }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={() => { }}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('Newf {{itemname}}', { itemname: t(modelExposedName) })}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create newf {{itemname}}', { itemname: t(modelExposedName) })}
                    subtitle={t('Fill the basic information to create a newf {{itemname}} that you will be able to edit via the standard interface', { itemname: t(modelExposedName) })}
                />
            }
            {...params}
        />
    </>
}

export default Create;


