import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadOne } from 'src/mtska-frontend-data-provider/hooks/useLoadOne.hooks';

const dataProviderName = 'cars/car';
const wizardName = 'Send Notification';
const wizardAction = 'notificationSend';
const wizardObject = 'Notification';


const NotificationSend = (params) => {
    const { t } = useTranslation();
    const { userCapabilities, existingModules } = useContext(AppContext);


    const searchParams = new URLSearchParams(document.location.search);

    const id = params.itemId || searchParams.get('id') ;

    // DATA PROVIDER
    const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction,
        reloadCounter,
    } = useDataProvider(dataProviderName, params.multipart);

    useLoadOne({loadOne, params:{id:id}, reloadCounter})
    
    const {
        loadAll: modelLoadAll,
        items: modelItems
    } = useDataProvider('notifications/model');

    useEffect(()=> {
        modelLoadAll();
    },[]);
    

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "notification",
                "fieldsets": [
                    {
                        "name": "notification",
                        "fields": {
                            "notification_model": {
                                "type": "select",
                                "options": modelItems.map(itm => {
                                    return  {
                                    label: itm.name,
                                    value: itm._id
                                }}),
                                "required": true,
                                "className":"col-12"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const itemsReadeable = () => {

        if(!item?.data) return '';
       
        const elements = Array.isArray(item) ? item : [item.data];
        const readeables = elements.map((el) => {
            return el.plate ? el.plate : el.setup?.make + " " + el.setup?.model;
        });
        return readeables.join(', ');
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "plate": item?.data?.plate }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={true}
            // canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t(
                        'Send a {{wizardObject}} related to {{carIdentifier}}',
                        {
                            wizardObject: wizardObject,
                            carIdentifier: itemsReadeable(),
                        }
                    )}
                />
            }
            {...params}
        />
    </>
}

export default NotificationSend;


