export const SPLIT_RECORD_CONSTANTS = {
    action: 'split',
    config: {
        netValue: {
            label: 'Valore imponibile',
            type: 'number'
        },
        description: {
            label: 'Descrizione',
            type: 'text'
        },
        pianoContiCeId: {
            label: 'Piano dei conti',
            optionId: 'id',
            optionLabel: 'name',
            type: 'select'
        }
    },
    model: 'incomestatements/contoeconomicorecord',
    modelPianoConti: 'incomestatements/pianocontice',
    title: 'SplitRecord',
    wizardObject: 'ContoEconomicoRecord'
}
