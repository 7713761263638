import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/joy'
import { useDataProvider } from 'src/mtska-frontend-data-provider'

const LanguageSwitcher = () => {
    const visible = true;
    const { t, i18n } = useTranslation();
    const { doAction } = useDataProvider('auth/user');

    const handleSwitch = (locale) => {
        doAction && doAction({
            action: "changeLocale",
            model: 'auth/user',
            data: {
                locale: locale
            }
        }).then(() => {
            i18n.changeLanguage(locale);
        });
    }

    return (
        <>
            {visible && (
                <Box className="languageSwitcher">
                    {i18n.language == "it" && (
                        <Box onClick={() => handleSwitch('en')} className="flag">
                            <svg width="24" height="16" viewBox="0 0 3 2" xmlns="http://www.w3.org/2000/svg">
                                <rect width="1" height="2" fill="#008C45" />
                                <rect width="1" height="2" x="1" fill="#ffffff" />
                                <rect width="1" height="2" x="2" fill="#CD212A" />
                            </svg>
                        </Box>
                    )}

                    {i18n.language == "en" && (
                        <Box onClick={() => handleSwitch('it')} className="flag">
                            <svg width="24" height="16" viewBox="0 0 60 30" xmlns="http://www.w3.org/2000/svg">
                                <clipPath id="t">
                                    <path d="M0,0 v30 h60 v-30 z" />
                                </clipPath>
                                <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
                                <path d="M0,0 L60,30 M60,0 L0,30" stroke="#ffffff" stroke-width="6" />
                                <path d="M0,0 L60,30 M60,0 L0,30" stroke="#C8102E" stroke-width="4" />
                                <path d="M30,0 v30 M0,15 h60" stroke="#ffffff" stroke-width="10" />
                                <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6" />
                            </svg>
                        </Box>
                    )}
                </Box>
            )}
        </>
    )
}

export default LanguageSwitcher;
