import React from "react"

import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { Icon } from '../../assets';
import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'

const InputTextElement = ({ id, name, value, type, label, placeholder, icon, hint, error, invalid, editing, required, autoFocus, disabled, readonly: readOnly, className, onChange, onKeyDown, slotProps }) => {
    const { t } = useTranslation()

    if (!editing) readOnly = true;

    let outerClass = "form-element-control";
    outerClass += (editing) ? " editing" : " reading";
    outerClass += (error) ? " error" : "";
    outerClass += (required) ? " required" : "";
    outerClass += (disabled) ? " disabled" : "";
    outerClass += (readOnly) ? " readonly" : "";

    return (
        <div className={("form-element " + type + " " + className).trim()}>
            <FormControl error={error} className={(invalid) ? outerClass + " form-element-error" : outerClass}>
                <FormLabel className="form-element-label" htmlFor={name}>{t(label)}</FormLabel>
                {type === 'date'
                    ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={disabled}
                                error={error}
                                id={id}
                                format='DD/MM/YYYY'
                                name={name}
                                onChange={onChange}
                                readOnly={readOnly}
                                required={required}
                                slotProps={{
                                    textField: {
                                        autoComplete: 'off',
                                        autoFocus,
                                        className: 'form-element-field',
                                        placeholder: t(placeholder),
                                        sx: {
                                            height: 46,
                                            '& .MuiInputBase-root': {
                                                height: '100%',
                                                transition: 'background-color 0.3s, border-color 0.3s',
                                                '&.Mui-disabled, &.MuiInputBase-readOnly': {
                                                    backgroundColor: 'var(--neutral-100)'
                                                },
                                                '&:not(.Mui-disabled):not(.MuiInputBase-readOnly)': {
                                                    backgroundColor: '#fbfcfe'
                                                },
                                                '&:Mui-focused': {
                                                    backgroundColor: '#fff'
                                                }
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                border: '0.5px solid var(--neutral-200)',
                                                '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent'
                                            }
                                        }
                                    },
                                    variant: 'outlined',
                                    ...slotProps || {}
                                }}
                                value={value || null}
                            />
                        </LocalizationProvider>
                    )
                    : (
                        <Input
                            id={id}
                            type={type}
                            name={name}
                            value={value}
                            error={error}
                            invalid={invalid}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            variant="outlined"
                            autoComplete="off"
                            className="form-element-field"

                            autoFocus={autoFocus}
                            placeholder={t(placeholder)}
                            disabled={disabled}
                            readOnly={readOnly}
                            required={required}

                            startDecorator={(icon) ? (
                                <Icon icon={icon} />
                            ) : (
                                <></>
                            )}
                            endDecorator={(invalid) ? (
                                <Icon icon="faCircleExclamation" />
                            ) : (
                                <></>
                            )}
                            
                            slotProps={slotProps}
                        />
                    )
                }
                <FormHelperText className="form-element-hint">
                    {(invalid) ? (
                        <>
                            {error ? error.message : 'Messaggio di errore'}
                        </>
                    ) : (
                        <>
                            {hint}
                        </>
                    )}
                </FormHelperText>
            </FormControl>
        </div>
    );
};


export default InputTextElement;
