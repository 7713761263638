import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Text } from "../Typography";

const DocumentAvatar = ({ document = null }) => {

    const { formatDate } = useFormatter();
    const { t } = useTranslation();

    const apiBasePath = process.env.REACT_APP_API_ENDPOINT || '';

    return (
        <Box className="document-avatar">
            {/* { <textarea>{JSON.stringify(document)}</textarea> } */}
            <Text className="label id">{t('name')}</Text>
            <Box className="value document-name">{document?.name}</Box>
            <Text className="label id">{t('type')}</Text>
            <Box className="value document-type">{t(document?.type)}</Box>

            {
                document?.documentIdentifier && (
                    <>
                        <Text className="label id">{t('documentIdentifier')}</Text>
                        <Box className="value document-type">{document?.documentIdentifier}</Box>
                    </>
                )
            }
            {
                document?.expire_at && (
                    <>
                        <Text className="label id">{t('expire_at')}</Text>
                        <Box className="value document-type">{formatDate(document?.expire_at)}</Box>
                    </>
                )
            }
            {
                document?.url && (
                    <Box className="action">
                        <a href={apiBasePath + document?.url} target="_blank" class="buttonized input-button small MuiButton-variantSolid">{t('show')}</a>
                    </Box>
                )
            }
        </Box>
    )
}

export default DocumentAvatar;