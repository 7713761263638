import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import PersonAvatar from '../Snippet/PersonAvatar';

const RelationshipEmploymentsWithPerson = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <PersonAvatar firstname={v?.person?.firstname || v?.person?.name} lastname={v?.person?.lastname} url={v?.person?.picture?.url} withname={true}  item={v?.person}/>
                <Box className="coupled">
                    <Box className="label">{t('Role')}</Box>
                    <Box className="value">
                        <span>{v?.role}</span>
                    </Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('Legal Entity')}</Box>
                    <Box className="value">
                        <span>{v?.legalEntity?.name}</span>
                    </Box>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipEmploymentsWithPerson



