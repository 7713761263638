import React, { useMemo, useState } from 'react'
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';

const Default = () => {
    const { t } = useTranslation();
    const now = new Date();
    const startMonth = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-01';

    const [filters, setFilters] = useState()

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Documents"
                icon="faCars"
                dataProviderName={'statistics/item/count/documents/document'}
                filterbutton={{
                    visible: true,
                    label: t("All"),
                    callback: (newfilters) => setFilters(newfilters)
                }}
            />
            <KPI
                title="With file"
                icon="faCar"
                dataProviderName={'statistics/withfile/count/documents/document'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "size",
                        operator: "isNotEmpty"
                    }]
                }}
            />
            <KPI
                title="Without file"
                icon="faCar"
                dataProviderName={'statistics/withoutfile/count/documents/document'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "size",
                        operator: "isEmpty"
                    }]
                }}
            />
        </Box>
        <List filters={filters} />
    </>, [filters])

}

export default Default;


