import React, { useContext, useEffect, useState } from 'react'
import { useDataProvider } from '../../../mtska-frontend-data-provider'
import { AppContext } from '../../../mtska-frontend-app-scaffolding'
import { Box } from '@mui/joy'
import { LegalEntitySelect } from './LegalEntitySelect'
import { Text } from '../Typography'
import { useTranslation } from 'react-i18next'

const LegalEntitySelector = () => {

    const { t } = useTranslation();
    const { appConfiguration, filterByLegalEntity, saveSetting, settings, setFilterByLegalEntity } = useContext(AppContext)

    const { loadAll } = useDataProvider('companies/selectablelegalentity')

    const [options, setOptions] = useState()

    useEffect(() => {

        const onError = () => {
            setOptions([])
        }

        const onSuccess = (data) => {
            const { items } = data || {}
            const { data: options } = items || {}

            let optionAll = [];
            if (appConfiguration?.legalEntitySelector?.canSeeAllButton === true) {
                optionAll = [{
                    id: "all",
                    name: t("all"),
                }];
            }

            setOptions([
                ...optionAll,
                ...options
            ] || [])
        }

        loadAll().then(onSuccess).catch(onError)

    }, [])

    const onChange = (e, id) => {
        if (e && id !== filterByLegalEntity) {
            const name = 'selectedLegalEntity'
            const value = { id }
            setFilterByLegalEntity(id)
            saveSetting({ name, value }).then(() => { }).catch(() => console.error('catch saveSetting'))
        }
    }

    const legalEntitySelectProps = { filterByLegalEntity, onChange, options }

    return (
        <>
            <LegalEntitySelect {...legalEntitySelectProps} />
            
            {appConfiguration?.legalEntitySelector?.active && settings && !filterByLegalEntity && (
                <Box className="dialog dialog-confirm">
                    <Box className="dialog-backdrop" />
                    <Box className="dialog-content">
                        <Text level="h3">{appConfiguration?.legalEntitySelector?.title}</Text>
                        <p>{appConfiguration?.legalEntitySelector?.subtitle}</p>
                        <Text level="body-md">
                            <LegalEntitySelect {...legalEntitySelectProps} />
                        </Text>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default LegalEntitySelector