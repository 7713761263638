import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import { Backdrop } from "@mui/material"

const dataProviderName = 'calendars/carcalendar';
const wizardName = 'Calendar management';
const wizardAction = 'calendarManagement';
const wizardObject = 'Calendar';


const CarCalendarManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const [drawable, setDrawable] = useState(false);


    // DATA PROVIDER
    const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, false);

    const modelName = existingModules.modelName

    let detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "configuration",
                        "fields": {
                            "status": {
                                "type": "select",
                                "options": [
                                    { "label": "active", "value": "active" },
                                    { "label": "paused", "value": "paused" }
                                ],
                                "required": true,
                                "className": "col-8",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "bookabilityStart_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "bookabilityEnd_at": {
                                "type": "date",
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "minimumDaysBookable": {
                                "type": "number",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "maximumDaysBookable": {
                                "type": "number",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "recoveryTimeAtReenter": {
                                "type": "number",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "requiredStartDay": {
                                "type": "select",
                                "options": [
                                    { label: t('sunday'), value: 0 },
                                    { label: t('monday'), value: 1 },
                                    { label: t('tuesday'), value: 2 },
                                    { label: t('wednesday'), value: 3 },
                                    { label: t('thursday'), value: 4 },
                                    { label: t('friday'), value: 5 },
                                    { label: t('saturday'), value: 6 },
                                    { label: t('no_restriction'), value: null },
                                ],
                                "required": true,
                                "className": "col-3",
                                "placeholder": "select",
                                "icon": "faCalendarDay"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    if (item?.data) {
        detailConfig.tabs[0].fieldsets.push(
            {
                name: "booking",
                fields: {
                    calendar: {
                        type: "calendarBooking",
                        calendar_id: item.data._id,
                        className: "col-12"
                    }
                }
            }
        )
    }

    useEffect(() => {
        if (params.car?.calendar?._id) {
            loadOne(params.car?.calendar?._id).then(() => {
                //debugger;

                setDrawable(true);
            });
        } else {
            // nuovo calendario
            setDrawable(true);
        }
    }, []);

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const car = params.car;
    return (
        !drawable ? <Backdrop open={!drawable} style={{ backgroundColor: "white" }}><CircularProgress /></Backdrop> :
            <DetailComponent
                values={{
                    "car_id": car?._id,
                    "legal_entity_id": car?.legalEntity?._id,
                    "_id": item?.data?._id,
                    "status": item?.data?.status,
                    "bookabilityStart_at": item?.data?.bookabilityStart_at,
                    "bookabilityEnd_at": item?.data?.bookabilityEnd_at,
                    "minimumDaysBookable": item?.data?.minimumDaysBookable,
                    "maximumDaysBookable": item?.data?.maximumDaysBookable,
                    "recoveryTimeAtReenter": item?.data?.recoveryTimeAtReenter,
                    "requiredStartDay": item?.data?.requiredStartDay,
                }}
                modelName={modelName}
                detailConfig={detailConfig}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                title={t(wizardName)}
                canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
                header={
                    <InfoHeader
                        view="wizard"
                        title={t(wizardName)}
                        subtitle={t('Define a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject: wizardObject, carIdentifier: (car?.plate ? car?.plate : car?.setup?.make + " " + car?.setup?.model) })}
                    />
                }
                {...params}
            />
    )
}

export default CarCalendarManagement;


