import { CHILD_MIN_WIDTH } from "../../../excelPreview.constants"

export const getStyles = ({ childrenLength, index }) => ({
    childS: {
        borderTop: '1px solid #e0e0e0',
        minWidth: CHILD_MIN_WIDTH,
        width: '100%'
    },
    columnS: {
        alignContent: 'space-around',
        backgroundColor: '#f0f4f8',
        borderLeft: index > 0 && '1px solid #e0e0e0',
        minWidth: `${childrenLength * CHILD_MIN_WIDTH}px`,
        width: '100%',
    }
})
