import React, { useState, useEffect } from "react";
import { TextField, Typography, FormControlLabel, RadioGroup, FormControl, Radio, Box, Dialog } from '@mui/material';
import { PersonAvatar } from '../Snippet';
import { Button } from '../Button'
import { Text } from '../Typography';
import { useTranslation } from 'react-i18next';
import { useDataProvider } from "src/mtska-frontend-data-provider";

const BookingFormDialog = ({ open, onClose, onSave, dateRange, warningMessage, possibleDrivers, calendar }) => {
    const { t } = useTranslation();
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [localSaving, setLocalSaving] = useState(false);

    // DATA PROVIDER
    const {
        doAction,
        success
    } = useDataProvider('calendars/carcalendarrecord', false);

    const handleSave = async (data) => {
        doAction && doAction({ action: 'create', model: 'calendars/carcalendarrecord', data: data });
    }

    useEffect(() => {
        if (success) {
            onSave()
        }
        setLocalSaving(false);
    }, [success])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const data = {
            start_at: dateRange[0].format('YYYY-MM-DD'),
            end_at: dateRange[1].format('YYYY-MM-DD'),
            isApproved:'true',
            car_calendar_id:calendar.data._id,
            employment_id:selectedDriverId,
            legal_entity_id:calendar.data.legalEntity._id,
            notes: formData.get('notes')
        }

        handleSave(data);
        setLocalSaving(true);
    };

    return (
        <Dialog className="dialog dialog-confirm" open={open} onClose={onClose}>
            <Box className="dialog-backdrop"></Box>
            <Box className="dialog-content">
                <Text level="h3">{t('New booking: {{start_at}} - {{end_at}}', { start_at: dateRange[0]?.format('DD/MM/YYYY'), end_at: dateRange[1]?.format('DD/MM/YYYY') })}</Text>
                {warningMessage && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        ⚠️ {warningMessage}
                    </Typography>
                )}
                <Text level="body-md">{t('Select the driver associated to this booking')}</Text>

                <form onSubmit={handleSubmit} id="booking-form" style={{ paddingTop: '20px' }}>
                    {/* Lista selezionabile dei driver */}
                    <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
                        <RadioGroup
                            name="selectedDriver"
                            value={selectedDriverId} // Stato per memorizzare l'ID selezionato
                            onChange={(e) => setSelectedDriverId(e.target.value)}
                        >
                            {possibleDrivers.map((driver) => (
                                <FormControlLabel
                                    key={driver.employment._id}
                                    value={driver.employment._id}
                                    control={<Radio />}
                                    label={
                                        <PersonAvatar
                                            firstname={driver.employment.person.firstname}
                                            lastname={driver.employment.person.lastname}
                                            withname={true}
                                            detail={true}
                                            item={driver} // Passa l'oggetto completo se necessario
                                        />
                                    }
                                    sx={{ alignItems: 'center', mt: 1 }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    {/* Altri campi del form */}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem 0' }}>
                        <TextField label="Note" multiline rows={4} fullWidth />
                    </div>
                </form>

                <Box className="dialog-actions" style={{ paddingTop: '10px' }}>
                    <Button variant="soft" className="dialog-ko" onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant="solid" className="dialog-ok" type="submit" form="booking-form" disabled={!selectedDriverId || localSaving}>{t('Save')}</Button>
                </Box>
            </Box>
        </Dialog>
    )

};

export default BookingFormDialog;