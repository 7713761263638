import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';
const wizardName = 'Car association';
const wizardObject = 'Car';
const wizardAction = 'carAssociation';

const CarAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { 
        item, 
        errorMessage, 
        errors, 
        saving, 
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "assignment_period": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "assignment_start_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faCalendarHeart"
                            },
                            "assignment_end_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faCalendarXmark"
                            },
                            "available_cars": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "associated": {
                                "type": "carlist",
                                "defaultSelectedValues": [],
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/car",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "isPool",
                                            "operator": "isNot",
                                            "value": "true"
                                        },
                                        {
                                            "operator": "notHasActivePools"
                                        },
                                    ]
                                },
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
        
    const handleSave = (data) => {
        doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{firstname}} {{lastname}}', { wizardObject:wizardObject, firstname: item.data.firstname, lastname: item.data.lastname })}
                />
            }
            {...params}
        />
    </>
}

export default CarAssociation;


