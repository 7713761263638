import React from 'react'

import { useTranslation } from 'react-i18next';
import DriverHelpIt from './Driver.help.it';
import DriverHelpEn from './Driver.help.en';

const DriverHelp = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <>
            {currentLanguage === 'it' && <DriverHelpIt />}
            {currentLanguage === 'en' && <DriverHelpEn />}
        </>
    );
    
}

export default DriverHelp;


