import { useTranslation } from 'react-i18next'

export const useFormatter = () => {
    const { t, i18n } = useTranslation();

    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);
    const datetimeformatter = Intl.DateTimeFormat(i18n.resolvedLanguage, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Per il formato 24 ore
    })

    const distanceformatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        maximumFractionDigits: 0
    });

    const numberformatter = Intl.NumberFormat(i18n.resolvedLanguage);
    const percentformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: "percent" })
    const currencyformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'EUR', maximumFractionDigits: 5 })
    const compactCurrencyFormatter = (value) => {
        const absValue = Math.abs(value);
        let result = '';

        if (absValue >= 1_000_000) {
            result = (value / 1_000_000).toFixed(1) + 'M€';
        } else if (absValue >= 1_000) {
            result = (value / 1_000).toFixed(1) + 'K€';
        } else {
            result = currencyformatter.format(value);
        }

        return result;
    }

    const byteFormatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        notation: "compact",
        style: "unit",
        unit: "kilobyte",
        unitDisplay: "narrow",
    });

    const formatDate = (string) => {
        let formatted = '-';
        if (string) {
            try {
                formatted = dateformatter.format(new Date(string));
            } catch (e) {
                formatted = <em>!{JSON.stringify(string)} </em>;
            }
        }
        return formatted;
    }

    const formatDatetime = (string) => {
        let formatted = '-';
        if (string) {
            try {
                formatted = datetimeformatter.format(new Date(string));
            } catch (e) {
                formatted = <em>!{JSON.stringify(string)} </em>;
            }
        }
        return formatted;
    }

    const formatDateForInput = (string) => {
        let formatted = '';
        if (string) {
            try {
                formatted = new Date(string).toLocaleDateString('en-CA');
            } catch (e) {
                // do nothing
            }
        }
        return formatted;
    }

    const formatDatetimeForInput = (string) => {
        let formatted = '';
        if (string) {
            try {
                const dateObj = new Date(string);

                const parts = datetimeformatter.formatToParts(dateObj);

                // Ricostruisci la data nel formato YYYY-MM-DDTHH:MM
                const year = parts.find(part => part.type === 'year').value;
                const month = parts.find(part => part.type === 'month').value;
                const day = parts.find(part => part.type === 'day').value;
                const hour = parts.find(part => part.type === 'hour').value;
                const minute = parts.find(part => part.type === 'minute').value;

                // Formatta la data per datetime-local (senza secondi e millisecondi)
                formatted = `${year}-${month}-${day}T${hour}:${minute}`;
            } catch (e) {
                // do nothing
            }
        }

        return formatted;
    }

    const formatDistance = (string) => {
        let formatted = '-';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = distanceformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatNumber = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = numberformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatPercentage = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = percentformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatCurrency = (string, compact = false) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                if (compact) {
                    formatted = compactCurrencyFormatter(string);
                } else {
                    formatted = currencyformatter.format(string);
                }
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatCurrencyVariable = (string, currency) => {
        let cf;
        switch (currency) {
            case 'EUR':
                cf = currencyformatter;
                break;
            case 'USD':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'USD' });
                break;
            case 'GBP':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'GBP' });
                break;
            case 'CHF':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'CHF' });
                break;
        }

        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = cf.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatBytes = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = byteFormatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    return {
        formatDate,
        formatDatetime,
        formatDateForInput,
        formatDatetimeForInput,
        formatDistance,
        formatNumber,
        formatPercentage,
        formatCurrency,
        formatCurrencyVariable,
        formatBytes
    }

};
