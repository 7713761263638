import { Default } from "./views";

const Module = {
    name: "cashflowreports",
    defaultRoutePath: "/cashflows/cashflowreport",
    menuItemScope: "cashflows",
    menuItemName: "cashflowreports",
    routes: [
        {
            path: "/cashflows/cashflowreport",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                }
            ],
        },
    ],
    drawerRoutes: [
    ],

}

export default Module;
