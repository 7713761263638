import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';


const wizardName = 'Send Notification';
const wizardAction = 'notificationSend';
const wizardObject = 'Notification';

const Notify = (params) => {

   const { t } = useTranslation();
   const { userCapabilities, existingModules } = useContext(AppContext);


   const searchParams = new URLSearchParams(document.location.search);

   const id = params.itemId || searchParams.get('id');
   const { close } = useDrawer();
   const {
      item,
      getOne,
      loadOne,
      errorMessage,
      errors,
      saving,
      doAction,
      reloadCounter,
   } = useDataProvider(params.dataProviderName);



   const {
      loadAll: modelLoadAll,
      items: modelItems
   } = useDataProvider('notifications/model');

   useEffect(() => {
      modelLoadAll();
   }, []);


   const modelName = existingModules.modelName;

   const detailConfig = {
      "tabs": [
         {
            "name": "notification",
            "fieldsets": [
               {
                  "name": "notification",
                  "fields": {
                     "notification_model": {
                        "type": "select",
                        "options": modelItems.map(itm => {
                           return {
                              label: itm.name,
                              value: itm._id
                           }
                        }),
                        "required": true,
                        "className": "col-12"
                     },
                  }
               }
            ]
         }
      ],
      "validation": []
   }

   const handleSave = (data) => {
      console.log("Notify handleSave", data);
      doAction({
         action: "notificationSend",
         model: params.dataProviderName,
         data: params.items.map((item) => {
            return {
               id: item,
               ...data,
            };
         })
      })
         .then((data) => params.handleClose(params.uid, data.item.data))
         .catch(() => console.error('catch handleSave'));
   }

   return <>
      <DetailComponent
         // values={{ "ids": params.items }}
         modelName={modelName}
         detailConfig={detailConfig}
         save={handleSave}
         saving={saving}
         errors={errors}
         errorMessage={errorMessage}
         title={t(wizardName)}
         canSave={true}
         // canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
         header={
            <InfoHeader
               view="wizard"
               title={t(wizardName)}
               subtitle={t(
                  'Send a {{wizardObject}} related to {{carIdentifier}}',
                  {
                     wizardObject: 'wizardObject',
                     carIdentifier: params.items.map((id) => {
                        const item = getOne(id);
                        return item?.plate ?? item?.name ?? item?.firstname + ' ' + item?.lastname;
                     }).join(', '),
                  }
               )}
            />
         }
         {...params}
      />
   </>


}

export default Notify;

