/* eslint-disable no-console */
import React, { useContext } from 'react';

import { Box } from '@mui/joy';
import { Auth0LoginButton, Auth0LogoutButton, PickARoleButton } from '../Auth0';
import { Text } from 'src/mtska-frontend-app-component';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useTranslation } from 'react-i18next';

const SignIn = () => {

    const { t } = useTranslation();
    const { appConfiguration, loginError, loginOptions } = useContext(AppContext);

    return (
        <Box className={"app-login-panel"+(loginOptions ? " optionable" : '')}>
            <Box className="logo" title={appConfiguration.fullName} align="center" >
                <div dangerouslySetInnerHTML={{ __html: appConfiguration.login.logoSvg }} />
            </Box>

            <Text level="h4">{appConfiguration.login.suiteName}</Text>
            <Text level="h1">{appConfiguration.app.fullName}</Text>
            <Text level="body-md">{appConfiguration.login.prompt}</Text>
            {
                loginError && loginError !== true ? (// se loginError è boolean true, loginOptions è popolato
                    <>
                    <Text level="body-md error">{t(loginError)}</Text>
                    <Auth0LogoutButton className="input-button big" size="regular" variant="solid" label="Retry"/>
                    </>
                ) : loginOptions ? (
                    <>
                    <Text level="body-md options">{t(loginOptions.title)}
                    <Box className="loginByRoleWrapper">
                    {
                        loginOptions.roles.map((role) => (
                            <PickARoleButton className="input-button medium" role={role} label={"Login as "+role}/>
                        ))
                    }
                    </Box>
                    </Text>

                    </>
                ) : (
                    <Auth0LoginButton className="input-button big" />
                )
            }
        </Box>
    );
};

// export default withRouter(SignIn);
export default SignIn;

