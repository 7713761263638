import React, { useContext } from 'react';
import { List, ListItem } from '@mui/joy';
import { RouterAnchor as Anchor } from "../RouterAnchor"
import { AppContext } from '../../../mtska-frontend-app-scaffolding';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'src/mtska-frontend-app-component/assets';


const ModuleMenu = ({ }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { filterByLegalEntity, currentModuleNavigationData, settings, userCapabilities, appConfiguration } = useContext(AppContext);

    // console.info('ModuleMenu', 'currentModuleNavigationData', currentModuleNavigationData);
    // console.info('ModuleMenu', 'appConfiguration?.legalEntitySelector?.active', appConfiguration?.legalEntitySelector?.active);
    // console.info('ModuleMenu', 'userCapabilities', userCapabilities);

    return (
        <nav className="module-menu">
            {((appConfiguration?.legalEntitySelector?.active && settings && filterByLegalEntity) || !appConfiguration?.legalEntitySelector?.active) && (
                <List className="module-menu-portion module-menu-portion-activities">
                    {currentModuleNavigationData && Object.entries(currentModuleNavigationData).map(([k, moduleMenuItem]) => {
                        // console.info('ModuleMenu', moduleMenuItem ?? '',  userCapabilities ?? '', moduleMenuItem?.capability ?? '', userCapabilities[moduleMenuItem.capability] ?? '');
                        return (moduleMenuItem && userCapabilities && userCapabilities[moduleMenuItem.capability] && moduleMenuItem.moduleMenuVisibility) && (
                            <ListItem
                                key={k}
                                className={moduleMenuItem.href.split('?')[0] === location.pathname.split('?')[0] ? "module-menu-item module-menu-item-selected" : "module-menu-item"}
                            >
                                <Anchor underline="none" className="label" href={moduleMenuItem.href}>
                                    {moduleMenuItem.icon && <Icon icon={moduleMenuItem.icon} className="icon" />}
                                    {t(moduleMenuItem.title)}
                                </Anchor>
                            </ListItem>
                        )
                    })}
                </List>
            )}
        </nav>
    );
};

export default ModuleMenu;
