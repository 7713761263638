import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

export const usePianoContiOptions = ({ loadAll }) => {

    const [options, setOptions] = useState()

    useEffect(() => {
        if (!options) {
            loadAll()
                .then((data) => {
                    const { items } = data || {}
                    const { data: options } = items || {}
                    setOptions(options || [])
                })
                .catch(() => {
                    console.error('catch usePianoContiOptions')
                    setOptions([])
                })
        }
    }, [])

    return { options }
}

export const useRowManager = ({ idSottopos: contoEconomicoRecordId, setShowError }) => {

    const [state, setState] = useState({
        data: {
            contoEconomicoRecordId,
            splits: [
                /* { todo ==> esempio di init, da cancellare
                    description: 'd1',
                    idFE: v4(),
                    pianoContiCeId: '67530b8fefb5ddd8c90a53b5',
                    totalValue: 0
                } */
            ]
        }
    })

    const onAdd = () => {
        const newState = { ...state }
        newState.data.splits.push({
            description: '',
            idFE: v4(),
            pianoContiCeId: '',
            totalValue: 0
        })
        setState(newState)
        setShowError(false)
    }

    const onChange = ({ field, idFE: idFEFromParams, value }) => {
        const newState = { ...state }
        const currentSplit = newState.data.splits.find(({ idFE }) => idFE === idFEFromParams)
        currentSplit[field] = value
        setState(newState)
        setShowError(false)
    }

    const onDelete = ({ idFE: idFEFromParams }) => {
        const newState = { ...state }
        newState.data.splits = newState.data.splits.filter(({ idFE }) => idFE !== idFEFromParams)
        setState(newState)
        setShowError(false)
    }

    return { onAdd, onChange, onDelete, state }
}

