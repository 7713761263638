import React, { useContext } from "react"
import { Box, ModalClose } from "@mui/joy"
import { useTranslation } from 'react-i18next'
import { Text } from "src/mtska-frontend-app-component"
import { Importer } from "../components"
import { Importer2 } from "../components/Importer2"
import { AppContext } from "src/mtska-frontend-app-scaffolding"

const Create = (params) => {
    const { t } = useTranslation();
    const { appConfiguration } = useContext(AppContext);

    return <>
        {params.isDrawer && (
            <Box className="drawer-section-header" >
                <ModalClose />
                <Text level="title-md" icon >{t("New Import")}</Text>
            </Box>
        )}
        {
            appConfiguration && appConfiguration.importer?.version == 1 && <Importer {...params} />
        }
        {
            appConfiguration && appConfiguration.importer?.version == 2 && <Importer2 {...params} />
        }
        
    </>

}

export default Create;


