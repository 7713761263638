import React, { useContext } from 'react'
import { OPTIONS } from './pianoContiReport.constants'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { useDefault } from './pianoContiReport.hooks'
import { useTranslation } from 'react-i18next'
import { styles } from './pianoContiReport.styles'
import { AppContext } from 'src/mtska-frontend-app-scaffolding'
import { ExcelPreview } from './excelPreview/ExcelPreview'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export const PianoContiReport = ({ dataProviderName }) => {

    const { filterByLegalEntity, saveSetting, settings, setSettings, userCapabilities } = useContext(AppContext)

    const { download, downloading, loadAll } = useDataProvider(dataProviderName)

    const { onFilterChange, state } = useDefault({ dataProviderName, filterByLegalEntity, loadAll, saveSetting, settings, setSettings })

    const { t } = useTranslation()

    const { data, filters } = state

    const { periodValue } = filters

    const { containerSectionS, infoS, selectS } = styles

    return (
        <div style={{ ...containerSectionS }}>
            {filterByLegalEntity && filterByLegalEntity !== 'all'
                ? (
                    <>
                        <div>
                            <Select
                                disabled={!data}
                                onChange={(e) => onFilterChange({ key: 'periodValue', value: e.target.value })}
                                size='small'
                                sx={{ ...selectS }}
                                value={periodValue}
                            >
                                {OPTIONS.map(({ label, value }) => <MenuItem key={value} value={value}>{t(label)}</MenuItem>)}
                            </Select>
                        </div>
                        <ExcelPreview
                            data={data}
                            dataProviderName={dataProviderName}
                            download={download}
                            downloading={downloading}
                            userCapabilities={userCapabilities}
                        /></>
                )
                : (
                    <div style={{ ...infoS }}>
                        {t("Select a company")}
                    </div>
                )
            }
        </div>
    )
}
