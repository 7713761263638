import React, { useMemo } from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {
    const date = new Date();
    const formattedDateToday = date.toISOString().split('T')[0];
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const formattedDateStartOfMonth = `${startOfMonth.getFullYear()}/${String(startOfMonth.getMonth() + 1).padStart(2, '0')}/${String(startOfMonth.getDate()).padStart(2, '0')}`;
    
    return <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Listings"
                icon="faGauge"
                dataProviderName={'statistics/item/count/opportunities/listing'}
            />
            <KPI
                title={"Listings current month (" + formattedDateStartOfMonth + ")"}
                icon="faCalendar"
                dataProviderName={'statistics/currentmonth/count/opportunities/listing'}
                filters={{
                    'items': [{
                        'field': 'registered_at',
                        'operator': 'afterOrEqual',
                        'value': formattedDateStartOfMonth
                    }]
                }}
            />
            <KPI
                title={"Listings today"}
                icon="faCalendarStar"
                dataProviderName={'statistics/currentday/count/opportunities/listing'}
                filters={{
                    'items': [{
                        'field': 'registered_at',
                        'operator': 'afterOrEqual',
                        'value': formattedDateToday
                    }]
                }}
            />
        </Box>
        <List />
    </>

}

export default Default;


