import { Delete, Download, Notify } from "./views";

const Module = {
    name: "wizards",
    defaultRoutePath: "/wizards",

    routes: [
        //
    ],
    drawerRoutes: [
        {
            path: "delete",
            Component: Delete,
        },
        {
            path: "download",
            Component: Download,
        },
        {
            path: "notify",
            Component: Notify,
        },
    ],

}

export default Module;

