import React from 'react'
import { getStyles } from './column.styles'
import { styles } from '../../../excelPreview.styles'

export const Column = ({ column, index }) => {

    const { children, fieldKey: columnFieldKey, label, style: customColumnS = {} } = column || {}

    const childrenLength = (children && children.length) || 1

    const { childS, columnS } = getStyles({ childrenLength, index })
    const { cellContainerS, cellS, headerCellS } = styles

    return (
        <div style={{ ...columnS, ...customColumnS }}>
            <div style={{ ...cellS, ...headerCellS }} title={label}>
                {label}
            </div>
            {children && (
                <div style={{ ...cellContainerS }}>
                    {children.map((child) => {
                        const { fieldKey, label, title } = child || {}
                        return (
                            <div
                                key={`${columnFieldKey}${fieldKey}`}
                                style={{ ...childS, ...cellS, ...headerCellS }}
                                title={title}
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
