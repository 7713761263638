import React from "react"
import { useTranslation } from 'react-i18next';
import { Button } from ".";
import { DrawerButton } from "../Drawer";

const DownloadButton = ({ onClick = () => { }, downloading, disabled, dataProviderName }) => {

    const { t } = useTranslation();


    return <DrawerButton
        drawerRoute='wizards/download'
        className="button-new"
        params={{
            dataProviderName
        }}
        label={t("Export")}
        disabled={disabled}
    />

    // return (
    //     <Button
    //         onClick={onClick}
    //         disabled={disabled}
    //         className="button-export"
    //     >
    //         {downloading ? t('Downloading...') : t('Export')}
    //     </Button>
    // )
};


export default DownloadButton;
