import React from "react"
import InfoHeaderPerson from "./InfoHeaderPerson";
import InfoHeaderDriver from "./InfoHeaderDriver";
import InfoHeaderLegalEntity from "./InfoHeaderLegalEntity";
import InfoHeaderVehicle from "./InfoHeaderVehicle";
import InfoHeaderWizard from "./InfoHeaderWizard";

const InfoHeader = (params) => {

    const { view, item, control, title, subtitle } = params;

    if (view === 'person') {
        return <InfoHeaderPerson
            {...params}
            view={view}
            item={item}
            control={control}
        />
    }

    if (view === 'driver') {
        return <InfoHeaderDriver
            {...params}

            view={view}
            item={item}
            control={control}
        />
    }

    if (view === 'legalEntity') {
        return <InfoHeaderLegalEntity
            {...params}

            view={view}
            item={item}
            control={control}
        />
    }

    if (view === 'vehicle') {
        return <InfoHeaderVehicle
            {...params}

            view={view}
            item={item}
            control={control}
        />
    }

    if (view === 'wizard') {
        return <InfoHeaderWizard
            {...params}

            view={view}
            title={title}
            subtitle={subtitle}
        />
    }
};

export default InfoHeader
