import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useFormatter, useRelationshipInjector } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/inspection';
const model = 'cars/inspection';
const modelExposedName = 'Inspections';
const wizardAction = 'create';




const Create = (params) => {

    const { t } = useTranslation();
    const { userCapabilities, existingModules } = useContext(AppContext);


    const searchParams = Object.fromEntries(new URLSearchParams(document.location.search));

    const {
        multipart,
        handleClose,
        uid
    } = { ...params, ...searchParams };

    //  // DATA PROVIDER
    const {
        doAction,
        item,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, multipart);

   
    const {modelName, detailConfig} = existingModules[dataProviderName]

    // SAVE AND SUCCESS
    const [editing, setEditing] = useState(false)
    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await handleClose(uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success]);

    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    const values = {}

    return <>
        <DetailComponent
            values={values}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', { itemname: t(modelExposedName) })}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.create']}
            header={<></>}
            {...params}
        />
    </>
}

export default Create;


