
export const useXMLFlattener = () => {
    const flattenItalianInvoiceXml = (data) => {
        if (Array.isArray(data.FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee)) {
            return data.FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee.map((invoiceLine) => { return lineFlattener(data, invoiceLine)})
        } else {
            return [lineFlattener(data, data.FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee)];
        }
        
    }

    return {
        flattenItalianInvoiceXml
    }
}

const lineFlattener = (data, invoiceLine) => {
    return {
        'invoice_country': data.FatturaElettronicaHeader?.DatiTrasmissione?.IdTrasmittente?.IdPaese,
        'invoice_code': data.FatturaElettronicaHeader?.DatiTrasmissione?.IdTrasmittente?.IdCodice,
        'invoice_progressive_id': data.FatturaElettronicaHeader?.DatiTrasmissione?.ProgressivoInvio,

        'invoice_type': data.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.TipoDocumento,
        'invoice_currency': data.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.Divisa,
        'invoice_at': data.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.Data,
        'invoice_number': data.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.Numero,
        'invoice_total_value': data.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.ImportoTotaleDocumento,
        'invoice_net_value': data.FatturaElettronicaBody?.DatiBeniServizi?.DatiRiepilogo?.ImponibileImporto,
        'invoice_vat_value': data.FatturaElettronicaBody?.DatiBeniServizi?.DatiRiepilogo?.Imposta,
        'invoice_vat_percentage': data.FatturaElettronicaBody?.DatiBeniServizi?.DatiRiepilogo?.AliquotaIVA,
        'invoice_vat_appliable': data.FatturaElettronicaBody?.DatiBeniServizi?.DatiRiepilogo?.EsigibilitaIVA,
        
        'invoice_part_line':invoiceLine.NumeroLinea,
        'invoice_part_description':invoiceLine.Descrizione,
        'invoice_part_quantity':invoiceLine.Quantita,
        'invoice_part_unitofmeasure':invoiceLine.UnitaMisura,
        'invoice_part_price_unit':invoiceLine.PrezzoUnitario,
        'invoice_part_price_total':invoiceLine.PrezzoTotale,
        'invoice_part_vat_percentage':invoiceLine.AliquotaIVA,

        'supplier_vat_country': data.FatturaElettronicaHeader?.CedentePrestatore?.DatiAnagrafici?.IdFiscaleIVA?.IdPaese,
        'supplier_vat_code': data.FatturaElettronicaHeader?.CedentePrestatore?.DatiAnagrafici?.IdFiscaleIVA?.IdCodice,
        'supplier_fiscalcode': data.FatturaElettronicaHeader?.CedentePrestatore?.DatiAnagrafici?.CodiceFiscale,
        'supplier_name': data.FatturaElettronicaHeader?.CedentePrestatore?.DatiAnagrafici?.Anagrafica?.Denominazione,
        'supplier_location_address': data.FatturaElettronicaHeader?.CedentePrestatore?.Sede?.Indirizzo,
        'supplier_location_zip': data.FatturaElettronicaHeader?.CedentePrestatore?.Sede?.CAP,
        'supplier_location_city': data.FatturaElettronicaHeader?.CedentePrestatore?.Sede?.Comune,
        'supplier_location_province': data.FatturaElettronicaHeader?.CedentePrestatore?.Sede?.Provincia,
        'supplier_location_country': data.FatturaElettronicaHeader?.CedentePrestatore?.Sede?.Nazione,
        'supplier_contacts_telephone': data.FatturaElettronicaHeader?.CedentePrestatore?.Contatti?.Telefono,

        'customer_vat_country': data.FatturaElettronicaHeader?.CessionarioCommittente?.DatiAnagrafici?.IdFiscaleIVA?.IdPaese,
        'customer_vat_code': data.FatturaElettronicaHeader?.CessionarioCommittente?.DatiAnagrafici?.IdFiscaleIVA?.IdCodice,
        'customer_fiscalcode': data.FatturaElettronicaHeader?.CessionarioCommittente?.DatiAnagrafici?.CodiceFiscale,
        'customer_name': data.FatturaElettronicaHeader?.CessionarioCommittente?.DatiAnagrafici?.Anagrafica?.Denominazione,
        'customer_location_address': data.FatturaElettronicaHeader?.CessionarioCommittente?.Sede?.Indirizzo,
        'customer_location_zip': data.FatturaElettronicaHeader?.CessionarioCommittente?.Sede?.CAP,
        'customer_location_city': data.FatturaElettronicaHeader?.CessionarioCommittente?.Sede?.Comune,
        'customer_location_province': data.FatturaElettronicaHeader?.CessionarioCommittente?.Sede?.Provincia,
        'customer_location_country': data.FatturaElettronicaHeader?.CessionarioCommittente?.Sede?.Nazione,
        'customer_contacts_telephone': data.FatturaElettronicaHeader?.CessionarioCommittente?.Contatti?.Telefono,

        'payment_condition': data.FatturaElettronicaBody?.DatiPagamento?.CondizioniPagamento,
        'payment_mode': data.FatturaElettronicaBody?.DatiPagamento?.DettaglioPagamento?.ModalitaPagamento,
        'payment_total_value': data.FatturaElettronicaBody?.DatiPagamento?.DettaglioPagamento?.ImportoPagamento,
        'payment_iban': data.FatturaElettronicaBody?.DatiPagamento?.DettaglioPagamento?.IBAN,

    }
}