import React, { useContext } from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Full, Minimal } from '../views';
import { AppContext } from '../contexts';
import { useDataProvider } from "src/mtska-frontend-data-provider";

const Routes = ({ modules = [] }) => {

    const searchParams = new URLSearchParams(document.location.search)

    const { appConfiguration } = useContext(AppContext);
    const { item } = useDataProvider('auth/token')

    const protectedLoader = ({ request }) => {
        if (!sessionStorage.getItem('token')) {

            const url = new URL(request.url);
            const urlSearchParams = url.searchParams.toString()
            const urlPathname = url.pathname;
            const encryptedDestination = btoa(urlPathname + '?' + urlSearchParams);

            const params = new URLSearchParams();
            params.set("dst", encryptedDestination);
            return redirect("/?" + params.toString());
        }
        return null;
    }

    const publicLoader = ({ request }) => {
        if (item?.data?.errorMessage != undefined || item?.data?.options != undefined) {
            return null
        } else if (item?.data != undefined && item?.data?.errorMessage == undefined && sessionStorage.getItem('token')) {
            if (searchParams.get('dst')){
                const destination = atob(searchParams.get('dst'));
                return redirect(destination);
            } 
            const qs = searchParams.toString();
            return redirect(
                (process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URI || 
                appConfiguration.auth0.DEFAULT_AUTHENTICATED_URI) + "?" + qs
            );
        }
        return null;
    }

    const rs = [
        {
            path: "/",
            Component: Minimal,
            loader: publicLoader
        },
    ];
    
    Object.entries(modules).forEach(([key, m]) => {
        m.routes.map((r, j) => {
            if (r.protected) {
                r.Component = Full
                r.loader = protectedLoader
            } else {
                r.Component = Minimal
                r.loader = publicLoader
            }
            //console.info('module '+m.name, r.path)
            rs.push(r)
        })
    });

    const router = createBrowserRouter(rs);

    return <RouterProvider router={router} />
}

export default Routes;
