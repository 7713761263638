import React, { useMemo, useContext } from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const Default = () => {
    const { userCapabilities } = useContext(AppContext);

    const now = new Date();
    const startPreviousYear = (now.getFullYear() - 1) + '-01-01';
    const startYear = now.getFullYear() + '-01-01';
    const startPreviousMonth = now.getFullYear() + '-' + String(now.getMonth()).padStart(2, '0') + '-01';
    const startMonth = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-01';

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            {
                userCapabilities && userCapabilities['auth/role.is_superuser'] && (
                    <KPI
                        title="Records"
                        icon="faCar"
                        className="superuserData"
                        dataProviderName={'statistics/item/count/cars/cost'}
                    />
                )
            }
            <KPI
                title="Costs Previous Year"
                icon="faMoneyBill"
                dataProviderName={'statistics/totalpy/sumfinancial/cars/cost/valueTotal'}
                filters={{
                    'items': [{
                        'field': 'invoiced_at',
                        'operator': 'after',
                        'value': startPreviousYear
                    },
                    {
                        'field': 'invoiced_at',
                        'operator': 'before',
                        'value': startYear
                    }]
                }}
                format="currency"
            />
            <KPI
                title="Costs Current Year"
                icon="faMoneyBill"
                dataProviderName={'statistics/total/sumfinancial/cars/cost/valueTotal'}
                filters={{
                    'items': [{
                        'field': 'invoiced_at',
                        'operator': 'after',
                        'value': startYear
                    }]
                }}
                format="currency"
            />
            <KPI
                title="Costs Previous Month"
                icon="faMoneyBill"
                dataProviderName={'statistics/monthlylm/sumfinancial/cars/cost/valueTotal'}
                filters={{
                    'items': [{
                        'field': 'invoiced_at',
                        'operator': 'after',
                        'value': startPreviousMonth
                    },
                    {
                        'field': 'invoiced_at',
                        'operator': 'before',
                        'value': startMonth
                    }]
                }}
                format="currency"
            />
            <KPI
                title="Costs Current Month"
                icon="faMoneyBill"
                dataProviderName={'statistics/monthly/sumfinancial/cars/cost/valueTotal'}
                filters={{
                    'items': [{
                        'field': 'invoiced_at',
                        'operator': 'after',
                        'value': startMonth
                    }]
                }}
                format="currency"
            />

        </Box>
        <List />
    </>, [])

}

export default Default;


