import React from 'react'
import { styles as commonStyles } from '../../../excelPreview.styles'
import { Cell } from './cell/Cell'

export const Row = ({ columns, row, rowIndex, sectionIndex }) => {

    const { cellContainerS } = commonStyles

    return (
        <div style={{ ...cellContainerS }}>
            {Object.values(columns).map((column, columnIndex) => (
                <Cell
                    column={column}
                    index={columnIndex}
                    key={`section${sectionIndex}row${rowIndex}column${columnIndex}`}
                    row={row}
                />
            ))}
        </div>
    )
}
