import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import PersonAvatar from '../Snippet/PersonAvatar';
import { Tag } from '../Snippet';
import { useDrawer } from '../Drawer'


const RelationshipPerson = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const { activate } = useDrawer();

    return (

        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <PersonAvatar firstname={v?.firstname} lastname={v?.lastname} url={v?.picture?.url} withname={true} item={v}/>

                <Box className="email">
                    {v?.email || <Tag color={"danger"} size="small" label={t('Missing email')} />}
                </Box>
            </ListItemContent>
            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipPerson



