import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'documents/document';
const wizardName = 'Generic File association';
const wizardObject = 'Document';
const wizardAction = 'genericFileAssociation';

const GenericFileAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const {
        item,
        save,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, true);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "document",
                "fieldsets": [
                    {
                        "name": "document",
                        "fields": {
                            "file": {
                                "type": "dropzone",
                                "required":true,
                                "className": "col-12",
                                "icon": "faFile"
                            },
                            "type": {
                                "type": "select",
                                "required":true,
                                "options": [
                                    {
                                        "label": "vehicleRegistration",
                                        "value": "vehicleRegistration"
                                    },
                                    {
                                        "label": "insurance",
                                        "value": "insurance"
                                    },
                                    {
                                        "label": "activeClaimReport",
                                        "value": "activeClaimReport"
                                    },
                                    {
                                        "label": "passiveClaimReport",
                                        "value": "passiveClaimReport"
                                    },
                                    {
                                        "label": "article94",
                                        "value": "article94"
                                    },
                                    {
                                        "label": "recallCampaignCompleted",
                                        "value": "recallCampaignCompleted"
                                    },
                                    {
                                        "label": "fuelCardAssignment",
                                        "value": "fuelCardAssignment"
                                    }
                                ],
                                "className": "col-12",
                                "icon": "faCircleX"
                            },
                            "car": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "cars"
                                },
                                "view": "car"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    // DOCUMENT ****NON USA**** QUESTO PROCESSO DI CREAZIONE TRAMITE JOB
    // 
    // const handleSave = (data) => {
    //     doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    // }

    const handleSave = async (data) => {
        save && save({ ...data, filters: params.filters }).then(() => {close()})
    }
    
    const car = params?.car || params?.parent;
    return <>
        <DetailComponent
            values={{
                "id": item?.data?._id,
                "car": car,
                "visibleToDriver": "true",
                "name": t("document") + (car?.plate ? ' ' + car?.plate : '')
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Add a {{wizardObject}} to {{plate}}', { wizardObject: wizardObject, plate: car?.plate })}
                />
            }
            {...params}
        />
    </>
}

export default GenericFileAssociation;


