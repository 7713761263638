import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, FormControl, Input, InputLabel, Popover, Slider } from '@mui/material'

export const NumericRangeFilter = ({ applyValue, item, value: valuesFP }) => {

    const { t } = useTranslation()

    const max = 100000

    const initValues = valuesFP || [0, max]

    const [anchorEl, setAnchorEl] = useState(null)
    const [values, setValues] = useState(initValues)

    const formatNumber = (num) => new Intl.NumberFormat('it-IT').format(num)

    const onClickInput = (event) => setAnchorEl(event.currentTarget)

    const onClose = (value) => {
        if (value && value[0] && value[1]) {
            setValues(value)
            applyValue({ ...item, value })
        }
        setAnchorEl(null)
    }

    const anchorOrigin = { horizontal: 'left', vertical: 'bottom' }
    const marks = [{ label: 0, value: 0 }, { label: formatNumber(max), value: max }]
    const open = Boolean(anchorEl)
    const value = initValues.map(formatNumber).join(' - ')

    return (
        <FormControl sx={{ width: 'inherit' }} variant='standard'>
            <InputLabel>{t('Select Filter')}</InputLabel>
            <Input onClick={onClickInput} readOnly size='sm' value={value} variant='standard' />
            <Popover anchorEl={anchorEl} anchorOrigin={anchorOrigin} onClose={onClose} open={open}>
                <Box sx={{ display: 'grid', gap: '26px', padding: '36px 36px 16px 36px' }}>
                    <Slider
                        marks={marks}
                        max={max}
                        onChange={(_event, newValue) => setValues(newValue)}
                        value={values}
                        valueLabelDisplay='auto'
                    />
                    <Box>
                        <Button onClick={() => onClose(values)}>Ok</Button>
                        <Button onClick={() => onClose()}>Cancel</Button>
                    </Box>
                </Box>
            </Popover>
        </FormControl>
    )
}
