import React, { useState, useEffect } from 'react'
import createConstants from 'src/mtska-frontend-data-provider/redux/constants'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { Box, Alert } from '@mui/joy'
import { Button, Input, Text } from 'src/mtska-frontend-app-component'

const constants = createConstants()

export const Importer2 = ({ dataProviderName, handleClose, importModel, uid }) => {

    const { save } = useDataProvider('imports/jobwrapper', true)

    const { t } = useTranslation()

    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])

    const { control } = useForm()

    const selectedMultipleFileWatcher = useWatch({ control, name: 'multipleFileUploader' })

    useEffect(() => {
        const hasSelectedMultipleFileWatcher = selectedMultipleFileWatcher && selectedMultipleFileWatcher.length > 0
        hasSelectedMultipleFileWatcher && handleFileChange(selectedMultipleFileWatcher)
    }, [selectedMultipleFileWatcher])

    const handleFileChange = (selectedMultipleFileWatcher) => {
        setSelectedFiles(prevFiles => [...prevFiles, ...selectedMultipleFileWatcher])
    }

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            setError('Nessun file selezionato')
            return
        }

        setError(null)
        setProcessing(true)

        try {
            const formData = new FormData()
            selectedFiles.forEach(file => formData.append('files[]', file))
            const model = importModel || dataProviderName
            formData.append('model', model)
            save(formData)
                .then((data) => {
                    const { message, type } = data || {}
                    type === constants.SAVE_FAILURE
                        ? onError({ message })
                        : onSuccess({ data, uid })
                })
                .catch(() => {
                    onError({ error: 'catch handleUpload' })
                })
        } catch (message) {
            onError({ message })
        }
    }

    const onError = ({ error, message }) => {
        error && console.error(error)
        message && setError(message)
        setProcessing(false)
    }

    const onSuccess = ({ data }) => {
        setProcessing(false)
        setSelectedFiles([])
        handleClose(uid, data.item.data)
    }

    const hasFilesToUpload = selectedFiles && selectedFiles.length > 0

    return (
        <Box className="drawer-section-content">
            <Box sx={{ display: 'grid', gap: '20px' }}>
                <Text level='title-md'>{t('Select a file to be imported')}</Text>
                <Input
                    beforeOnClick={() => {
                        setError(null)
                        setSelectedFiles([])
                    }}
                    control={control}
                    editing={true}
                    label={'Upload more file'}
                    multiple={true}
                    name='multipleFileUploader'
                    type='dropzone'
                />
                {error && <Alert color='danger'>{error}</Alert>}
                <Box>
                    <Button
                        disabled={!hasFilesToUpload || (hasFilesToUpload && processing)}
                        onClick={handleUpload}
                        variant='solid'
                    >
                        {t('Import')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
