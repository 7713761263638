import React, { useEffect } from 'react'
import { Box, List, ListItem, Typography } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { Button, DocumentAvatar, DrivingLicenceAvatar, PersonAvatar, ServiceAvatar, Text, useDrawer } from 'src/mtska-frontend-app-component';
import { VehicleCard } from '../../Car/views';
import VehicleConfigurationCard from '../../Car/views/VehicleConfigurationCard';

const Driver = () => {
    const { t } = useTranslation();
    const { activate } = useDrawer();

    const { item: user } = useDataProvider('auth/token');
    const { loadAll: loadAllWhoAmI, items: myPerson } = useDataProvider('persons/person/whoami');
    const { loadAll: loadAllMyEmployment, items: myEmployment } = useDataProvider('persons/person/employment');
    const { loadAll: loadAllMyDocuments, items: myDocuments } = useDataProvider('persons/person/documents');
    const { loadAll: loadAllMyCars, items: myCars, total: myCarsTotal } = useDataProvider('cars/car');
    const { loadAll: loadAllMyServices, items: myServices } = useDataProvider('persons/person/services');
    const { loadAll: loadAllMyConfigurations, items: myConfigurations } = useDataProvider('persons/person/configurations');

    useEffect(() => {
        loadAllWhoAmI();
        loadAllMyEmployment();
        loadAllMyDocuments();
        loadAllMyCars();
        loadAllMyServices();
    }, [user]);

    useEffect(() => {
        if (
            myEmployment?.fleetConfigurationActive &&
            myEmployment?.fleetConfigurationLink.length > 0 &&
            (myEmployment?.fleetConfigurationLinkValid_at && (new Date(myEmployment?.fleetConfigurationLinkValid_at) < new Date())) &&
            (myEmployment?.fleetConfigurationLinkExpire_at && (new Date(myEmployment?.fleetConfigurationLinkExpire_at) > new Date()))
        ) loadAllMyConfigurations();
    }, [myEmployment]);

    const handlePersonClick = () => {
        activate('persons/detail', { id: myPerson.id });
    }

    const handleDrivingLicenceClick = () => {
        activate('persons/drivingLicenceAssociation', { person: myPerson, personId: myPerson._id, multipart: true })
    }

    return (
        <Box className="dashboard dashboard-driver">

            <Box className="dashboard-portion-title">{t('Your profile')}</Box>
            <Box className="dashboard-portion-driver-info">
                <PersonAvatar onClick={handlePersonClick} firstname={myPerson?.firstname} lastname={myPerson?.lastname} url={myPerson?.picture?.url} withname={true} detail={user?.data?.email} item={myPerson} />
                <DrivingLicenceAvatar onClick={handleDrivingLicenceClick} drivingLicence={myDocuments?.person?.drivingLicense} />
            </Box>

            {
                myEmployment?.fleetConfigurationActive &&
                myEmployment?.fleetConfigurationLink.length > 0 &&
                (myEmployment?.fleetConfigurationLinkValid_at && (new Date(myEmployment?.fleetConfigurationLinkValid_at) < new Date())) &&
                (myEmployment?.fleetConfigurationLinkExpire_at && (new Date(myEmployment?.fleetConfigurationLinkExpire_at) > new Date())) && (
                    <Box className="dashboard-configuration-wrapper">
                        <Box className="dashboard-configuration-cta">
                            <img src="/images/configuration.webp" />
                            <Box className="dashboard-configuration-cta-inner">
                                <Text level="title-sm">{t('Hurra!')}</Text>
                                <Text level="body-sm">{t('You are elegible to a new configuration, it\'s fun time!')}</Text>
                                <a href={myEmployment?.fleetConfigurationLink} target="_blank" className='MuiButton-colorPrimary MuiButton-variantSolid input-button regular buttonized'>{t('Start')}</a>
                            </Box>
                        </Box>
                    </Box>
                )
            }

            {
                myEmployment?.fleetConfigurationActive &&
                myConfigurations &&
                myConfigurations?.ArrayConfigurazioni &&
                myConfigurations?.ArrayConfigurazioni.length > 0 && (
                    <Box className="dashboard-configuration-wrapper">
                        <Box className="dashboard-portion-title">{t('Your configurations')}</Box>
                        <Box className="dashboard-portion-subtitle">{t('You have {{myConfigurationsTotal}} configurations', { myConfigurationsTotal: myConfigurations.ArrayConfigurazioni.length })}</Box>
                        {
                            // <textarea>
                            //     {JSON.stringify(myConfigurations.ArrayConfigurazioni)}
                            // </textarea>
                        }

                        <List className="dashboard-portion dashboard-menu">
                            {
                                myConfigurations.ArrayConfigurazioni.map((myConfiguration, k) => (
                                    <ListItem key={k} className="dashboard-portion-item dashboard-configuration-list">
                                        <VehicleConfigurationCard itemview={myConfiguration} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                )
            }

            <Box className="dashboard-portion-title">{t('Your vehicles')}</Box>
            <Box className="dashboard-portion-subtitle">{t('You have {{myCarsTotal}} vehicles in your garage', { myCarsTotal: myCarsTotal })}</Box>

            <List className="dashboard-portion dashboard-menu">
                {
                    myCars.map((myCar, k) => (
                        <ListItem key={k} className="dashboard-portion-item dashboard-vehicle-list">
                            <VehicleCard itemview={myCar} />
                        </ListItem>
                    ))
                }
            </List>

            <Box className="dashboard-portion-title">{t('Your documents')}</Box>

            <List className="dashboard-documents-list-wrapper">
                {
                    Object.entries(myDocuments).map(([section, value]) => (
                        Object.keys(value).length > 0 && (
                            <ListItem key={section} className="dashboard-documents-list-section">
                                <Box className="dashboard-documents-list-section-title">{section == 'person' ? myPerson?.firstname + " " + myPerson?.lastname : t('Vehicle') + " " + section}</Box>
                                <List className="dashboard-documents-list-section-list">
                                    {
                                        Object.entries(value).map(([k, document]) => (
                                            <ListItem key={k} className="dashboard-documents-list-section-list-element">
                                                <DocumentAvatar document={document} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </ListItem>
                        )
                    ))
                }
            </List>

            <Box className="dashboard-portion-title">{t('Your services')}</Box>

            <List className="dashboard-services-list-wrapper">
                {
                    Object.entries(myServices).map(([section, value]) => (

                        Object.keys(value).length > 0 && (
                            <ListItem key={section} className="dashboard-services-list-section">
                                <Box className="dashboard-services-list-section-title">{section == 'person' ? myPerson?.firstname + " " + myPerson?.lastname : t('Vehicle') + " " + section}</Box>
                                <List className="dashboard-services-list-section-list">
                                    {
                                        Object.entries(value).map(([k, service]) => (
                                            <ListItem key={k} className="dashboard-services-list-section-list-element">
                                                <ServiceAvatar service={service} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </ListItem>
                        )

                    ))
                }
            </List>

        </Box>
    )

}

export default Driver;


