import * as Views from "./views";

const Module = {
    name: "caroffers",
    defaultRoutePath: "/bids/caroffer",
    menuItemScope: "bid",
    menuItemName: "offers",

    routes: [
        {
            path: "/bids/caroffer",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                }
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
    ],

}

export default Module;
