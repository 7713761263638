import React from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export const SelectOptionsFilter = ({ applyValue, item, options, value }) => {

    const { t } = useTranslation()

    return (
        <FormControl sx={{ width: 'inherit' }} variant="standard">
            <InputLabel>{t('Select Filter')}</InputLabel>
            <Select onChange={(event) => applyValue({ ...item, value: event.target.value })} value={value || ''}>
                <MenuItem value="">
                    {t('No selection')}
                </MenuItem>
                {options.map((option, index) => <MenuItem key={option + index} value={option}>{t(option)}</MenuItem>)}
            </Select>
        </FormControl>
    )
}
