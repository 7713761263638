import React from "react";
import { Icon } from 'src/mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';
import { RouterAnchor } from "src/mtska-frontend-app-component/components/RouterAnchor";

const Elletre = () => {
    const { t } = useTranslation();

    return (<ul className="dashboard-menu">
        <li className="dashboard-menu-item">

            <Icon icon="faDashboard" />
            <h2 className="menu-item-title">{t('Business intelligence')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item"><RouterAnchor href="/bis/biviewer?scope=sales">{t('Operational Report')}</RouterAnchor></li>
                <li className="dashboard-submenu-item"><a href="https://eu-west-1a.online.tableau.com/#/site/engelvoelkers/home" target="_blank">{t('Tableau')}</a></li>
            </ul>

        </li>
        <li className="dashboard-menu-item">

            <Icon icon="faFilter" />
            <h2 className="menu-item-title">{t('Operation')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item"><RouterAnchor href="/opportunities/listing">{t('Listing')}</RouterAnchor></li>
                <li className="dashboard-submenu-item"><RouterAnchor href="/opportunities/lead">{t('Leads')}</RouterAnchor></li>
                <li className="dashboard-submenu-item"><RouterAnchor href="/opportunities/proposal">{t('Proposals')}</RouterAnchor></li>
            </ul>

        </li>
        <li className="dashboard-menu-item">

            <Icon icon="faMegaphone" />
            <h2 className="menu-item-title">{t('Marketing')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item"><a href="https://www.immobiliare.it/login/" target="_blank">{t('Immobiliare')}</a></li>
                <li className="dashboard-submenu-item"><RouterAnchor href="/bis/biviewer?scope=analytics">{t('Google Analytics')}</RouterAnchor></li>

                <li className="dashboard-submenu-item hidden">{t('GPT')}</li>
                <li className="dashboard-submenu-item hidden">{t('Photo')}</li>
                <li className="dashboard-submenu-item hidden">{t('Matterport')}</li>
                <li className="dashboard-submenu-item hidden">{t('Content')}</li>
                <li className="dashboard-submenu-item hidden">{t('Newsletter')}</li>
            </ul>

        </li>
        <li className="dashboard-menu-item">

            <Icon icon="faMessageDollar" />
            <h2 className="menu-item-title">{t('Finance')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item"><RouterAnchor href="/bis/biviewer?scope=financial">{t('Financial Report')}</RouterAnchor></li>
                <li className="dashboard-submenu-item hidden">{t('Costs')}</li>
                <li className="dashboard-submenu-item hidden">{t('Ebitda')}</li>
            </ul>

        </li>
        <li className="dashboard-menu-item">

            <Icon icon="faSuitcase" />
            <h2 className="menu-item-title">{t('Sales support')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item hidden">{t('Customer welcome kit')}</li>
                <li className="dashboard-submenu-item hidden">{t('Farming material')}</li>
                <li className="dashboard-submenu-item hidden">{t('Opportunities')}</li>
                <li className="dashboard-submenu-item hidden">{t('Sister interrogation')}</li>
                <li className="dashboard-submenu-item"><a href="https://it.casafari.com/login" target="_blank">Casafari</a></li>
                <li className="dashboard-submenu-item"><a href="https://salescontrolling.engelvoelkers.com/dashboard/start/res/shop" target="_blank">{t('Sales Tool')}</a></li>
            </ul>

        </li>
        <li className="dashboard-menu-item dimmed">

            <Icon icon="faPeople" />
            <h2 className="menu-item-title">{t('Human Resources')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item hidden">{t('Talent acquisition')}</li>
                <li className="dashboard-submenu-item hidden">{t('Talent management')}</li>
                <li className="dashboard-submenu-item hidden">{t('Internal communications')}</li>
                <li className="dashboard-submenu-item hidden">{t('Incentive')}</li>
                <li className="dashboard-submenu-item hidden">{t('Knowledge base')}</li>
            </ul>

        </li>
        <li className="dashboard-menu-item">

            <Icon icon="faCog" />
            <h2 className="menu-item-title">{t('Settings')}</h2>
            <ul className="dashboard-submenu">
                <li className="dashboard-submenu-item"><a href="/commercials/area">{t('Areas')}</a></li>
                <li className="dashboard-submenu-item"><a href="/commercials/shop">{t('Shops')}</a></li>
                <li className="dashboard-submenu-item"><a href="/people/person">{t('Users')}</a></li>
                <li className="dashboard-submenu-item"><a href="/commercials/areasource">{t('Goals')}</a></li>
            </ul>

        </li>
    </ul>)
}

export default Elletre;