import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadOne } from 'src/mtska-frontend-data-provider/hooks/useLoadOne.hooks';

const dataProviderName = 'cars/inspection';
const wizardName = 'Add Inspection';
const wizardAction = 'inspectionAccomplished';
const wizardObject = 'Inspection';


const InspectionAccomplished = (params) => {
    const { t } = useTranslation();
    const { userCapabilities, existingModules } = useContext(AppContext);

    const searchParams = new URLSearchParams(document.location.search);

    const id = params.carId || searchParams.get('id');

    // DATA PROVIDER
    const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider('cars/car');



    useEffect(() => {
        loadOne(id);
    }, [id]);


    const modelName = existingModules.modelName;

    const itemsReadeable = () => {

        if (!item?.data) return '';

        const elements = Array.isArray(item) ? item : [item.data];
        const readeables = elements.map((el) => {
            return el.plate ? el.plate : el.setup?.make + " " + el.setup?.model;
        });
        return readeables.join(', ');
    }

    const detailConfig = {
        "tabs": [
            {
                "name": "inspections_accomplished",
                "fieldsets":[ {
                            "name": "inspections_accomplished",
                            "fields": {
                                
                                "accomplished_at": {
                                    "type": "date",
                                    "required": true,
                                    "className": "col-12",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar"
                                },
                                "accomplished_flag": {
                                    "type": "checkbox",
                                    "required": true,
                                    "options": [
                                        {
                                            value: t("inspections_accomplished_confirm_message",{
                                                carIdentifier: itemsReadeable(),
                                            }),
                                            label: t("inspections_accomplished_confirm_message",{
                                                carIdentifier: itemsReadeable(),
                                            }),
                                        }
                                    ],
                                    "className": "col-12",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar"
                                },
                               

                            }
                        }
                ]



            }
        ],
        "validation": []
    }

    const values = {
        carId: item?.data?._id
    };



    const handleSave = (data) => {
        doAction({
            action: wizardAction,
            model: 'cars/car',
            data: data
        })
            .then((data) => params.handleClose(params.uid, data.item.data))
            .catch(() => console.error('catch handleSave'));
    }

   

    return <>
        {/* {JSON.stringify(params)} */}
        {/* {JSON.stringify(item)} */}
        {/* {JSON.stringify(item?.data?.inspectionsNotAccomplished)} */}
        <DetailComponent
            editingDefault={true}
            values={values}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={true}
            // canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={<>
                <InfoHeader
                    view="wizard"
                    title={t("wizardsInspectionAccomplishedTitle")}
                    subtitle={t(
                        'wizardsInspectionAccomplishedSubtitle',
                        {
                            carIdentifier: itemsReadeable(),
                        }
                    )}
                />
            </>

            }
            {...params}
        />
    </>
}

export default InspectionAccomplished;


