import React, { useMemo, useState, useContext } from 'react'
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const Default = () => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);

    const [filters, setFilters] = useState()

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            {
                userCapabilities && userCapabilities['auth/role.is_superuser'] && (
                    <KPI
                        title="Vehicles"
                        icon="faCars"
                        dataProviderName={'statistics/item/count/cars/car'}
                        className="superuserData"
                        filterbutton={{
                            visible: true,
                            label: t("All"),
                            callback: (newfilters) => setFilters(newfilters)
                        }}
                    />
                )
            }
            <KPI
                title="running"
                icon="faCar"
                dataProviderName={'statistics/running/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "running"
                    }]
                }}
            />
            <KPI
                title="available"
                icon="faShop"
                dataProviderName={'statistics/available/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "available"
                    }]
                }}
            />
            <KPI
                title="in_order"
                icon="faCartShopping"
                dataProviderName={'statistics/order/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "in_order"
                    }]
                }}
            />
            <KPI
                title="in_offer"
                icon="faHandshake"
                dataProviderName={'statistics/offer/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "in_offer"
                    }]
                }}
            />
            <KPI
                title="in_configuration"
                icon="faPencil"
                dataProviderName={'statistics/configuration/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "in_configuration"
                    }]
                }}
            />
            <KPI
                title="closed"
                icon="faFolder"
                dataProviderName={'statistics/closed/count/cars/car'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "status",
                        operator: "contains",
                        value: "closed"
                    }]
                }}
            />
          
        </Box>
        <List filters={filters} />
    </>, [filters])

}

export default Default;


