import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Text } from "../Typography";
import { Icon } from "src/mtska-frontend-app-component/assets";

const ServiceAvatar = ({ service = null }) => {

    const { formatDate } = useFormatter();
    const { t } = useTranslation();

    const apiBasePath = process.env.REACT_APP_API_ENDPOINT || '';

    const getIcon = () => {
        const icons = {
            "fuelcard": "faGasPump",
            "tollsystem": "faRoad",
            "lta": "faCity",
            "courtesycar": "faCar",
            "tyre": "faCircle",
            "roadsideassistance": "faTruckMedical"
        }

        return icons[service.type];
    }
    return (
        <Box className="service-avatar">
            <Box className="service-type"><Icon icon={getIcon()}></Icon> {t(service?.type)}</Box>
            <Text className="label id">{t('name')}</Text>
            <Box className="value service-name">{service?.name}</Box>
            <Text className="label id">{t('serviceIdentifier')}</Text>
            <Box className="value service-identifier">{service?.identifier}</Box>
            {
                service?.type == 'fuelcard' && (
                    <>
                        <Text className="label id">{t('pin')}</Text>
                        <Box className="value service-identifier">{service?.pin}</Box>
                    </>
                )
            }
            {
                service?.type == 'tyre' && (
                    <>
                        <Text className="label id">{t('tyreType')}</Text>
                        <Box className="value service-tyreType">{t(service?.tyreType)}</Box>
                        <Text className="label id">{t('tyreCategory')}</Text>
                        <Box className="value service-tyreCategory">{t(service?.tyreCategory)}</Box>
                        <Text className="label id">{t('tyreNumber')}</Text>
                        <Box className="value service-tyreNumber">{service?.tyreNumber}</Box>
                        <Text className="label id">{t('tyreStorageLocation')}</Text>
                        <Box className="value service-storagelocation">{service?.tyreStorageLocation}</Box>
                    </>
                )
            }
            {
                service?.start_at && (
                    <>
                        <Text className="label id">{t('start_at')}</Text>
                        <Box className="value service-start">{formatDate(service?.start_at)}</Box>
                    </>
                )
            }
            {
                service?.end_at && (
                    <>
                        <Text className="label id">{t('end_at')}</Text>
                        <Box className="value service-end">{formatDate(service?.end_at)}</Box>
                    </>
                )
            }
        </Box>
    )
}

export default ServiceAvatar;