import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'src/mtska-frontend-app-scaffolding/hooks/useDebounce'
import { getFormattedRows, moveArrayElement } from './list.functions'
import { GridCellModes } from '@mui/x-data-grid'

// Logica di abilitazione della modalità edit al single click su una cell
export const useCellModes = () => {

    const [cellModesModel, setCellModesModel] = useState({})

    const handleCellClick = useCallback((params = {}, event = {}) => {
        const { currentTarget, target } = event
        const { nodeType } = target
        const { isEditable } = params

        if (!isEditable || (nodeType === 1 && !currentTarget.contains(target))) return

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {}
                        ),
                    }),
                    {}
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {}
                    ),
                    [params.field]: { mode: GridCellModes.Edit }
                }
            }
        })
    }, [])

    const handleCellModesModelChange = useCallback((newModel) => {
        setCellModesModel(newModel)
    }, [])

    return { cellModesModel, handleCellClick, handleCellModesModelChange }
}

// Logica di gestione della modalità full-screen della DataGridPro
export const useFullScreen = ({ elementId }) => {

    const [isFullScreen, setIsFullScreen] = useState(false)

    useEffect(() => {
        const handleFullscreenChange = () => { !document.fullscreenElement && setIsFullScreen(false) }
        document.addEventListener('fullscreenchange', handleFullscreenChange)
        return () => { document.removeEventListener('fullscreenchange', handleFullscreenChange) }
    }, [])

    const toggleFullscreen = () => {
        if (elementId) {
            const element = document.getElementById(elementId)
            if (element && !document.fullscreenElement) {
                if (element.requestFullscreen) {
                    element.requestFullscreen()
                    setIsFullScreen(true)
                }
            } else if (document.exitFullscreen) {
                document.exitFullscreen()
                setIsFullScreen(false)
            }
        }
    }

    return { isFullScreen, toggleFullscreen }
}

// Logica di preparazione, gestione, salvataggio del model/settings 
export const useModelSettings = ({ dataProviderName, filtersFromProps, isNotDrawer, loading, onFilterPageSortChange, saveSetting, setSettings, settings }) => {

    const [model, setModel] = useState({})
    const [localSettings, setLocalSettings] = useState(settings)

    /*
    useEffect(() => {
        if (!localSettings) {
            setLocalSettings(settings)
        }
    }, [settings])
    */

    /**
     * ATTIVITÀ RUSPANTE DI MIRKO; 
     * HO BISOGNO DI POTER RICEVERE DA QUERYSTRING UN VALORE DI FILTRO APPLICATO IMMEDIATAMENTE
     * 
     * Se faccio affidamento sullo useEffect[filterFromProps] non passa perché al kick della pagina sono in loading e model non ha ancora elementi.
     * Usando lo useEffect[settings] (originale commentato qui sopra) forzo il filterModel utilizzato al primo rendering pescando da filterFromProps
     * 
     * Da testare alla data del 8 febbraio 2025
    */
    useEffect(() => {
        const dpm = dataProviderName+".model";
        if (settings && filtersFromProps) {
            if ( settings[dpm] ) {
                settings[dpm].filterModel = filtersFromProps.filterModel;
            }
        }

        if (!localSettings) {
            setLocalSettings(settings)
        } else if (filtersFromProps != undefined) {
            if (!localSettings.hasOwnProperty(dpm)) {
                localSettings[dpm] = {};
            }
            localSettings[dpm].filterModel = filtersFromProps.filterModel;
        }
    }, [settings])

    useEffect(() => {
        if (Object.keys(model).length > 0 && !loading && isNotDrawer) {
            setModelField({ ...filtersFromProps })
        }
    }, [filtersFromProps])

    const onChangeSettings = ({ name, settings, value }) => {
        if (settings && JSON.stringify(settings[name]) !== JSON.stringify(value)) {
            const newSettings = { ...settings }
            newSettings[name] = value
            setLocalSettings(newSettings)
            if (isNotDrawer) {
                setSettings(newSettings)
                saveSetting({ canReloadCounter: false, name, value })
            }
        }
        onFilterPageSortChange(value)
    }

    const debouncedFunction = useDebounce([{ callback: onChangeSettings }])

    const setModelField = (newModelFields = {}) => {
        const value = { ...model, ...newModelFields }
        const { items: preItems, quickFilterValues: preSearchValue } = model.filterModel || {}
        const { items: newItems, quickFilterValues: newSearchValue } = value.filterModel || {}

        const name = `${dataProviderName}.model`

        if (JSON.stringify(model.filterModel) !== JSON.stringify(value.filterModel)) {
            value.paginationModel.page = 0
        }

        if (
            JSON.stringify(preItems) !== JSON.stringify(newItems) ||
            JSON.stringify(preSearchValue) !== JSON.stringify(newSearchValue)
        ) {
            debouncedFunction({ name, settings: localSettings, value })
        } else {
            onChangeSettings({ name, settings: localSettings, value })
        }

        setModel(value)
    }

    const setColumnOrderChange = ({ oldIndex, targetIndex }) => {
        const { columnOrder } = model
        const tempColumnOrder = [...columnOrder]
        const newColumnOrder = moveArrayElement(tempColumnOrder, oldIndex - 1, targetIndex - 1)
        setModelField({ columnOrder: newColumnOrder })
    }

    const setPaginationModel = (newModel) => {
        const { paginationModel } = model
        const newPaginationModel = { ...paginationModel, ...newModel }
        setModelField({ paginationModel: newPaginationModel })
    }

    return { localSettings, model, setColumnOrderChange, setModelField, setPaginationModel }
}

// Logica di preparazione, gestione, salvataggio delle rows
export const useRows = ({ config, dataProviderName: model, doAction = () => { }, items }) => {

    const [rows, setRows] = useState()

    const previousItemsRef = useRef(null)

    useEffect(() => {
        const { tableConfig } = config?.data?.modules[model] || {}
        const { current } = previousItemsRef
        const isSameItems = current && JSON.stringify(current) === JSON.stringify(items)
        if (items && (items.length > 0 || (items.length === 0 && rows)) && tableConfig && !isSameItems) {
            setRows(getFormattedRows({ items, tableConfig }))
            previousItemsRef.current = items
        }
    }, [config, items])

    const processRowUpdate = (newRow, oldRow, configAction) => {
        const { id } = newRow
        const key = Object.keys(newRow).find((key) => newRow[key] !== oldRow[key])
        const action = configAction || `${key}Management`
        if (key) {
            setRows((prev) => prev.map((row) => row?.id === id ? newRow : row))
            const data = { id, [key]: newRow[key] }
            doAction({ action, data, model })
            return newRow
        } else return oldRow
    }

    return { processRowUpdate, rows }
}

// Logica per preservare il focus del GridToolbarQuickFilter, forzandolo dopo ogni aggiornamento
export const useSearchFocus = () => {

    const searchRef = useRef(null)
    const [isSearchFocused, setIsSearchFocused] = useState(false)

    useEffect(() => {
        if (isSearchFocused && searchRef.current) {
            searchRef.current.focus()
        }
    })

    return { isSearchFocused, searchRef, setIsSearchFocused }
}
