import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerButton, List as ListComponent, useDrawer } from 'src/mtska-frontend-app-component'
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';

import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';
import { ImportButton } from '../../Import'

const dataProviderName = 'persons/person';
const drawerRouteBase = 'persons';
const moduleName = "Persons";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const { doAction, loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        <DrawerButton
                            drawerRoute={drawerRouteBase + '/create'}
                            icon='faAdd'
                            params={{
                                detailStack: params.detailStack ?? {},
                                relatedElements: params.relatedElements ?? {}
                            }}
                            label={t('New')}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.create']}
                        />,
                        <ImportButton
                            callback={() => loadAll()}
                            dataProviderName={dataProviderName}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.import']}
                            icon='faDownload'
                        />
                        /* <SplitButton
                            selectedItems={selected}
                            actions={[
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.export'],
                                    disabledIfNoItemselected: false,
                                    icon: "faUpload",
                                    label: t('Export'),
                                    onClick: () => {
                                        activate(
                                            'wizards/download',
                                            {
                                                dataProviderName: dataProviderName
                                            },
                                        )
                                    }
                                },
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.notification'],
                                    disabledIfNoItemselected: true,
                                    icon: "faEnvelope",
                                    label: t('Notify'),
                                    onClick: () => {
                                        activate(
                                            'wizards/notify',
                                            {
                                                items: selected,
                                                dataProviderName: dataProviderName
                                            },
                                            (data) => {
                                                if (!data?.success) return;
                                                setSelected([]);
                                            }
                                        )
                                    }
                                }
                            ]}
                        />, */
                    ]}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                    selectionModel={selected}
                    doAction={doAction}
                />
            )}
        </>
    );
};

export default List;


