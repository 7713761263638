import { Default } from "./views";

const Module = {
    name: "contoeconomicoreports",
    defaultRoutePath: "/incomestatements/contoeconomicoreport",
    menuItemScope: "incomestatements",
    menuItemName: "contoeconomicoreports",
    routes: [
        {
            path: "/incomestatements/contoeconomicoreport",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                }
            ],
        },
    ],
    drawerRoutes: [
    ],

}

export default Module;
