import React, { useState, useEffect } from 'react';

import { Box } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { default as ReactApexChart } from 'react-apexcharts'
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useFormatter } from "../../utils";

const Timeline = ({ title, dataProviderName, icon, filters, format }) => {
    const { items, loadAll, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();

    const { formatCurrency } = useFormatter();

    const applyFormat = (val) => {
        switch (format) {
            case 'currency': 
                return formatCurrency(val);
            default:
                return val;
        }
    }

    useEffect(() => {
        if (dataProviderName && !loading) loadAll(filters);
    }, [reloadCounter]);

    useEffect(() => {
        if (items && typeof items === 'object' && !Array.isArray(items)) {
            setSeries([{
                name: title,
                data: items.series
            }]);
            setOptions({
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val) => applyFormat(val),
                    offsetY: -20,
                    style: {
                        fontSize: '10px',
                        fontWeight: '400',
                        colors: ["#304758"]
                    }
                },

                xaxis: {
                    categories: items.labels,
                    position: 'top',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false
                    }

                },
            })
        }
    }, [items])


    return (
        series && options && <Box className="mtska-chart mtska-chart-pie">
            <Icon icon={icon} family="fal"></Icon>
            <Text level="title-sm" className="title">{title}</Text>
            <Box className="chartwrapper">
                <ReactApexChart
                    type="bar"
                    options={options}
                    series={series}
                    height={200}
                />
            </Box>
        </Box>
    );
}

export default Timeline;