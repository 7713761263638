import { useEffect } from 'react'

export const useFieldWatcher = ({ setValue, watchedFields }) => {

    const {
        contractReajustmentType,
        contractDuration,
        start_at,
        totalLeaseFee,
        actualMonthlyMileage,
        projectedCostOrReimbursement,
        contractReajustmentStart_at,
        contractMileage, 
        projectedMileage,
        bracket1ExtraKmCost,
        bracket1KmReimbursement,
        originalTotalLeaseFee,
        contractDurationEnlapsed,
        contractDurationRemaining,
        totalCostOwnershipFee,
        actualTotalCostOwnershipFee
    } = watchedFields

    useEndAt({ setValue, watchedFields: { contractDuration, start_at } })
    useLifetimeLeaseFee({ setValue, watchedFields: { contractReajustmentType, contractDuration, contractDurationEnlapsed, contractDurationRemaining, originalTotalLeaseFee, totalLeaseFee } })
    useTotalCostOwnershipFee({ setValue, watchedFields: { contractReajustmentType, contractDuration, contractDurationEnlapsed, contractDurationRemaining, originalTotalLeaseFee, totalLeaseFee, projectedCostOrReimbursement } })
    useProjectedMileage({ setValue, watchedFields: { actualMonthlyMileage, contractDuration } })
    useProjectedMileageCost({ setValue, watchedFields: { contractMileage, projectedMileage, bracket1ExtraKmCost, bracket1KmReimbursement } })
    useEnlapsedRemaining({ setValue, watchedFields: { contractDuration, start_at, contractReajustmentStart_at } })
    useContractReajustmentSettlement({ setValue, watchedFields: { contractReajustmentType, originalTotalLeaseFee, totalLeaseFee, contractDurationEnlapsed } })
    useRecalculationsaving({ setValue, watchedFields: { totalCostOwnershipFee, actualTotalCostOwnershipFee } })
}

const useEndAt = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractDuration, start_at } = watchedFields

        if (start_at && contractDuration) {
            let endDate = new Date(start_at);
            endDate.setMonth(endDate.getMonth() + parseInt(contractDuration));
            endDate.setDate(endDate.getDate() - 1);

            setValue('end_at', endDate.toISOString());
        }
        
    }, [...Object.values(watchedFields)])
}

const useProjectedMileage = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { actualMonthlyMileage, contractDuration } = watchedFields

        setValue('projectedMileage', parseInt(actualMonthlyMileage * contractDuration));
        
    }, [...Object.values(watchedFields)])
}

const useRecalculationsaving = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { totalCostOwnershipFee, actualTotalCostOwnershipFee } = watchedFields

        setValue('recalculationsaving', parseFloat((actualTotalCostOwnershipFee - totalCostOwnershipFee).toFixed(2)) * -1);
        
    }, [...Object.values(watchedFields)])
}

const useProjectedMileageCost = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractMileage, projectedMileage, bracket1ExtraKmCost, bracket1KmReimbursement } = watchedFields

        const projectedDeltaMileage = projectedMileage - contractMileage;
        if (contractMileage && projectedMileage) {
            setValue('projectedDeltaMileage', projectedDeltaMileage);
        }

        if (projectedDeltaMileage < 0 ) {
            setValue('projectedCostOrReimbursement', parseFloat((projectedDeltaMileage * bracket1KmReimbursement).toFixed(2)));
        } else {
            setValue('projectedCostOrReimbursement', parseFloat((projectedDeltaMileage * bracket1ExtraKmCost).toFixed(2)));
        }
        
        
    }, [...Object.values(watchedFields)])
}

const useContractReajustmentSettlement = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractReajustmentType, originalTotalLeaseFee, totalLeaseFee, contractDurationEnlapsed } = watchedFields
        
        if (contractReajustmentType == "adjustment_with_settlement" && originalTotalLeaseFee && totalLeaseFee && contractDurationEnlapsed) {
            setValue('contractReajustmentSettlement', (totalLeaseFee - originalTotalLeaseFee) * contractDurationEnlapsed);
        } else {
            setValue('contractReajustmentSettlement', 0)
        }
        
    }, [...Object.values(watchedFields)])
}

const useLifetimeLeaseFee = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractReajustmentType, contractDuration, contractDurationEnlapsed, contractDurationRemaining, originalTotalLeaseFee, totalLeaseFee, projectedCostOrReimbursement } = watchedFields

        if (contractReajustmentType == "adjustment_without_settlement" && contractDurationRemaining && totalLeaseFee) {
            setValue('lifetimeLeaseFee', parseFloat((contractDurationEnlapsed * originalTotalLeaseFee) + (contractDurationRemaining * totalLeaseFee)).toFixed(2));
        } else if (contractDuration && totalLeaseFee) {
            setValue('lifetimeLeaseFee',  parseFloat(contractDuration * totalLeaseFee).toFixed(2));
        } 
        
    }, [...Object.values(watchedFields)])
}

const useTotalCostOwnershipFee = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractReajustmentType, contractDuration, contractDurationEnlapsed, contractDurationRemaining, originalTotalLeaseFee, totalLeaseFee, projectedCostOrReimbursement } = watchedFields

        if (contractReajustmentType == "adjustment_without_settlement" && contractDurationRemaining && totalLeaseFee) {
            setValue('totalCostOwnershipFee', parseFloat((contractDurationEnlapsed * originalTotalLeaseFee) + (contractDurationRemaining * totalLeaseFee) + (projectedCostOrReimbursement ?? 0)).toFixed(2));
        } else if (contractDuration && totalLeaseFee) {
            setValue('totalCostOwnershipFee', parseFloat(contractDuration * totalLeaseFee + (projectedCostOrReimbursement ?? 0)).toFixed(2));
        } 
        
    }, [...Object.values(watchedFields)])
}


const useEnlapsedRemaining = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { contractDuration, start_at,  contractReajustmentStart_at } = watchedFields

        if (start_at && contractDuration && contractReajustmentStart_at) {
            let start_at_date = new Date(start_at);
            let end_at_date = new Date(start_at);
            end_at_date.setMonth(end_at_date.getMonth() + parseInt(contractDuration));
            end_at_date.setDate(end_at_date.getDate() - 1);

            let contractReajustmentStart_at_date = new Date(contractReajustmentStart_at);

            const millisecondsInMonth = 30.436875 * 24 * 60 * 60 * 1000; // Media di giorni in un mese

            const enlapsed = (contractReajustmentStart_at_date - start_at_date) / millisecondsInMonth;
            setValue('contractDurationEnlapsed', parseFloat(enlapsed.toFixed(1)));

            const remaining = (end_at_date - contractReajustmentStart_at_date) / millisecondsInMonth;
            setValue('contractDurationRemaining', parseFloat(remaining.toFixed(1)));
        }
        
    }, [...Object.values(watchedFields)])
}