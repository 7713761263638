import { Default, Detail, List,  Create } from "./views";



const Module = {
    name: "notifications",
    defaultRoutePath: "/notifications/notification",
    menuItemScope: "notifications",
    menuItemName: "notifications",

    routes: [
        {
            path: "/notifications/notification",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
       
    ],
    
}

export default Module;
