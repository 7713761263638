import React from 'react';
import { Badge, Box } from '@mui/joy';
import Auth0LogoutButton from '../../../mtska-frontend-modules/modules/Auth/views/Auth0/Auth0LogoutButton';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { DrawerButton } from '../Drawer';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '.';

const UserMenu = () => {

    const { t } = useTranslation();


    const { item } = useDataProvider('auth/token');

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return 'N';
    }
    
    return (
        <Box className="user-menu">
            
            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} variant='plain' badgeContent={Number.isInteger(item?.data?.notificationsPending) ? parseInt(item?.data?.notificationsPending) : 0} color="danger" size='sm' >
                <DrawerButton className="user-icon" drawerRoute={'notificationUsers/list'} params={{ removeSaveButton: true }} label={ <> { getFirstCharacter(item?.data?.lastname)} </> } />
            </Badge>
            <LanguageSwitcher />

            <Box className="user-name">{getFirstCharacter(item?.data?.firstname) + ". " + item?.data?.lastname}</Box>
            <Box className="user-email">{item?.data?.roles}</Box>
            <Auth0LogoutButton className="user-logout"></Auth0LogoutButton>

        </Box>
    )
}

export default UserMenu
