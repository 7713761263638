import React from 'react'
import { Box } from '@mui/joy'

const CellSimple = ({ value, type = 'simple' }) => {
    return (
        <Box className={"list-element-cell cell-" + type}>
            {value || value === 0 ? value : ''}
        </Box>
    )
}

export default CellSimple