import React, { useContext } from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from 'src/mtska-frontend-app-component/components/Button';
import { LegalEntityAvatar } from 'src/mtska-frontend-app-component/components/Snippet';

import { useTranslation } from 'react-i18next'
import { useFormatter } from 'src/mtska-frontend-app-component/utils';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const RelationshipContract = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatDate, formatDistance, formatCurrency } = useFormatter();
    const { userCapabilities } = useContext(AppContext)

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return v ? (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            {
                (v?.contractFormula != 'ownership') ? (
                    <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                        <Box className="renter">
                            <LegalEntityAvatar name={v?.renter?.name} url={v?.renter?.logo?.url} withname={true} detail={v?.orderIdentifier ?? t('Missing order identifie')} />
                        </Box>
                        <Box className="identifier coupled">
                            <Box className="label">{t('contractIdentifier')}</Box>
                            <Box className="value">{v?.contractIdentifier}</Box>
                        </Box>
                        <Box className="duration coupled">
                            <Box className="label">{t('contractDuration')}</Box>
                            <Box className="value">{v?.contractDuration} {t('months')}</Box>
                        </Box>
                        <Box className="mileage coupled">
                            <Box className="label">{t('contractMileage')}</Box>
                            <Box className="value">{formatDistance(v?.contractMileage)}</Box>
                        </Box>
                        {
                            v?.contractReajustmentStart_at ? (

                                <Box className="end_at coupled highlight" data-isfuture={(new Date(v?.contractReajustmentStart_at) > new Date())}>
                                    <Box className="label">{(new Date(v?.contractReajustmentStart_at) > new Date()) ? t('contractReajustmentStart_at_short_future') : t('contractReajustmentStart_at_short')}</Box>
                                    <Box className="value">{formatDate(v?.contractReajustmentStart_at)}</Box>
                                </Box>
                            ) : (

                                <Box className="end_at coupled">
                                    <Box className="label">{t('start_at')}</Box>
                                    <Box className="value">{formatDate(v?.start_at)}</Box>
                                </Box>
                            )
                        }
                        {
                            v?.contractReajustmentEnd_at ? (

                                <Box className="end_at coupled highlight" data-isfuture={(new Date(v?.contractReajustmentEnd_at) > new Date())}>
                                    <Box className="label">{(new Date(v?.contractReajustmentEnd_at) > new Date()) ? t('contractReajustmentEnd_at_short_future') : t('contractReajustmentEnd_at_short')}</Box>
                                    <Box className="value">{formatDate(v?.contractReajustmentEnd_at)}</Box>
                                </Box>
                            ) : (

                                <Box className="end_at coupled">
                                    <Box className="label">{t('end_at')}</Box>
                                    <Box className="value">{formatDate(v?.end_at)}</Box>
                                </Box>
                            )
                        }

                        {
                            !userCapabilities['auth/role.is_driver'] && !userCapabilities['auth/role.is_carmanager'] && (
                                <Box className="date coupled norightborder">
                                    <Box className="label">{t('totalLeaseFee')}</Box>
                                    <Box className="value">{formatCurrency(v?.totalLeaseFee)}</Box>
                                </Box>
                            )
                        }
                    </ListItemContent>
                ) : (
                    <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                        <Box className="identifier coupled">
                            <Box className="label">{t('contractFormula')}</Box>
                            <Box className="value">{t('ownership')}</Box>
                        </Box>
                        <Box className="start_at coupled">
                            <Box className="label">{t('start_at')}</Box>
                            <Box className="value">{formatDate(v?.start_at)}</Box>
                        </Box>
                    </ListItemContent>
                )
            }

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>

    ) : (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                non assegnato
            </ListItemContent>
        </ListItem>
    )
}


export default RelationshipContract


