import React, { useMemo } from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Services"
                icon="faCard"
                dataProviderName={'statistics/item/count/cars/service'}
            />
            <KPI
                title="Active drivers"
                icon="faPeople"
                dataProviderName={'statistics/active/count/cars/pool'}
                filters={{
                    'items': [
                        {
                            'field': 'assignment_start_at',
                            'operator': 'before',
                            'value': new Date().toISOString()
                        },
                        {
                            'field': 'assignment_end_at',
                            'operator': 'afterOrNull',
                            'value': new Date().toISOString()
                        }
                    ]
                }}
            />
            <KPI
                title="Suppliers"
                icon="faBuilding"
                dataProviderName={'statistics/item/count/companies/legalEntity'}
                filters={{
                    'items': [{
                        'field': 'type',
                        'operator': 'equals',
                        'value': 'supplier'
                    }]
                }}
            />
        </Box>
        <List />
    </>, [])

}

export default Default;


