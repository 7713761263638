import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Option, Select } from '@mui/joy'
import { AppContext } from '../../../mtska-frontend-app-scaffolding'
import { Icon } from 'src/mtska-frontend-app-component/assets'
import { LegalEntityAvatar } from '../Snippet'

export const LegalEntitySelect = ({ filterByLegalEntity, onChange = () => { }, options }) => {

    const { appConfiguration } = useContext(AppContext);
    const { t } = useTranslation();

    const selectedOption = options && options.find((option) => option.id === filterByLegalEntity);

    // CHI TOCCA MUORE
    let label = t('alllegalentity');
    let name = t('∞');
    if (appConfiguration?.legalEntitySelector?.active && filterByLegalEntity && selectedOption && selectedOption.name !== 'all') {
        label = selectedOption.name;
        name = selectedOption.name;
    }

    return (
        <Box className="legalentity-menu">

            <Box className="legalentity-icon">
                <LegalEntityAvatar name={name} url={selectedOption?.logo?.url} size='squared' />
            </Box>
            {options
                ? (
                    <Box className="legalentity-name">
                        <span className="label">{t('legalentities')}</span>
                        <span className="value">{label}</span>
                    </Box>
                )
                : (
                    <Box alignContent='end' textAlign='center'>
                        <CircularProgress size='sm' />
                    </Box>
                )
            }
            {appConfiguration?.legalEntitySelector?.active &&
                <Box className="legalentity-list">
                    <Select
                        autoComplete="off"
                        className="form-element-field form-element-field-select"
                        id="input-select-strategy"
                        name="input-select-strategy"
                        onChange={onChange}
                        slotProps={{ listbox: { sx: { maxHeight: '300px', placement: 'bottom-end' } } }}
                        value={filterByLegalEntity || ''}
                        variant="outlined"
                    >
                        {options && options.map((legalentity, k) => (
                            <Option key={k} value={legalentity.id}>{legalentity.name}</Option>
                        ))}
                    </Select>
                    <Icon icon="faChevronRight" className="selector-icon" />
                </Box>
            }
        </Box>
    )
}
