export const styles = {
    fullRowS: {
        alignContent: 'space-around',
        backgroundColor: '#f0f4f8',
        borderBottom: '1px solid #e0e0e0',
        borderTop: '1px solid #e0e0e0',
        fontWeight: 'bold',
        textAlign: 'center'
    }
}
