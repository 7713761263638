export const styles = {
    containerSectionS: {
        display: 'flex',
        flexDirection: 'column',
        gap: 25
    },
    infoS: {
        color: '#9fa6ad',
        fontSize: 14,
        textAlign: 'center',
    },
    selectS: {
        backgroundColor: '#fff',
        width: '250px'
    }
}
