import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/contract';
const model = 'cars/contract';
const modelExposedName = 'Contract';
const wizardAction = 'create';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { doAction, item, loadOne, loadAll, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const {modelName} = existingModules[dataProviderName]
    const detailConfig = {
        "tabs": [
            {
                "name": "create_new_contract",
                "fieldsets": [
                    {
                        "name": "contract_information",
                        "fields": {
                            "orderIdentifier": {
                                "type": "text",
                                "className": "col-3"
                            },
                            "ordered_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarPen"
                            },
                            "offerIdentifier": {
                                "type": "text",
                                "className": "col-3"
                            },
                            "offer_at": {
                                "type": "date",
                                "className": "col-3"
                            },
                            "contractIdentifier": {
                                "type": "text",
                                "className": "col-3"
                            },
                            "contract_at": {
                                "type": "date",
                                "className": "col-3"
                            },
                            "contractFormula": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "longterm",
                                        "value": "longterm"
                                    },
                                    {
                                        "label": "midterm",
                                        "value": "midterm"
                                    },
                                    {
                                        "label": "shortterm",
                                        "value": "shortterm"
                                    },
                                    {
                                        "label": "leasing",
                                        "value": "leasing"
                                    },
                                    {
                                        "label": "ownership",
                                        "value": "ownership"
                                    }
                                ],
                                "className": "col-3"
                            },
                            "contractMode": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "preleasing",
                                        "value": "preleasing"
                                    },
                                    {
                                        "label": "substitute",
                                        "value": "substitute"
                                    },
                                    {
                                        "label": "final",
                                        "value": "final"
                                    }
                                ],
                                "className": "col-3"
                            },
                            "renter": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "supplier_type",
                                                "operator": "equals",
                                                "value": "renter"
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity"
                            },
                            "legalEntity": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "type",
                                                "operator": "equals",
                                                "value": "customer"
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity",
                                "label": "customer"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    
    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            errorMessage={errorMessage}
            errors={errors}
            saving={saving}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


