import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';
const wizardName = 'Car Configuration Access Management';
const wizardObject = 'Car';
const wizardAction = 'configuratorManagementMulti';

const ConfiguratorManagementMulti = (params) => {
    const { t } = useTranslation();
    const [segmentList, setSegmentList] = useState();
    const { userCapabilities, existingModules } = useContext(AppContext);

    const { items: segments, loadAll: loadAllSegments } = useDataProvider('infocar/getAvailableSegments');

    // DATA PROVIDER
    const {
        item,
        getOne,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadAllSegments().then((segments) => {
            let sl = segments.items.map((segment) => {
                return {
                    "label": segment.Descrizione,
                    "value": segment.Descrizione,//segment.Codice
                }
            });
            setSegmentList(sl);
        });
    }, []);

    const modelName = existingModules.modelName

    let detailConfig = {
        "tabs": [
            {
                "name": "configuration",
                "fieldsets": [
                    {
                        "name": "configuration",
                        "fields": {
                            "configuration": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "fleetConfigurationActive": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "true",
                                        "value": "true"
                                    },
                                    {
                                        "label": "false",
                                        "value": "false"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faToggle"
                            },
                            "fleetConfigurationLinkValid_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarHeart"
                            },
                            "fleetConfigurationLinkExpire_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarXmark"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    };

    let values = {
        "ids": params?.person?._id,
        "email": params?.person?.email,
        "segment": params?.person?.employment?.segment,
    };

    console.info('qui');
    const persons = params.personIds.map(async (id) => {
        const person = getOne(id);
        console.info('person', person);
        // addPersonDetailConfig(person._id);
        // addPersonValues(person);
        return person;
    })

    const addPersonValues = (person) => {
        values[person._id+".id"] = person._id;
        values[person._id+".name"] = person.firstname+" "+person.lastname;
        values[person._id+".segment"] = person._related_employment_segment;
        values[person._id+".email"] = person._related_employment_segment;
    }

    const addPersonDetailConfig = (id) => {
        detailConfig.tabs[0].fieldsets.push({
            "name": "configuration",
            "fields": {
                [id+".name"]: {
                    "type": "select",
                    "required": true,
                    "options": segmentList,
                    "className": "col-3",
                    "icon": "faCircleX"
                },
                [id+".segment"]: {
                    "type": "select",
                    "required": true,
                    "options": segmentList,
                    "className": "col-3",
                    "icon": "faCircleX"
                },
                [id+".fleetConfigurationQuantityUnlimited"]: {
                    "type": "select",
                    "required": true,
                    "options": [
                        {
                            "label": "true",
                            "value": "true"
                        },
                        {
                            "label": "false",
                            "value": "false"
                        }
                    ],
                    "className": "col-3",
                    "icon": "faToggle"
                },
                [id+".fleetConfigurationLink"]: {
                    "type": "text",
                    "required": true,
                    "readonly": true,
                    "className": "col-3",
                    "icon": "faGlobe"
                },
                [id+".fleetDriverCode"]: {
                    "type": "hidden",
                },
                [id+".email"]: {
                    "type": "hidden",
                },
                [id+".id"]: {
                    "type": "hidden",
                },
            }
        })
    }

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return (
        segmentList && persons && <DetailComponent
            values={values}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={(params?.person?.employment?.legalEntity?.configurator !== 'infocarfleet') ? t('LegalEntity not allowed to configure') : errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Manage the access to Car Configurator for {{firstname}} {{lastname}}', { wizardObject: wizardObject, firstname: params?.person?.firstname, lastname: params?.person?.lastname })}
                />
            }
            {...params}
        />
    );
}

export default ConfiguratorManagementMulti;


