import React, { useState } from 'react'
import { Box } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { Tag } from 'src/mtska-frontend-app-component';

const DriverHelpIt = () => {
    const { t } = useTranslation();
    const [selectedRenter, setSelectedRenter ] = useState();
    const getSubdomain = () => {
        const host = window.location.hostname.split('.'); 
        return host.length > 2 ? host[0] : ''; 
    };
    const customerSupportEmail = "driver"+getSubdomain()+"@myfleetsolution.it"

    
    return (
        <Box className={"dashboard dashboard-driver-help"+(selectedRenter ? ' filtered '+selectedRenter : '')}>
            <Tag>{t('Help')}</Tag>
            <h1 style={{ color: 'var(--app-main)' }}>{t('Guida pratica ai servizi di mobilità')}</h1>
            <p class="smielato">
                Caro Driver,<br /><br />
                sappiamo quanto sia importante per te gestire ogni tua necessità di mobilità in modo semplice e diretto.  Allo scopo ti abbiamo preparato una guida pratica che ti indichi chi contattare per ogni esigenza.<br />
                Ti consigliamo di stamparne una copia e di tenerla in auto.<br /><br />
                Grazie
            </p>
            <h4>Seleziona il noleggiatore per filtrare le informazioni:</h4>
            <ul className="renterSelecter">
                <li><Tag color={selectedRenter == 'arval' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('arval')}>Arval</Tag></li>
                <li><Tag color={selectedRenter == 'leaseplan' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('leaseplan')}>Leaseplan</Tag></li>
                <li><Tag color={selectedRenter == 'vwleasing' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('vwleasing')}>Volkswagen Leasing</Tag></li>
            </ul>
            <div class="assistance-container">
                <div class="assistance-item">
                    <h2>Esigenze Urgenti</h2>
                </div>

                <div class="assistance-item">
                    <h3>SOCCORSO STRADALE ITALIA</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> 800 16 55 00 - Se Mid Term Contattare il numero 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> 800 146 146 - Se Flexi Plan Contattare il numero 800.146.146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Contattare il numero 800.44.99.88
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>SOCCORSO STRADALE ESTERO</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> +39 055 5329600 - Se Mid Term Contattare il numero 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> 02 66165560 - Se Flexi Plan Contattare il numero 800.146.146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Contattare il numero +39 02.66.16.53.02
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>PRENOTARE VETTURA SOSTITUTIVA</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> Presso il Service Point dell'intervento oppure in autonomia al numero 800 16 55 00 - Se Mid Term Contattare il numero 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Presso il Service Point dell'intervento oppure in autonomia al numero 800 146 146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Richiederla direttamente al Service Point che effettuerà il ripristino vettura oppure in autonomia al numero 800.44.99.88
                        </div>
                    </div>
                </div>
            </div>

            <div class="assistance-container">
                <div class="assistance-item">
                    <h2>Incidente, furto, atto vandalico, danno kasco, certificato di assicurazione</h2>
                </div>

                <div class="assistance-item">
                    <h3>DENUNCIA DI UN SINISTRO</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> scrivere a <a href="mailto:sinistri@arval.it">sinistri@arval.it</a> con in cc <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Compilare il form online sul <a href="https://www.ayvens.com/it-it/assistenza-clienti/denuncia-sinistri/">portale del Noleggiatore</a> e inoltrare e-mail a: <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Compilare il form online sul <a href="https://www.vwfs.it/denuncia-sinistri.html">portale del Noleggiatore</a> c/c <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>CERTIFICATO DI ASSICURAZIONE</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> oppure contattare il numero <a href="tel:800165500">800.16.55.00</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> oppure contattare il numero <a href="tel:800146146">800.146.146</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> oppure contattare il numero <a href="tel:0233027582">02 33027582</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="assistance-container">
                <div class="assistance-item">
                    <h2>Gestione quotidiana</h2>
                </div>

                <div class="assistance-item">
                    <h3>REPERIRE UN'OFFICINA, CARROZZERIA, CENTRO REVISIONI O GOMMISTA</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> <a href="tel:800165500">800 16 55 00</a> oppure tramite APP "MYARVAL" - Se Mid Term contattare il numero <a href="tel:800894506">800 894506</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Contattare il numero <a href="tel:800146146">800.146.146</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Fare riferimento alla rete ufficiale di concessionarie e Service Partner del Gruppo Volkswagen. Contattare il numero <a href="tel:0233027582">02 33027582</a>
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>PRENOTARE UN TAGLIANDO, UN CAMBIO GOMME O UN INTERVENTO DI MANUTENZIONE</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> <a href="tel:800165500">800 16 55 00</a> oppure tramite APP "MYARVAL" - Se Mid Term contattare il numero <a href="tel:800894506">800 894506</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Contattare il numero <a href="tel:800146146">800.146.146</a> oppure il Driver Contact Center al <a href="tel:0800845650">0800 845 650</a>, internazionale <a href="tel:+41447466333">+41 44 746 6333</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Fare riferimento alla rete ufficiale di concessionarie e Service Partner del Gruppo Volkswagen. Contattare il numero <a href="tel:0233027582">02 33027582</a>
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>RICHIESTA DI INFORMAZIONI/RISOLUZIONE DI PROBLEMATICHE VARIE</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> Scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Scrivere a <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                    </div>
                </div>
            </div>

        </Box>
    )

}

export default DriverHelpIt;


