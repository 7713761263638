import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'companies/legalentity';
const wizardName = 'SaltEdge Connection';
const wizardObject = 'SaltEdge';
const wizardAction = 'saltedgeConnection';

const SaltedgeConnection = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { 
        item, 
        errorMessage, 
        errors, 
        saving, 
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "configuration": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "name": {
                                "type": "text",
                                "className": "col-12",
                                "placeholder": "insert",
                                "icon": "faInputText"
                            },
                            "start_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faCalendarHeart"
                            },
                            "end_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faCalendarXmark"
                            },
                            "mandatory_legal_entity_fields": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "email": {
                                "type": "text",
                                "className": "col-6",
                                "placeholder": "insert",
                                "required":true,
                                "icon": "faInputText"
                            },
                            "vat": {
                                "type": "text",
                                "className": "col-6",
                                "placeholder": "insert",
                                "required":true,
                                "icon": "faInputText"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }
    
    return <>
        <DetailComponent
            values={{ 
                "id" : item?.data?._id, 
                "start_at": new Date().toISOString(), 
                "name": item?.data?.name + ' > SaltEdge', 
                "email": item?.data?.email, 
                "vat": item?.data?.vat 
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Connect {{wizardObject}} to {{name}}', { wizardObject:wizardObject, name: item?.data?.name })}
                />
            }
            {...params}
        />
    </>
}

export default SaltedgeConnection;


