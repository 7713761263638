import React from 'react'
import { styles as commonStyles } from '../../../../../excelPreview.styles'
import { styles } from './subCell.styles'

export const SubCell = ({ cell, child, getFormattedValue = () => { } }) => {

    const { fieldKey, formatType } = child || {}

    const newField = cell[fieldKey] || {}

    const { style: customCellS = {}, value: newValue = '' } = newField

    const isNumber = (newValue || newValue === 0) && !isNaN(Number(newValue))

    const formattedNewValue = getFormattedValue({ formatType, value: newValue })

    const { cellS } = commonStyles
    const { childS } = styles

    return (
        <div
            style={{ ...cellS, ...childS, ...customCellS, textAlign: isNumber ? 'right' : 'left' }}
            title={formattedNewValue}
        >
            {formattedNewValue}
        </div>
    )
}
