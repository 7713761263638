import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { useFormatter } from 'src/mtska-frontend-app-component/utils';
import { LegalEntityAvatar, Tag } from '../Snippet';
import { Icon } from '../../assets';


const RelationshipContoEconomicoRecordGroup = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatCurrency } = useFormatter();

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="coupled">
                    <LegalEntityAvatar name={v?.externalEntity?.name}  url={v?.externalEntity?.picture?.url} />
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('totalValue')}</Box>
                    <Box className="value">{formatCurrency(v?.totalValue)}</Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('documentID')}</Box>
                    <Box className="value">{v?.documentID}</Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('kind')}</Box>
                    <Box className="value">{v?.kind ? t(v?.kind) : <Tag color={"warning"} title={t("Unassigned")}><Icon icon="faWarning" />{t('Missing')}</Tag> }</Box>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipContoEconomicoRecordGroup



