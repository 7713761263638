import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import { Backdrop } from "@mui/material"

const dataProviderName = 'documents/document';
const wizardName = 'Car picture association';
const wizardObject = 'Car Picture';
const wizardAction = 'carPictureAssociation';

const CarPictureAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const [drawable, setDrawable] = useState(false);





    // DATA PROVIDER
    const {
        item,
        save,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, true);

    const { item: car, loadOne: loadOneCar } = useDataProvider('cars/car');

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "document",
                "fieldsets": [
                    {
                        "name": "document",
                        "fields": {
                            "file": {
                                "type": "dropzone",
                                "className": "col-12",
                                "icon": "faFile"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }


    useEffect(() => {
        console.info('CarPictureAssociation', params.carId)
        loadOneCar(params.carId).then(() => {
            console.info('OK CarPictureAssociation', car?.data)
            setDrawable(true);
        });
    }, []);

    // DOCUMENT ****NON USA**** QUESTO PROCESSO DI CREAZIONE TRAMITE JOB
    // 
    // const handleSave = (data) => {
    //     doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    // }

    const [editing, setEditing] = useState(false)


    useEffect(() => {
        if (success && editing) {
            loadOneCar(params.personId).then(() => {
                close();
            });
        }
    }, [success]);

    const handleSave = async (data) => {
        save && save({ ...data, filters: params.filters }).then(() => { close() })
    }

    return (
        !drawable ? <Backdrop open={!drawable} style={{ backgroundColor: "white" }}><CircularProgress /></Backdrop> :
            <DetailComponent
                values={{
                    "car_id": car?.data?._id,
                    "type": "carPicture",
                    "visibleToDriver": "true",
                    "name": t("Picture ") + (car?.data?.plate ? ' ' + car?.data?.plate : '')
                }}
                modelName={modelName}
                detailConfig={detailConfig}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                title={t(wizardName)}
                canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
                header={
                    <InfoHeader
                        view="wizard"
                        title={t(wizardName)}
                        subtitle={t('Add a {{wizardObject}} to {{plate}}', { wizardObject: wizardObject, plate: car?.data?.plate })}
                    />
                }
                {...params}
            />
    )
}

export default CarPictureAssociation;


