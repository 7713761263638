export const useFieldReadonlyHandler = () => {

    const verifyCondition = (conditiongroup, watch, getValues) => {

        console.info('verifyCondition START', conditiongroup);
        if(conditiongroup?.readonly ) {
            return conditiongroup?.readonly 
        }
        
        let readonly = false;
        let innerConditions = [];
        Array.isArray(conditiongroup.readonlycondition) && conditiongroup.readonlycondition.map((rule, k) => {
            if (Array.isArray(rule)) {
                innerConditions.push(verifyInnerRule(rule, watch, getValues))
            } else {
                innerConditions.push(rule)
            }
        })

        if (innerConditions.length == 1) {
            
            console.info('verifyCondition 1 innerConditions', conditiongroup.name, conditiongroup.readonlycondition, innerConditions);
            return innerConditions[0];
        } else if (innerConditions.length == 3 && innerConditions[1] == 'and') {
            //console.info('verifyCondition 3 innerConditions', conditiongroup.name, conditiongroup.readonlycondition, innerConditions);
            return innerConditions[0] && innerConditions[2];
        } else if (innerConditions.length == 3 && innerConditions[1] == 'or') {
            return innerConditions[0] || innerConditions[2];
        }

        return readonly;
    }

    const verifyInnerRule = (rule, watch, getValues) => {
        let readonly = false;
        if (rule[2] == 'null') rule[2] = null


        switch (rule[1]) {
            case '=':
                if (watch(rule[0]) == rule[2]) {
                    readonly = true;
                }
                break;
            case '!=':
                if (watch(rule[0]) != rule[2]) {
                    readonly = true;
                }
                break;
            case 'in':
                if (rule[2].includes(watch(rule[0]))) {
                    readonly = true;
                }
                break;
            case 'nin':
                if (!rule[2].includes(watch(rule[0]))) {
                    readonly = true;
                }
                break;
            case 'countEquals':
                if (watch(rule[0]).length == parseInt(rule[2])) {
                    readonly = true;
                }
                break;
            case 'countLess':
                if (watch(rule[0]).length > parseInt(rule[2])) {
                    readonly = true;
                }
                break;
            case 'countMore':
                if (watch(rule[0]).length < parseInt(rule[2])) {
                    readonly = true;
                }
                break;
        }

        // console.info('verifyInnerRule rule', rule, rule[0], watch(rule[0]), (readonly ? 'readonly' : 'non readonly'));
        return readonly;
    }
    return {
        verifyCondition
    }
}