import React from "react"
import { useTranslation } from 'react-i18next'
import { Text } from "../Typography";

const SectionParagraph = ({ level = 'paragraph-sm', icon, className, label, paragraph}) => {
    const { t } =  useTranslation();

    className = (typeof className != typeof undefined) ? className+" form-element" : "form-element";
    return <Text level={level} icon={icon} className={className} children={t(paragraph)} />
}

export default SectionParagraph