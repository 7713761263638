import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'companies/saltedgeaccount';
const wizardName = 'Get Account transactions';
const wizardObject = 'SaltEdge Account';
const wizardAction = 'gettransactions';

const SaltedgeConnection = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { 
        item, 
        errorMessage, 
        errors, 
        saving, 
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "get_transactions": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }
    
    return <>
        <DetailComponent
            values={{ 
                "id" : item?.data?._id
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Get new transactions from {{wizardObject}}', { wizardObject:wizardObject })}
                />
            }
            {...params}
        />
    </>
}

export default SaltedgeConnection;


