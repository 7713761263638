import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Setups"
                icon="faSteeringWheel"
                dataProviderName={'statistics/item/count/vehicles/setup'}
            />
            <KPI
                title="Vehicles"
                icon="faCar"
                dataProviderName={'statistics/item/count/cars/car'}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


