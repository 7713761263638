import React, { useState, useEffect } from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Tag, LegalEntityAvatar } from '../Snippet';
import { Anchor } from '../Typography';

const RelationshipSaltEdgeConnection = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();

    const tagcolors = {
        active: "success",
        inactive: "warning",
        disabled: "danger"
    }

    const [countdown, setCountdown] = useState();

    useEffect(() => {
        const targetDate = new Date(v?.created_at);
        const duration = 60 * 1000;
        const endDate = new Date(targetDate.getTime() + duration);

        const updateCountdown = () => {
            const now = new Date();
            const timeLeft = Math.max(0, Math.floor((endDate - now) / 1000));
            setCountdown(timeLeft);

            if (timeLeft <= 0) {
                clearInterval(timer);
            }
        };

        const timer = setInterval(updateCountdown, 1000);
        updateCountdown(); // Esegui subito il calcolo iniziale

        return () => clearInterval(timer);
    }, []);

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="name">
                    {v?.name}
                </Box>
                {(countdown > 0) && (
                    <Box className="connectionlink coupled">
                        <Anchor className="label" href={v?.saltEdgeCreationUrl} target='_blank' icon={'faPlug'}>{t('Connection Link')}</Anchor>
                        <Box className="value connectionLinxExpiration">Scade tra {countdown} secondi</Box>
                    </Box>
                )}
                <Box className={"status"}>
                    <Tag color={(tagcolors[v?.status ? v?.status : 'inactive'])} size="small" label={t(v?.status ? v?.status : 'inactive')} />
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipSaltEdgeConnection



