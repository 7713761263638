import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, List as ListComponent, Detail as DetailComponent, Confirm, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks'

const dataProviderName = 'imports/job'
const drawerRouteBase = 'imports'

const Detail = (params) => {

    const { dataProviderProps } = params || {}

    const {
        clickable = false,
        dataProviderName: dataProviderNameFP,
        dynamicColumns = true,
        field: fieldFP,
        title: titleFP
    } = dataProviderProps || {}

    const { t } = useTranslation();

    const { activate, close: closeDrawer } = useDrawer()

    const { userCapabilities, existingModules } = useContext(AppContext);
    const { modelName, detailConfig } = existingModules[dataProviderName]

    // DATA PROVIDER
    const { doAction, item, save, loadOne, success } = useDataProvider(dataProviderName, params.multipart);

    const [confirmDelete, setConfirmDelete] = useState()

    const handleImportAll = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Import")}
                okcallable={() => {
                    alert('import all ' + params.filters?._id)
                    doAction({
                        'action': 'import',
                        'model': 'Imports/Job',
                        'data': [params.filters?._id]
                    });
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Import all")}>
                {t("Are you sure you want to import all records?")}
            </Confirm>
        );
    }

    const handleImportSelected = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Import")}
                okcallable={() => {
                    doAction({
                        'action': 'import',
                        'model': 'Imports/Job',
                        'data': selected
                    });
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Import all")}>
                {t("Are you sure you want to import selected records?")}
            </Confirm>
        );
    }

    // const apiRef = useGridApiRef();

    const [selected, setSelected] = useState([]);
    const onRowSelectionModelChange = (selected) => {
        setSelected(selected);
    }

    const { loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderNameFP || 'imports/data')
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    /* useMemo(() => {
        const filterModel = {
            ...listFilterPageSort?.filterModel ?? {},
            items: [...listFilterPageSort?.filterModel?.items ?? [], {
                operator: 'equals',
                field: fieldFP || 'job_id',
                value: params.id
            }]
        };

        loadAll && loadAll(
            filterModel, 
            listFilterPageSort?.sortModel, 
            (listFilterPageSort?.paginationModel?.page || 0) + 1, 
            (listFilterPageSort?.paginationModel?.pageSize || 20)
        )

    }, [listFilterPageSort, params.filters]); */

    const newParams = {
        filters: {
            items: [
                ...listFilterPageSort?.filterModel?.items ?? [],
                {
                    field: fieldFP || 'job_id',
                    operator: 'equals',
                    value: params.id
                }
            ]
        }
    }

    useLoadAll({ listFilterPageSort, loadAll, params: { ...params, ...newParams }, reloadCounter })

    const handleRowDoubleclick = (row) => {
        if (clickable) {
            const { id } = row || {}
            console.log('id file', id)
            activate(
                drawerRouteBase + '/detail',
                {
                    dataProviderProps: {
                        dataProviderName: 'imports/data',
                        dynamicColumns: false,
                        field: 'job_id',
                        title: 'File detail'
                    },
                    id
                }
            )
        }
    }

    return (
        <>
            <ListComponent
                {...params}
                onRowDoubleClick={handleRowDoubleclick}
                onFilterPageSortChange={handleFilterPageSortChange}
                items={items}
                total={total}
                loading={loading}
                onRowSelectionModelChange={onRowSelectionModelChange}
                dynamicColumns={dynamicColumns}
                // apiRef={apiRef}
                enableSelection
                dataProviderName={dataProviderNameFP || 'imports/data'}
                title={titleFP || 'Import data'}
            // leftButtons={[]}
            /* rightButtons={[
                <Button
                    disabled={selected.length === 0}
                    onClick={() => {
                        handleImportSelected();
                    }}> Import selected</Button>,
                <Button onClick={() => {
                    handleImportAll();
                }}> Import all</Button>
            ]} */
            />
            {confirmDelete}
        </>

    )
}

export default Detail
