import React from "react"
import { Button  } from '../../../mtska-frontend-app-component';
import { useDrawer } from "./DrawerContext";


export const DrawerButton = ({ drawerRoute, params, callback, label, icon, family, disabled, loading, variant, size = "regular", className, title }) => {

    const { activate } = useDrawer();

    return (
        <Button
            disabled={disabled}
            onClick={() => {
                activate(drawerRoute, params, callback);
            }}
            icon={icon}
            family={family}
            loading={loading}
            variant={variant}
            size={size}
            className={className}
            label={label} 
            title={title}
        />
    );
};


