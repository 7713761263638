import * as Views from "./views";

const Module = {
    name: "dashboard",
    defaultRoutePath: "/dashboard",
    menuItemScope: "dashboard",
    menuItemName: "dashboard",

    routes: [
        {
            path: "/dashboard",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    index: true,
                    path: "driverhelp",
                    Component: Views.DriverHelp,
                },
            ],
        },
    ],
    drawerRoutes: [     
    ],
}

export default Module;
