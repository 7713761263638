import * as Views from './views'
import { Previewer } from './wizards'

const Module = {
    name: "cashflowrecords",
    defaultRoutePath: "/cashflows/cashflowrecord",
    menuItemScope: "cashflows",
    menuItemName: "cashflowrecords",
    routes: [
        {
            path: "/cashflows/cashflowrecord",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        {
            path: "create",
            Component: Views.Create,
        },
        {
            path: 'preview',
            Component: Previewer
        }
    ]
}

export default Module;
