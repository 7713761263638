import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'companies/legalentity';
const model = 'companies/legalEntity';
const modelExposedName = 'Legal Entity';
const wizardAction = 'create';

const Create = (params) => {

    const { t } = useTranslation();
    const { userCapabilities, existingModules } = useContext(AppContext);;


    // DATA PROVIDER
    const {
        doAction,
        item,
        errorMessage,
        errors,
        saving,
    } = useDataProvider(dataProviderName, params.multipart);


    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "create_new_legal_entity",
                "fieldsets": [
                    {
                        "name": "legal_entity_information",
                        "fields": {
                            "name": {
                                "type": "text",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputText",
                                "label": "legal_entity_name"
                            },
                            "legalForm": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "srl",
                                        "value": "srl"
                                    },
                                    {
                                        "label": "spa",
                                        "value": "spa"
                                    },
                                    {
                                        "label": "sas",
                                        "value": "sas"
                                    },
                                    {
                                        "label": "snc",
                                        "value": "snc"
                                    }
                                ],
                                "className": "col-2",
                                "placeholder": "insert"
                            },
                            "vat": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "status": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "active",
                                        "value": "active"
                                    },
                                    {
                                        "label": "inactive",
                                        "value": "inactive"
                                    }
                                ],
                                "className": "col-6",
                                "placeholder": "insert"
                            },
                            "industry": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "agricultureAndLivestock",
                                        "value": "agricultureAndLivestock"
                                    },
                                    {
                                        "label": "foodAndBeverage",
                                        "value": "foodAndBeverage"
                                    },
                                    {
                                        "label": "manufacturing",
                                        "value": "manufacturing"
                                    },
                                    {
                                        "label": "construction",
                                        "value": "construction"
                                    },
                                    {
                                        "label": "energyAndUtilities",
                                        "value": "energyAndUtilities"
                                    },
                                    {
                                        "label": "wholesaleAndRetailTrade",
                                        "value": "wholesaleAndRetailTrade"
                                    },
                                    {
                                        "label": "transportationAndLogistics",
                                        "value": "transportationAndLogistics"
                                    },
                                    {
                                        "label": "informationTechnology",
                                        "value": "informationTechnology"
                                    },
                                    {
                                        "label": "financialServices",
                                        "value": "financialServices"
                                    },
                                    {
                                        "label": "healthcareAndPharmaceuticals",
                                        "value": "healthcareAndPharmaceuticals"
                                    },
                                    {
                                        "label": "educationAndTraining",
                                        "value": "educationAndTraining"
                                    },
                                    {
                                        "label": "realEstate",
                                        "value": "realEstate"
                                    },
                                    {
                                        "label": "tourismAndHospitality",
                                        "value": "tourismAndHospitality"
                                    },
                                    {
                                        "label": "entertainmentAndMedia",
                                        "value": "entertainmentAndMedia"
                                    },
                                    {
                                        "label": "professionalServices",
                                        "value": "professionalServices"
                                    },
                                    {
                                        "label": "publicAdministration",
                                        "value": "publicAdministration"
                                    }
                                ],
                                "className": "col-6",
                                "placeholder": "insert"
                            },
                            "supplier_type": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "renter",
                                        "value": "renter"
                                    },
                                    {
                                        "label": "highway",
                                        "value": "highway"
                                    },
                                    {
                                        "label": "petrol",
                                        "value": "petrol"
                                    },
                                    {
                                        "label": "tyre",
                                        "value": "tyre"
                                    },
                                    {
                                        "label": "workshop",
                                        "value": "workshop"
                                    }
                                ],
                                "className": "col-12",
                                "placeholder": "insert",
                                "visibilitycondition": [
                                    [
                                        "type",
                                        "=",
                                        "supplier"
                                    ]
                                ]
                            },
                            "holding": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "legalentities"
                                },
                                "view": "legalEntity",
                                "capability": "company.holding.manage",
                                "visibilitycondition": [
                                    [
                                        "type",
                                        "=",
                                        "customer"
                                    ]
                                ]
                            },
                        }
                    },
                    {
                        "name": "contacts",
                        "fields": {
                            "telephone": {
                                "type": "text",
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "email": {
                                "type": "email",
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "website": {
                                "type": "text",
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "preferences": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "currency": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "EUR",
                                        "value": "EUR"
                                    },
                                    {
                                        "label": "GBP",
                                        "value": "GBP"
                                    },
                                    {
                                        "label": "CHF",
                                        "value": "CHF"
                                    },
                                    {
                                        "label": "USD",
                                        "value": "USD"
                                    }
                                ],
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "measurementSystem": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "metric",
                                        "value": "metric"
                                    },
                                    {
                                        "label": "imperial",
                                        "value": "imperial"
                                    }
                                ],
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                            "dateFormat": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "dd/mm/yyyy",
                                        "value": "dd/mm/yyyy"
                                    },
                                    {
                                        "label": "mm/dd/yyyy",
                                        "value": "mm/dd/yyyy"
                                    }
                                ],
                                "className": "col-4",
                                "placeholder": "insert"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const [editing, setEditing] = useState(false)

    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data))
    }

    return <>
        <DetailComponent
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


