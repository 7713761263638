import React, { useState, useEffect } from 'react';

import { Box, List, ListItem } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useDrawer } from '../Drawer';

const Table = ({ title, dataProviderName, icon, filters, columns, drawerRouteBase }) => {
    const { items, loadAll, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [drawable, setDrawable] = useState(false);
    const { activate } = useDrawer();

    const { t } = useTranslation();


    useEffect(() => {
        if (dataProviderName && !loading) loadAll(filters);
    }, [reloadCounter]);

    useEffect(() => {
        if (items && Array.isArray(items)) {
            setDrawable(true)
        }
    }, [items])

    const handleRowClick = (item) => {
        if (item.status == 'running') {
            activate(drawerRouteBase + '/detail', { id: item.id });
        }
    }

    return (
        drawable && <Box className="mtska-chart mtska-chart-table">
            <Icon icon={icon} family="fal"></Icon>
            <Text level="title-sm" className="title">{title}</Text>
            <DataGridPro
                rows={items}
                columns={columns}
                onRowDoubleClick={(params) => handleRowClick(params.row)}
                getRowId={(row) => row._id} // Usare _id come identificatore univoco per le righe
                disableColumnSelector
                disableRowSelectionOnClick
                pageSize={10}
                rowHeight={30}
                rowsPerPageOptions={[10, 25, 50]}
                hideFooter
                className="mtska-chart-table-data"
            />
        </Box>
    );
}

export default Table;