import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { IconButton } from '../../assets'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid'

const ListToolbar = ({ isFullScreen, leftButtons = [], rightButtons = [], searchRef, setIsSearchFocused, toggleFullscreen }) => {

    const [settingsOpened, setSettingsOpened] = useState(false)

    const handleSettingsToggle = () => {
        setSettingsOpened(!settingsOpened)
    }

    return (
        <GridToolbarContainer className="list-toolbar">
            <Box className="list-toolbar-secondary list-toolbar-filter">
                <GridToolbarQuickFilter
                    onBlur={() => setIsSearchFocused(false)}
                    onFocus={() => setIsSearchFocused(true)}
                    inputRef={searchRef}
                    placeholder="Search..."
                    sx={{ marginLeft: "auto" }}
                />
                {leftButtons.map((v, k) => <Box key={k}>{v}</Box>)}
            </Box>
            <Box className="list-toolbar-primary">
                {settingsOpened ? (
                    <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                    </GridToolbarContainer>
                ) : (
                    rightButtons.map((v, k) => <Box key={k}>{v}</Box>)
                )}
                <IconButton onClick={handleSettingsToggle} className="list-toolbar-activator" icon="faEllipsisVertical" />
                <IconButton icon={isFullScreen ? 'faMinimize' : 'faMaximize'} onClick={() => toggleFullscreen()} />
            </Box>
        </GridToolbarContainer>
    )
}

export default ListToolbar
