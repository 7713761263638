import React, { useState, useEffect } from 'react';

import { Box } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { default as ReactApexChart } from 'react-apexcharts'
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const Pie = ({ title, dataProviderName, icon, filters }) => {
    const { items, loadAll, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        if (dataProviderName && !loading) loadAll(filters);
    }, [reloadCounter]);

    useEffect(() => {
        if (items && typeof items === 'object' && !Array.isArray(items)) {
            setSeries(items.series);
            setOptions({
                labels: items.labels,
                theme: {
                    palette: 'palette2'
                },
                chart: {
                    type: 'donut',
                    width: 'auto',
                    height: '100%',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                        return parseFloat(val).toFixed(1) + '%'
                    },
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ['#FFF']
                    },
                    dropShadow: {
                        enabled: false,
                    }
                },
                plotOptions: {
                    pie: {
                        customScale: 1.1,
                        donut: {
                            size: '50%',
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    label: t('Total'),
                                    fontWeight: 200,
                                    offsetY: -20,
                                    color: undefined,
                                    formatter: function () {
                                        return items.series.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                    }
                                },
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    label: t('Total'),
                                    fontWeight: 200,
                                    offsetY: -20,
                                    color: undefined,
                                    formatter: function (value) {
                                        return value;
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '30px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    offsetY: -5,
                                    formatter: function (value) {
                                        return value;
                                    }
                                },
                            }
                        }
                    }
                }
            })
        }
    }, [items])


    return (
        series && options && <Box className="mtska-chart mtska-chart-pie">
            <Icon icon={icon} family="fal"></Icon>
            <Text level="title-sm" className="title">{title}</Text>
            <Box className="chartwrapper">
                <ReactApexChart
                    type="donut"
                    options={options}
                    series={series}
                    height={200}
                />
            </Box>
        </Box>
    );
}

export default Pie;