import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { LegalEntityAvatar, Tag, VehicleAvatar } from "../../../../mtska-frontend-app-component/components/Snippet";
import { Icon } from "src/mtska-frontend-app-component/assets";
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Text } from "../../../../mtska-frontend-app-component/components/Typography";
import { Button } from "../../../../mtska-frontend-app-component/components/Button";
import { useDataProvider } from "src/mtska-frontend-data-provider";

const dataProviderName = 'cars/carconfiguration';
const drawerRouteBase = 'cars';

const VehicleConfigurationCard = ({ itemview }) => {
    const { t } = useTranslation();
    // const { formatDate, formatDistance } = useFormatter();
    // const { item: car, loadOne } = useDataProvider(dataProviderName);
    // const [item, setItem] = useState();

    // useEffect(() => {
    //     loadOne(itemview?._id);
    // }, []);

    // const tagcolors = {
    //     installed: "success",
    //     available: "warning",
    //     running: "success",
    //     in_order: "secondary",
    //     in_offer: "secondary",
    //     in_configuration: "secondary",
    //     closed: "danger",
    //     preleasing: "warning",
    //     substitute: "warning",
    //     archived: "danger"
    // }


    return (
        itemview && <Box className={"vehicle-configuration"}>
            <Box className="date">{itemview.DataInizioStato}</Box>
            <Box className="category tag tag-success">{itemview.Categoria}</Box>
            <Box className="description">{itemview.DescrizioneVeicolo}</Box>
            <Box className="status">{itemview.StatoConfigurazione}</Box>
            {
                itemview.StatoConfigurazione == 'Configurazione salvata' && (
                    <Box className="warning_process warning_process_send">{t('You need to send this configuration to the Fleet Manager, using the InfocarFleet application, in order to have it quoted.')}</Box>
                )
            }
            {
                itemview.StatoConfigurazione == 'Configurazione inoltrata al Fleet Manager' && (
                    <Box className="warning_process warning_process_wait">{t('This configuration is either under quotation from different providers or the FleetManager is waiting for your feedback.')}</Box>
                )
            }
        </Box>
    )
}

export default VehicleConfigurationCard;