import React from 'react'
import { Box } from '@mui/joy'
import Switch from '@mui/material/Switch'
import { Icon } from 'src/mtska-frontend-app-component/assets'

const CellBoolean = ({ action, field: idField, row = {}, processRowUpdate, type = 'boolean', value }) => {

    const onChange = (event) => {
        const updatedRow = { ...row, [idField]: event.target.checked.toString() }
        processRowUpdate(updatedRow, row, action)
    }

    return (
        <Box className={"list-element-cell cell-" + type + " cell-" + type + "-" + ((String(value) === 'true') ? "true" : "false")}>
            {/* <Switch checked={value === 'true'} onChange={onChange} /> */}
            {(String(value) === 'true') ? (
                <Icon icon="faCircleCheck" />
            ) : (
                <Icon icon="faCircleXmark" />
            )}
        </Box>
    )
}

export default CellBoolean