import React, { useEffect, useState } from "react"
import { Box, Card, CardActions, CardContent, CircularProgress, ModalClose, Typography } from "@mui/joy"
import { Button, DetailTitle, InfoHeader, useDrawer } from "src/mtska-frontend-app-component";
import { useTranslation } from 'react-i18next';
import { useDataProvider } from "src/mtska-frontend-data-provider";

const Download = (params) => {
   const { t } = useTranslation();
   const { close } = useDrawer();
   const { download, downloading, getOne, saving } = useDataProvider(params.dataProviderName);

   const [downloadStarted, setDownloadStarted] = useState(false);
   useEffect(() => {
      if (downloading) {
         setDownloadStarted(true);
      }
   }, [downloading])

   return <>
      {params.isDrawer && (
         <Box className="drawer-section-header" >
            <ModalClose />
            <DetailTitle title={t("Export")} subtitle={t("")} />
         </Box>
      )}
      <Box className='drawer-section-content drawer-section-content-embedded form-element col-12'>
         <InfoHeader view="wizard" title={t("Export")} subtitle={""} />
         <Card className="form-wrapper">
            <CardContent>
               {downloading ?
                  <>
                     <CircularProgress></CircularProgress>
                  </> :
                  <>
                     {(!downloading && downloadStarted) ?
                        <>
                           <Typography>{t('You will receive an email with the export in a while.')}</Typography>

                        </> :
                        <>
                           <Typography>{t('This can take some time. You will receive an email when done!')}</Typography>
                        </>
                     }
                  </>
               }
            </CardContent>
            <CardActions>
               {downloading ?
                  <>
                  </> :
                  <>
                     {(!downloading && downloadStarted) ? <>
                        <Button variant={"soft"} onClick={() => { close(); }}>{t("Close")}</Button>
                     </> :
                        <>
                           <Button variant={"soft"} onClick={() => { close(); }}>{t("Cancel")}</Button>
                           <Button variant={"solid"} onClick={download}>{t("Export")}</Button>
                        </>
                     }
                  </>
               }
            </CardActions>
         </Card>
      </Box>
   </>
}

export default Download;

