import { DateRangeFilter } from "./customSelectionFilter/dateRangeFilter/DateRangeFilter"
import { NumericRangeFilter } from "./customSelectionFilter/numericRangeFilter/NumericRangeFilter"
import { SelectOptionsFilter } from "./customSelectionFilter/selectOptionsFilter/SelectOptionsFilter"

export const getCustomFilterOperators = ({ options, t, type, value }) => {
    const isDataRange = ['date', 'datetime'].includes(type)
    const isNumericRange = type === 'currency'

    const operator = isDataRange || isNumericRange ? 'between' : 'equals'
    
    return {
        InputComponent: (params) => {
            if (isDataRange) {
                return <DateRangeFilter {...params} value={value} />
            } else if (isNumericRange) {
                return <NumericRangeFilter {...params} value={value} />
            } else if (options) {
                return <SelectOptionsFilter {...params} options={options} value={value} />
            }
        },
        label: t('Select Filter'),
        value: operator
    }
}

export const getCustomLocaleText = ({ t }) => {
    return {
        headerFilterOperatorBetween: t('Between'),
        headerFilterOperatorContains: t('Contains'),
        headerFilterOperatorDoesNotContain: t('Does not contain'),
        headerFilterOperatorEquals: t('Equals'),
        headerFilterOperatorDoesNotEqual: t('Does not equal'),
        headerFilterOperatorStartsWith: t('Starts with'),
        headerFilterOperatorEndsWith: t('Ends with'),
        headerFilterOperatorIs: t('Is'),
        headerFilterOperatorNot: t('Is not'),
        headerFilterOperatorAfter: t('Is after'),
        headerFilterOperatorOnOrAfter: t('Is on or after'),
        headerFilterOperatorBefore: t('Is before'),
        headerFilterOperatorOnOrBefore: t('Is on or before'),
        headerFilterOperatorIsEmpty: t('Is empty'),
        headerFilterOperatorIsNotEmpty: t('Is not empty'),
        headerFilterOperatorIsAnyOf: t('Is any of'),
        headerFilterOperatorSelectFilter: t('Select Filter'),
        'headerFilterOperator=': t('Equals'),
        'headerFilterOperator!=': t('Not equals'),
        'headerFilterOperator>': t('Greater than'),
        'headerFilterOperator>=': t('Greater than or equal to'),
        'headerFilterOperator<': t('Less than'),
        'headerFilterOperator<=': t('Less than or equal to')
    }
}

export const getFormattedRows = ({ items, tableConfig }) => {
    const { columnsRendered = {} } = tableConfig
    const formattedRows = (items || []).map((item = {}) => {
        const formattedItem = {}
        Object.keys(item).forEach((key) => {
            const { [key]: value } = item
            const type = columnsRendered[key]?.type
            if (type === 'date' || type === 'datetime') {
                formattedItem[key] = value ? new Date(value) : undefined
            } else {
                formattedItem[key] = value
            }
        })
        return formattedItem
    })
    return formattedRows
}

export const moveArrayElement = (array, fromIndex, toIndex) => {
    const element = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, element)
    return array
}
