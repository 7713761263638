import { Default, Detail, List, Create, SplitRecord } from './views'
import { Previewer } from './wizards'

const Module = {
    name: "contoeconomicorecords",
    defaultRoutePath: "/incomestatements/contoeconomicorecord",
    menuItemScope: "incomestatements",
    menuItemName: "contoeconomicorecords",
    routes: [
        {
            path: "/incomestatements/contoeconomicorecord",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
        {
            path: "splitRecord",
            Component: SplitRecord,
        },
        {
            path: 'preview',
            Component: Previewer
        }
    ]
}

export default Module
