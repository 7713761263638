import React, { useState, useEffect, useContext } from 'react';
import { Detail as DetailComponent, Confirm, useDrawer, SplitButton } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {useLoadOne} from "src/mtska-frontend-data-provider/hooks/useLoadOne.hooks";

const dataProviderName = 'commercials/area';
const drawerRouteBase = 'commercials';

const Detail = (params) => {
    const { t } = useTranslation();
    const { activate, close: closeDrawer } = useDrawer();

    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success, reloadCounter } = useDataProvider(dataProviderName, params.multipart);
    const [assignedServices, setAssignedServices] = useState([])

    const {modelName, detailConfig} = existingModules[dataProviderName]

    // Ricerco i servizi collegati per poi passare il filtro al bottone di aggiunta costi
    const loadAssignedServices = (item) => {
        let services = [];
        const direct = item?.data?.services?.map((service) => service._id).flat(Infinity);
        const contract = item?.data?.contract?.services?.map((service) => service._id).flat(Infinity);
        const employment = item?.data?.pools?.map((pool) => pool.employments?.map((employment) => employment.services?.map((service) => service._id))).flat(Infinity);

        if (typeof direct != 'undefined') services.push(...direct);
        if (typeof contract != 'undefined') services.push(...contract);
        if (typeof employment != 'undefined') services.push(...employment);

        setAssignedServices(services);
    }

    useLoadOne({loadOne, params, reloadCounter, onSuccess: loadAssignedServices})


    const [editing, setEditing] = useState(false)

    const handleSave = (data) => {
        save({...data, filters: params.filters}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const [confirmDelete, setConfirmDelete] = useState()
    const handleDelete = (id) => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={() => {
                    destroy && destroy(id);
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Item elimination")}>
                {t("Are you sure to remove this element?")}
            </Confirm>
        );
    }

    return (
        <> 
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName+'.edit']}
                title={item?.data?.name == ' ' ? t('New area') : item?.data?.name}
                subtitle={(item?.data?.email) && item?.data?.email}
                rightButtons={[
                    <SplitButton
                        actions={[
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.delete'],
                                icon: "faTrash",
                                label: t('Delete'),
                                onClick: () => { handleDelete(item.data.id); }
                            },
                        ]}
                    />
                ]}
                {...params}
            />

            {confirmDelete}
        </>
    )
}

export default Detail;



