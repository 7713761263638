import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {  InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {default as DetailComponent }from './ContractExtension/Detail';

const dataProviderName = 'cars/contract';
const wizardName = 'Contract adjustment';
const wizardObject = 'Contract';
const wizardAction = 'contractExtension';

const ContractExtension = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const { doAction, item, loadOne, loading, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const { modelName } = existingModules

    if (!item && params?.id && loading !== true) {
        console.info('CARICO IL CONTRATTO DELLA VETTURA CHE HA APERTO IL WIZARD ', loading, item?.data?.id, params?.id)
        loadOne(params?.id);
    } else if ( item && params?.id && item.data?.id != params.id && loading !== true) {
        console.info('CARICO IL CONTRATTO DELLA VETTURA CHE HA APERTO IL WIZARD, SOVRASCRIVENDO item ESISTENTE ', loading, item?.data?.id, params?.id)
        loadOne(params?.id);
    }

    const detailConfig = {
        "tabs": [
            {
                "name": "contract_extension",
                "fieldsets": [
                    {
                        "name": "fees",
                        "fields": {
                            "reajustment_information": {
                                "type": "sectiontitle"
                            },
                            // "id": {
                            //     "type": "text",
                            //     "className": "col-4",
                            //     "icon": "faCalendarHeart",
                            //     "readonly":true
                            // },
                            "contractReajustmentType": {
                                "type": "select",
                                "icon": "faChartSimpleHorizontal",
                                "options": [
                                    {
                                        "value":"adjustment_with_settlement",
                                        "label":"adjustment_with_settlement",
                                    },
                                    {
                                        "value":"adjustment_without_settlement",
                                        "label":"adjustment_without_settlement",
                                    },
                                    {
                                        "value":"formal_extension",
                                        "label":"formal_extension",
                                    },
                                    {
                                        "value":"informal_extension",
                                        "label":"informal_extension",
                                    },
                                ],
                                "format":"translatable"
                            },
                            "contractReajustmentStart_at": {
                                "type": "date",
                                "className": "col-4",
                                "icon": "faCalendarHeart",
                                "format":"date",
                                "mindate": item?.data?.contractReajustmentStart_at?.split('T')[0] ?? new Date().toISOString().split('T')[0]
                            },
                            "contract_information": {
                                "type": "sectiontitle"
                            },
                            "start_at": {
                                "type": "date",
                                "className": "col-4",
                                "icon": "faCalendarHeart",
                                "format":"date",
                                "readonly":true
                            },
                            "contractDuration": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faHourglass",
                                "format":"month"
                            },
                            "contractDurationEnlapsed": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faHourglass",
                                "format":"month",
                                "readonly":true
                            },
                            "contractDurationRemaining": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faHourglass",
                                "format":"month",
                                "readonly":true
                            },
                            "end_at": {
                                "type": "date",
                                "className": "col-4",
                                "icon": "faCalendarXmark",
                                "format":"date",
                                "readonly":true
                            },

                            "mileage_information": {
                                "type": "sectiontitle"
                            },
                            "contractMileage": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faRoad",
                                "format":"distance"
                            },
                            "projectedMileage": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"distance",
                                "readonly":true
                            },
                            "projectedDeltaMileage": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"distance",
                                "readonly":true
                            },
                            "projectedCostOrReimbursement": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency",
                                "readonly":true
                            },

                            "fees": {
                                "type": "sectiontitle"
                            },
                            "totalLeaseFee": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency"
                            },
                            "contractReajustmentSettlement": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency",
                                "readonly":true
                            },
                            "lifetimeLeaseFee": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency",
                                "readonly":true
                            },
                            "totalCostOwnershipFee": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency",
                                "readonly":true
                            },
                            "recalculationsaving": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faMoneyBill",
                                "format":"currency",
                                "readonly":true
                            }
                        }
                    }
                    /*
                    Data Effetto		
                    Nuova Data Fine Prorogata		
                    Nuovi KM a fronte Proroga		
                    Data Fine = Nuova Data Fine Prorogata			
                    Canone
                    */
                ]
            }
        ],
        "validation": []
    }
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data });
        close();
    }

    return <>
        <DetailComponent
            values={{
                id: item?.data?._id,
                //
                contractReajustmentType:"adjustment_with_settlement",
                // durata
                start_at: item?.data?.start_at,
                contractDuration: item?.data?.contractDuration,
                end_at: item?.data?.end_at,
                contractDurationEnlapsed: item?.data?.contractDurationEnlapsed,
                contractDurationRemaining: item?.data?.contractDurationRemaining,
                // ricalcoli precedenti
                contractReajustmentStart_at: item?.data?.contractReajustmentStart_at ?? new Date().toISOString(),
                contractReajustmentEnd_at: item?.data?.contractReajustmentEnd_at,
                recalculationsaving: item?.data?.recalculationsaving,
                // km
                contractMileage: item?.data?.contractMileage,
                actualMonthlyMileage: item?.data?.car?.projectedMileage / item?.data?.contractDuration,
                projectedMileage: item?.data?.car?.projectedMileage,
                projectedDeltaMileage: item?.data?.car?.projectedDeltaMileage,
                projectedCostOrReimbursement: item?.data?.car?.projectedCostOrReimbursement,
                // canoni
                originalTotalLeaseFee: item?.data?.totalLeaseFee,
                totalLeaseFee: item?.data?.totalLeaseFee,
                contractReajustmentSettlement: item?.data?.contractReajustmentSettlement,
                lifetimeLeaseFee: item?.data?.lifetimeLeaseFee,
                totalCostOwnershipFee: item?.data?.totalCostOwnershipFee,
                actualTotalCostOwnershipFee: item?.data?.totalCostOwnershipFee,
                financialFee: item?.data?.financialFee,
                serviceFee: item?.data?.serviceFee,
                fuelFee: item?.data?.fuelFee,
                fuelIncluded: item?.data?.fuelIncluded,
                insuranceFee: item?.data?.insuranceFee,
                insuranceIncluded: item?.data?.insuranceIncluded,
                taxFee: item?.data?.taxFee,
                taxIncluded: item?.data?.taxIncluded,
                // brackets
                bracketType: item?.data?.bracketType,
                bracket1ExtraKmCost: item?.data?.bracket1ExtraKmCost,
                bracket1KmReimbursement: item?.data?.bracket1KmReimbursement,
                bracket1Percentage: item?.data?.bracket1Percentage,
                bracket2ExtraKmCost: item?.data?.bracket2ExtraKmCost,
                bracket2KmReimbursement: item?.data?.bracket2ExtraKmCost,
                bracket2Percentage: item?.data?.bracket2ExtraKmCost,
                bracket3ExtraKmCost: item?.data?.bracket3ExtraKmCost,
                bracket3KmReimbursement: item?.data?.bracket3KmReimbursement,
                bracket3Percentage: item?.data?.bracket3Percentage,

                // veicolo in arrivo
                newvehicle: item?.data?.car?.pool?.cerca_veicolo_in_ordine
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Adjust {{wizardObject}} {{identifier}}', { wizardObject: wizardObject, identifier: item?.data?.contractIdentifier })}
                />
            }
            {...params}
        />
    </>
}

export default ContractExtension;


