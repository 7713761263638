import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="KPI rilevante 1"
                icon="faClog"
                dataProviderName={'statistics/kpi1/count/cars/car'}
            />
            <KPI
                title="KPI rilevante 2"
                icon="faClog"
                dataProviderName={'statistics/kpi2/count/cars/car'}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


