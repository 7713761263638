import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { List as ListComponent, useDrawer, Confirm, Button, DrawerButton } from 'src/mtska-frontend-app-component'
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'cashflows/cashflowattrrulegroup';
const cashflowRecordDataProviderName = 'cashflows/cashflowrecord'
const drawerRouteBase = 'cashflowattributionrulegroups';
const moduleName = "Regole di attribuzione Cash Flow";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getSelected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name]) => name);
    };
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const { doAction: doActionCashflowRecord } = useDataProvider(cashflowRecordDataProviderName)

    const getFunctionalButtons = () => {
        const functionalButtons = []

        functionalButtons.push(
            <DrawerButton
                // className='button-new'
                className='iconic'
                disabled={userCapabilities && !userCapabilities[dataProviderName + '.create']}
                drawerRoute={drawerRouteBase + '/create'}
                icon='faPlus'
                params={{
                    detailStack: params.detailStack ?? {},
                    relatedElements: params.relatedElements ?? {}
                }}
                title={t('New')}
            />,
            selected.length > 0 && <Button
                className='iconic'
                disabled={(
                    (userCapabilities && !userCapabilities[dataProviderName + '.create']) ||
                    selected.length === 0 ||
                    selected.length > 10
                )}
                icon='faPlay'
                onClick={() => activate(
                    'cashflowrecords/preview',
                    {
                        massiveActionProps: {
                            doMassiveAction: ({ callback = () => { } }) => {
                                const params = {
                                    action: 'executeRule',
                                    data: selected,
                                    model: cashflowRecordDataProviderName
                                }
                                doActionCashflowRecord(params).then(() => {
                                    callback()
                                    apiRef.current.setRowSelectionModel([])
                                })
                            }
                        },
                        selected
                    }
                )}
                title={t('esegui_regole') + ` (${selected.length}/10)`}
                variant='outlined'
            />,
            selected.length > 0 && <Button
                // className='button-delete'
                className='iconic'
                disabled={(userCapabilities && !userCapabilities[dataProviderName + '.delete']) || selected.length === 0}
                icon='faTrash'
                onClick={() => handleDelete()}
                variant='outlined'
                title={t('Delete') + ` (${selected.length})`}
            />
        )
        return functionalButtons
    }

    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={async () => {
                    doAction && await doAction({ action: 'destroy', model: dataProviderName, data: getSelected() });
                    setConfirmDelete(null);
                    apiRef.current.setRowSelectionModel([]);
                    loadAll();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Items elimination")}>
                {t("Are you sure to remove the selected elements?")}
            </Confirm>
        );
    }

    const { doAction, loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderName)
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={getFunctionalButtons()}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                />
            )}
            {confirmDelete}
        </>
    );
};

export default List;


