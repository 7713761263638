import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import { Backdrop } from "@mui/material";

const dataProviderName = 'persons/person';
const wizardName = 'Car Configuration Access Management';
const wizardObject = 'Car';
const wizardAction = 'configuratorManagementSingle';

const ConfiguratorManagementSingle = (params) => {
    const { t } = useTranslation();
    const [segmentList, setSegmentList] = useState();
    const { userCapabilities, existingModules } = useContext(AppContext);

    const person = params?.person;
    const { items: segments, loadAll: loadAllSegments } = useDataProvider('infocar/getAvailableSegments/' + person?.employment?.legalEntity?._id);

    // DATA PROVIDER
    const {
        item,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadAllSegments().then((segments) => {
            if (segments.type == 'GETALL_FAILURE') {
                setSegmentList(segments);
            } else {
                let sl = segments?.items?.map((segment) => {
                    return {
                        "label": segment.Descrizione,
                        "value": segment.Descrizione,//segment.Codice
                    }
                });
                setSegmentList(sl);
            }
        });
    }, []);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "configuration",
                "fieldsets": [
                    {
                        "name": "configuration",
                        "fields": {
                            "configuration": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "fleetConfigurationActive": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "true",
                                        "value": "true"
                                    },
                                    {
                                        "label": "false",
                                        "value": "false"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faToggle"
                            },
                            "fleetConfigurationLinkValid_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarHeart"
                            },
                            "fleetConfigurationLinkExpire_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarXmark"
                            },
                            "segment": {
                                "type": "select",
                                "required": true,
                                "options": (segmentList?.type == 'GETALL_FAILURE') ? [] : segmentList,
                                "className": "col-12",
                                "icon": "faCircleX"
                            },
                            "fleetDriverCode": {
                                "type": "text",
                                "required": true,
                                "readonly": true,
                                "className": "col-4",
                                "icon": "faPerson"
                            },
                            "email": {
                                "type": "email",
                                "required": true,
                                "readonly": true,
                                "className": "col-4",
                                "icon": "faMessage"
                            },
                            "fleetConfigurationQuantityUnlimited": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "true",
                                        "value": "true"
                                    },
                                    {
                                        "label": "false",
                                        "value": "false"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faToggle"
                            },
                            "fleetConfigurationLink": {
                                "type": "text",
                                "required": true,
                                "readonly": true,
                                "className": "col-12",
                                "icon": "faGlobe"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return (

        !segmentList ? <Backdrop open={!segmentList} style={{ backgroundColor: "white" }}><CircularProgress /></Backdrop> :
            <DetailComponent
                values={{
                    "id": person?._id,
                    "email": person?.email,
                    "segment": person?.employment?.segment,
                    "fleetDriverCode": person?.firstname + person?.lastname + person?._id,
                    "fleetConfigurationLink": person?.employment?.fleetConfigurationLink,
                    "fleetConfigurationActive": person?.employment?.fleetConfigurationActive,
                    "fleetConfigurationQuantityUnlimited": person?.employment?.fleetConfigurationQuantityUnlimited,
                    "fleetConfigurationLinkValid_at": person?.employment?.fleetConfigurationLinkValid_at,
                    "fleetConfigurationLinkExpire_at": person?.employment?.fleetConfigurationLinkExpire_at
                }}
                modelName={modelName}
                detailConfig={detailConfig}
                save={handleSave}
                saving={saving}
                errors={errors}
                readonly={(segmentList.type == 'GETALL_FAILURE') ? true : false}
                errorMessage={(segmentList.type == 'GETALL_FAILURE') ? t('LegalEntity not allowed to configure') : errorMessage}
                title={t(wizardName)}
                canSave={(segmentList.type == 'GETALL_FAILURE') ? false : userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
                header={
                    <InfoHeader
                        view="wizard"
                        title={t(wizardName)}
                        subtitle={t('Manage the access to Car Configurator for {{firstname}} {{lastname}}', { wizardObject: wizardObject, firstname: person?.firstname, lastname: person?.lastname })}
                    />
                }
                {...params}
            />
    );
}

export default ConfiguratorManagementSingle;


