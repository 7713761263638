
import React, { useState, useEffect, useContext } from 'react';
import { Detail as DetailComponent, Confirm, useDrawer, SplitButton, useRelationshipInjector, useFormatter } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {useLoadOne} from "src/mtska-frontend-data-provider/hooks/useLoadOne.hooks";

const dataProviderName = 'incomestatements/contoeconomicoattrrulegroup';
const drawerRouteBase = 'contoeconomicoattributionrulegroups';

const Detail = (params) => {
    const { t } = useTranslation();
    const { close: closeDrawer } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const { injectToDetail } = useRelationshipInjector();
    const { formatCurrency } = useFormatter();

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success, reloadCounter } = useDataProvider(dataProviderName, params.multipart);

    const {modelName, detailConfig} = existingModules[dataProviderName]

    useLoadOne({loadOne, params, reloadCounter})

    const [editing, setEditing] = useState(false)

    const handleSave = (data) => {
        save({...data, filters: params.filters}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = (id) => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={() => {
                    destroy && destroy(id);
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Item elimination")}>
                {t("Are you sure to remove this element?")}
            </Confirm>
        );
    }

    injectToDetail(item?.data, 'car', params.filters);
    injectToDetail(item?.data, 'legalEntity', params.filters?.legalEntity);

    return (
        <>
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName+'.edit']}
                title={(item?.data?.name) ? item?.data?.name : t("New")}
                subtitle={(item?.data?.priority) && t('priority') + " " + item?.data?.priority}
                rightButtons={[
                    <SplitButton 
                        actions={[
                            {
                                disabled:userCapabilities && !userCapabilities[dataProviderName+'.delete'],
                                icon:"faTrash",
                                label:t('Delete'),
                                onClick:() => { handleDelete(item.data.id); }
                            }
                        ]}
                    />
                ]}
                {...params}
            />
            {confirmDelete}
        </>
    )
}

export default Detail;


