import React, { useContext } from 'react';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { Elletre, FleetManager } from ".";

const Default = () => {
    const { appConfiguration } = useContext(AppContext);

    const getView = () => {
        switch (appConfiguration.dashboard.view) {
            case 'elletre':
                return <Elletre />;
            case 'imprenditorevero':
            case 'fleetmanager':
                return <FleetManager />
        }
    }  

    return getView()

}

export default Default;


