import React from "react";
import { Box } from "@mui/joy";
import { useDrawer } from "../Drawer";

const drawerRouteBase = 'cars';

const VehicleAvatar = ({ size = "default", url = null, withname = false, fullname = null, detail = null, item }) => {

    const apiBasePath =  process.env.REACT_APP_API_ENDPOINT || '';
    const { activate } = useDrawer();

    const handleCarPictureClick = () => {
        activate(drawerRouteBase + '/carPictureAssociation', { car: item, carId: item?._id, multipart: true });
    }

    return (
        <Box className={"vehicle-avatar-wrapper vehicle-avatar-withname-" + withname}>
            <Box className={"vehicle-avatar vehicle-avatar-" + size} onClick={handleCarPictureClick}>
                {url && (
                    <img src={apiBasePath + url} />
                )}
                {!url && (

                    <img src={'/images/car.png'} className="default" />

                )}
            </Box>
            {withname === true && (
                <Box className={"vehicle-name"+(detail ? " hasdetail" : "")}>
                    <span className="fullname" title={fullname}>{fullname}</span>
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
        </Box>
    )
}

export default VehicleAvatar;