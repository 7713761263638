import { useEffect, useState } from 'react'

export const useDefault = ({ dataProviderName, filterByLegalEntity, loadAll, saveSetting, settings, setSettings }) => {

    const SETTING_NAME = `${dataProviderName}.model`

    const prePeriodValue = !Array.isArray(settings) && settings && settings[SETTING_NAME]?.periodValue
        ? settings[SETTING_NAME].periodValue
        : 'currentYear'

    const [state, setState] = useState({
        data: undefined,
        filters: {
            periodValue: prePeriodValue
        }
    })

    const setStateWrapped = (newState) => {
        setState((prev) => ({ ...prev, ...newState }))
    }

    useEffect(() => {
        if (filterByLegalEntity && filterByLegalEntity !== 'all') {
            loadData({ timeFrame: prePeriodValue })
        }
    }, [filterByLegalEntity])

    const loadData = ({ timeFrame }) => {
        loadAll({ timeFrame })
            .then((res) => {
                console.log(res)
                const { items } = res || {}
                setStateWrapped({ data: items })
            })
            .catch(() => {
                console.error('catch useDefault')
                setStateWrapped({ data: {} })
            })
    }

    const onFilterChange = ({ key, value }) => {
        const filters = { ...state.filters, [key]: value }
        setStateWrapped({ data: undefined, filters })
        const newSettings = { ...settings }
        newSettings[SETTING_NAME] = filters
        setSettings(newSettings)
        saveSetting({ canReloadCounter: false, name: SETTING_NAME, value: filters })
        loadData({ timeFrame: value })
    }

    return { onFilterChange, state }
}
