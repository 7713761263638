import { addDays, format } from 'date-fns'

export const getEndAt = (watchedFields) => {
    const { contractDuration, start_at } = watchedFields

    if (start_at && contractDuration) {
        let endDate =new Date(start_at);
        endDate.setMonth(endDate.getMonth() + parseInt(contractDuration));
        endDate.setDate(endDate.getDate() - 1);

        return endDate.toISOString();
    }
}

export const getTotalLeaseFee = (watchedFields) => {
    const {
        advanceFee = 0,
        deposit = 0,
        financialFee = 0,
        fuelFee = 0,
        fuelIncluded,
        insuranceFee = 0,
        insuranceIncluded,
        serviceFee = 0,
        taxFee = 0,
        taxIncluded
    } = watchedFields

    return (
        // Number(advanceFee) +
        // Number(deposit) +
        Number(financialFee) +
        (fuelIncluded === 'yes' ? Number(fuelFee) : 0) +
        (insuranceIncluded === 'yes' ? Number(insuranceFee) : 0) +
        Number(serviceFee) +
        (taxIncluded === 'yes' ? Number(taxFee) : 0)
    )
}

export const getEmissionsForFringeBenefitByProvider = (watchedFields, emissionsManual, setup) => {
    const { emissionsProvider } = watchedFields

    if (emissionsProvider) {
        if (emissionsProvider == 'manual') {
            return emissionsManual
        } else if (emissionsProvider == 'setup_emissions' && setup) {
            return setup.emissions;
        }
    }
}

export const getEmissionsForFringeBenefitByManual = (watchedFields, emissionsProvider, setup) => {
    const { emissionsManual } = watchedFields

    if (emissionsProvider) {
        if (emissionsProvider == 'manual') {
            return emissionsManual
        } else if (emissionsProvider == 'setup_emissions' && setup) {
            return setup.emissions;
        }
    }
}

export const getEmissionsForFringeBenefitBySetup = (watchedFields, emissionsProvider, emissionsManual) => {
    const { setup } = watchedFields

    if (emissionsProvider) {
        if (emissionsProvider == 'manual') {
            return emissionsManual
        } else if (emissionsProvider == 'setup_emissions' && setup) {
            return setup.emissions;
        }
    }
}

export const getHybridizationForFringeBenefitByProvider = (watchedFields, hybridizationManual, setup) => {
    const { hybridizationProvider } = watchedFields

    if (hybridizationProvider) {
        if (hybridizationProvider == 'manual') {
            return hybridizationManual;
        } else if (hybridizationProvider == 'setup_hybridization' && setup && ['bev', 'phev'].includes(setup.fuelType)) {
            return setup.fuelType;
        } else if (hybridizationProvider == 'setup_hybridization' && setup) {
            return 'combustion';
        }
    }
}
export const getHybridizationForFringeBenefitByManual = (watchedFields, hybridizationProvider, setup) => {
    const { hybridizationManual } = watchedFields

    if (hybridizationProvider) {
        if (hybridizationProvider == 'manual') {
            return hybridizationManual;
        } else if (hybridizationProvider == 'setup_hybridization' && setup && ['bev', 'phev'].includes(setup.fuelType)) {
            return setup.fuelType;
        } else if (hybridizationProvider == 'setup_hybridization' && setup) {
            return 'combustion';
        }
    }
}

export const getHybridizationForFringeBenefitBySetup = (watchedFields, hybridizationProvider, hybridizationManual) => {
    const { setup } = watchedFields

    if (hybridizationProvider) {
        if (hybridizationProvider == 'manual') {
            return hybridizationManual;
        } else if (hybridizationProvider == 'setup_hybridization' && setup && ['bev', 'phev'].includes(setup.fuelType)) {
            return setup.fuelType;
        } else if (hybridizationProvider == 'setup_hybridization' && setup) {
            return 'combustion';
        }
    }
}

export const getFringeBenefitFieldsByProvider = (watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency) => {
    const {
        fbProvider
    } = watchedFields

    return _getFringeBenefitFields(fbProvider, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency)
}

export const getFringeBenefitFieldsByCar = (watchedFields, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency) => {
    const {
        fbFringeBenefitCar
    } = watchedFields

    return _getFringeBenefitFields(fbProvider, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency)
}

export const getFringeBenefitFieldsByEmission = (watchedFields, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency) => {
    const {
        emissionsFinal
    } = watchedFields

    return _getFringeBenefitFields(fbProvider, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency)
}

const _getFringeBenefitFields = (fbProvider, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency) => {
    if (fbProvider) {
        if (fbProvider == 'manual') {
            return {
                fbModelSetupProvider: fbModelSetupProvider,
                fbMonthly: fbMonthly,
                fbAnnual: fbAnnual,
                fbCurrency: fbCurrency
            }
        } else if (fbProvider == 'aci' && emissionsFinal && fbFringeBenefitCar) {

            let fbAnnualFinal;
            switch (true) {
                case parseInt(emissionsFinal) < 60:
                    fbAnnualFinal = fbFringeBenefitCar?.fringeBenefitValue?.value25;
                    break;
                case parseInt(emissionsFinal) < 160:
                    fbAnnualFinal = fbFringeBenefitCar?.fringeBenefitValue?.value30;
                    break;
                case parseInt(emissionsFinal) < 190:
                    fbAnnualFinal = fbFringeBenefitCar?.fringeBenefitValue?.value50;
                    break;
                default:
                    fbAnnualFinal = fbFringeBenefitCar?.fringeBenefitValue?.value60;
                    break;
            }

            return {
                fbModelSetupProvider: fbFringeBenefitCar?.make+" "+fbFringeBenefitCar?.model+" "+fbFringeBenefitCar?.setup,
                fbMonthly: parseInt(fbAnnualFinal / 12 * 100) / 100,
                fbAnnual: fbAnnualFinal,
                fbCurrency: fbCurrency
            }
        }
    }
}