import React, {useMemo, useState} from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';

const Default = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState()

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Legal Entities"
                icon="faBuilding"
                dataProviderName={'statistics/total/count/companies/legalEntity'}
                filterbutton={{
                    visible:true,
                    label:t("All"),
                    callback:(newfilters) => setFilters(newfilters)
                }}
                filters={{
                    'items': [{
                        'field': 'type',
                        'operator': 'isNot',
                        'value': 'holding'
                    }]
                }}
            />
            <KPI
                title="Customers"
                icon="faCartShopping"
                dataProviderName={'statistics/customer/count/companies/legalEntity'}
                filterbutton={{
                    visible:true,
                    label:t("Filter"),
                    callback:(newfilters) => setFilters(newfilters)
                }}
                filters={{
                    'items': [{
                        'field': 'type',
                        'operator': 'equals',
                        'value': 'customer'
                    }]
                }}
            />
            <KPI
                title="Suppliers"
                icon="faTruckFast"
                dataProviderName={'statistics/supplier/count/companies/legalEntity'}
                filterbutton={{
                    visible:true,
                    label:t("Filter"),
                    callback:(newfilters) => setFilters(newfilters)
                }}
                filters={{
                    'items': [{
                        'field': 'type',
                        'operator': 'equals',
                        'value': 'supplier'
                    }]
                }}
            />
        </Box>
        <List filters={filters}/>
    </>, [filters])

}

export default Default;


