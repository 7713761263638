export const CASH_FLOW = {
    company: 'Azienda 1',
    key: 'cashFlow',
    title: 'CASH FLOW',
    cashFlow: {
        section1: {
            columns: [
                {
                    fieldKey: 'label',
                    label: 'Nome campo',
                    style: {
                        minWidth: 220
                    }
                },
                { fieldKey: 'januaryValue', label: 'Gennaio', formatType: 'currency' },
                { fieldKey: 'februaryValue', label: 'Febbraio', formatType: 'currency' },
                { fieldKey: 'marchValue', label: 'Marzo', formatType: 'currency' },
                { fieldKey: 'aprilValue', label: 'Aprile', formatType: 'currency' },
                { fieldKey: 'mayValue', label: 'Maggio', formatType: 'currency' },
                { fieldKey: 'juneValue', label: 'Giugno', formatType: 'currency' },
                { fieldKey: 'julyValue', label: 'Luglio', formatType: 'currency' },
                { fieldKey: 'augustValue', label: 'Agosto', formatType: 'currency' },
                { fieldKey: 'septemberValue', label: 'Settembre', formatType: 'currency' },
                { fieldKey: 'octoberValue', label: 'Ottobre', formatType: 'currency' },
                { fieldKey: 'novemberValue', label: 'Novembre', formatType: 'currency' },
                { fieldKey: 'decemberValue', label: 'Dicembre', formatType: 'currency' },
            ],
            rows: [
                {
                    fields: {
                        label: {
                            fullRow: true,
                            value: 'DEPOSITI BANCARI'
                        }
                    }
                },
                {
                    fields: {
                        label: {
                            value: 'Banca'
                        },
                        januaryValue: {
                            value: 121121.1
                        },
                        februaryValue: {
                            value: 121121.2
                        },
                        marchValue: {
                            value: 121121.3
                        },
                        aprilValue: {
                            value: 121121.4
                        },
                        mayValue: {
                            value: 121121.5
                        },
                        juneValue: {
                            value: 121121.6
                        },
                        julyValue: {
                            value: 121121.7
                        },
                        augustValue: {
                            value: 121121.8
                        },
                        septemberValue: {
                            value: 121121.9
                        },
                        octoberValue: {
                            value: 121121.10
                        },
                        novemberValue: {
                            value: 121121.11
                        },
                        decemberValue: {
                            value: 121121.12
                        }
                    }
                }
            ]
        }
    }
}

export const CE_SP = {
    company: 'Azienda 1',
    key: 'balanceSheet',
    title: 'STATO PATRIMONIALE RICLASSIFICATO',
    balanceSheet: {
        section1: {
            columns: [
                { fieldKey: 'accountCode', label: 'Cod. Conto' },
                {
                    fieldKey: 'label',
                    label: 'ATTIVO',
                    style: {
                        minWidth: 220
                    }
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'firstPeriod',
                    label: '2021',
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'secondPeriod',
                    label: '2022',
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'thirdPeriod',
                    label: '2023',
                },
            ],
            rows: [
                {
                    fields: {
                        label: {
                            style: {
                                fontWeight: 'bold'
                            },
                            value: 'Immobilizzazioni Immateriali'
                        }
                    }
                },
                {
                    fields: {
                        accountCode: {
                            
                        },
                        label: {
                            style: {
                                backgroundColor: 'yellow'
                            },
                            value: 'Label 1'
                        },
                        firstPeriod: {
                            unitValue: {
                                style: {
                                    backgroundColor: 'lightgreen'
                                },
                                value: 0
                            },
                            totalValue: {
                                value: 121121.12
                            },
                            percentageValue: {
                                value: 0 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        secondPeriod: {
                            unitValue: {
                                value: 122122.12
                            },
                            totalValue: {
                                value: 122122.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        thirdPeriod: {
                            unitValue: {
                                value: 123123.12
                            },
                            totalValue: {
                                value: 123123.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        fourthPeriod: {
                            unitValue: {
                                value: 123123.12
                            },
                            totalValue: {
                                value: 123123.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        }
                    },
                    style: {
                        backgroundColor: 'red'
                    }
                }
            ]
        },
        section2: {
            columns: [
                { fieldKey: 'accountCode', label: '' },
                {
                    fieldKey: 'label',
                    label: 'PASSIVO',
                    style: {
                        minWidth: 220
                    }
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'firstPeriod',
                    label: '1° Trimestre',
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'secondPeriod',
                    label: '2° Trimestre',
                },
                {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'thirdPeriod',
                    label: '3° Trimestre',
                },
                /* {
                    children: [
                        { fieldKey: 'unitValue', label: 'Unit.', title: 'Valore Unitario', formatType: 'currency' },
                        { fieldKey: 'totalValue', label: 'Tot.', title: 'Valore Totale', formatType: 'currency' },
                        { fieldKey: 'percentageValue', label: 'Perc.', title: 'Valore Percentuale', formatType: 'percentage' },
                        { fieldKey: 'type', label: 'Tipo', title: 'Tipo' }
                    ],
                    fieldKey: 'fourthPeriod',
                    label: '4° Trimestre',
                } */
            ],
            rows: [
                {
                    fields: {
                        label: {
                            style: {
                                fontWeight: 'bold'
                            },
                            value: 'Immobilizzazioni Immateriali'
                        }
                    }
                },
                {
                    fields: {
                        accountCode: {
                            
                        },
                        label: {
                            value: 'Label 2'
                        },
                        firstPeriod: {
                            unitValue: {
                                value: 121121.12
                            },
                            totalValue: {
                                value: 121121.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        secondPeriod: {
                            unitValue: {
                                value: 122122.12
                            },
                            totalValue: {
                                value: 122122.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        thirdPeriod: {
                            unitValue: {
                                value: 123123.12
                            },
                            totalValue: {
                                value: 123123.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        },
                        /* fourthPeriod: {
                            unitValue: {
                                value: 124124.12
                            },
                            totalValue: {
                                value: 124124.12
                            },
                            percentageValue: {
                                value: 0.08 // ==> 8%
                            },
                            type: {
                                value: 'tipo 1'
                            }
                        } */
                    }
                }
            ]
        }
    }
}

export const OPTIONS = [
    { label: 'Current year', value: 'currentYear' },
    { label: 'Annual', value: 'annual' },
    { label: 'Current year quarter', value: 'currentYearQuarter' },
    { label: 'Previous year quarter', value: 'previousYearQuarter' },
    { label: 'Monthly', value: 'monthly' },
]