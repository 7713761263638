export const styles = {
    cellContainerS: {
        display: 'flex',
    },
    cellS: {
        alignContent: 'center',
        height: 40,
        overflow: 'hidden',
        padding: '0 10px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    containerButtonsS: {
        textAlign: 'right'
    },
    containerS: {
        backgroundColor: '#fff',
        fontSize: 14
    },
    footerS: {
        borderBottom: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        borderRadius: '0 0 15px 15px',
        borderRight: '1px solid #e0e0e0',
        fontWeight: 'bold',
        padding: '20px 24px'
    },
    headerCellS: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    titleS: {
        alignItems: 'center',
        borderLeft: '1px solid #e0e0e0',
        borderRadius: '15px 15px 0 0',
        borderRight: '1px solid #e0e0e0',
        borderTop: '1px solid #e0e0e0',
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        fontWeight: 'bold',
        padding: '20px 24px'
    }
}
