export const styles = {
    sectionS: {
        minWidth: 'fit-content',
        width: '100%'
    },
    sectionsS: {
        border: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        width: '100%'
    }
}
