import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import PersonAvatar from '../Snippet/PersonAvatar';
import { Icon } from '../../assets';
import { useDrawer } from '../Drawer'
import { Tag } from '../Snippet';


const RelationshipEmployment = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const { activate } = useDrawer();


    // // hack veloce per modellare v se lo ricevo dalla scheda person. Noi vogliamo un employment idratato con person ma riceviamo person
    // /**
    //  * @todo fix this shit
    //  */
    // if (typeof v.person_id == 'undefined') {
    //     const oldv = v;
    //     v = {
    //         ...oldv.employment,
    //         'person':oldv.pools?.[0].employment.person
    //     };
    // }

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                {
                    v?.person ? 
                    <PersonAvatar firstname={v?.person?.firstname || v?.name} lastname={v?.person?.lastname} url={v?.person?.picture?.url} withname={true} item={v?.person}/>
                    :
                    <Box className={"status"}>
                        <Tag color={"danger"} title={t("Missing person in employment")}><Icon icon="faWarning" />{t('Missing')}</Tag>
                    </Box>
                }
                

                <Box className="coupled">
                    <Box className="label">{t('Role')}</Box>
                    <Box className="value">
                        <span>{v?.role}</span>
                    </Box>
                </Box>

                <Box className={"status"}>
                    <Tag color={v?.status != "terminated" ? "success" : "danger"} title={t("Missing person in employment")}><Icon icon="faWarning" />{t(v?.status)}</Tag>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipEmployment



