import React from 'react';
import { Box } from '@mui/joy';

const CellUserName = ({ value, type = 'simple' }) => {

    return (
           (value && (
                   <Box className={"list-element-cell cell-person"}>
                       <span className="name" title={value?.name}>{(value.name).trim()}</span>
                   </Box>
               ) 
           )
       );
}

export default CellUserName