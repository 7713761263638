import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/joy";
import { useNavigate } from 'react-router-dom';
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { AppContext } from "src/mtska-frontend-app-scaffolding";
import { useTranslation } from "react-i18next";

const Auth0LoginButton = ({ className, label = "Login" }) => {

    const { error, getAccessTokenWithPopup } = useAuth0();

    const { t } = useTranslation(); 
    const navigate = useNavigate();

    const { loadOne: loadConfig } = useDataProvider('configs/config')
    const { item, loadOne } = useDataProvider('auth/token')

    const { setUserCapabilities, userCapabilities, appConfiguration, loginError, setLoginError, setLoginOptions } = useContext(AppContext)

    useEffect(() => {
        if (typeof item?.token === 'undefined') return;
        sessionStorage.setItem('token', item?.token)
    }, [item, navigate])

    const login = () => {
        (async () => {
            try {
                const token = await getAccessTokenWithPopup({
                    authorizationParams: {
                        audience: appConfiguration.auth0.AUDIENCE
                    }
                })
                if (token !== null) {
                    sessionStorage.setItem('token', token)

                    loadOne(token).then((response) => { //+"?role=_reset"
                        if (response?.item?.data?.errorMessage) {
                            setLoginError(response?.item?.data?.errorMessage);
                        } else if (response?.item?.data?.options) {
                            setLoginOptions(response?.item?.data?.options);
                        } else {
                            setLoginError(null);
                            setLoginOptions(null);

                            loadConfig(token).then((response) => {
                                if (response && response.item && response.item.data) {
                                    setUserCapabilities(response.item.data.capabilities);
                                }
                            });
                        }
                    });
                }
            } catch (e) { }
        })()
    }

    useEffect(() => {
        if (Object.keys(userCapabilities).lenght > 0) {
            debugger
            navigate(
                process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URI ||
                appConfiguration.auth0.DEFAULT_AUTHENTICATED_URI,
                { replace: true }
            );
        }
    }, [userCapabilities])


    return <>
        {error && error.messages}
        <Button
            size="regular"
            variant="solid"
            className={className}
            onClick={login}
        >
            {t(label)}
        </Button>
    </>
}

export default Auth0LoginButton;