import React, { useState } from 'react'
import { Box } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { Tag } from 'src/mtska-frontend-app-component';

const DriverHelpEn = () => {
    const { t } = useTranslation();
    const [selectedRenter, setSelectedRenter] = useState();
    const getSubdomain = () => {
        const host = window.location.hostname.split('.');
        return host.length > 2 ? host[0] : '';
    };
    const customerSupportEmail = "driver" + getSubdomain() + "@myfleetsolution.it"


    return (
        <Box className={"dashboard dashboard-driver-help" + (selectedRenter ? ' filtered ' + selectedRenter : '')}>
            <Tag>{t('Help')}</Tag>
            <h1 style={{ color: 'var(--app-main)' }}>{t('Practical Guide to Mobility Services')}</h1>
            <p class="smielato">
                Dear Driver,<br /><br />
                We understand how important it is for you to manage your mobility needs in a simple and direct way.
                For this reason, we have prepared a practical guide indicating who to contact for each need.<br />
                We recommend printing a copy and keeping it in your car.<br /><br />
                Thank you
            </p>
            <h4>Select your rental provider to filter the information:</h4>
            <ul className="renterSelecter">
                <li><Tag color={selectedRenter == 'arval' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('arval')}>Arval</Tag></li>
                <li><Tag color={selectedRenter == 'leaseplan' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('leaseplan')}>Leaseplan</Tag></li>
                <li><Tag color={selectedRenter == 'vwleasing' ? "success" : ""} size="medium" onClick={() => setSelectedRenter('vwleasing')}>Volkswagen Leasing</Tag></li>
            </ul>

            <div class="assistance-container">
                <div class="assistance-item">
                    <h2>Urgent Needs</h2>
                </div>

                <div class="assistance-item">
                    <h3>ROADSIDE ASSISTANCE - ITALY</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> 800 16 55 00 - For Mid Term, contact 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> 800 146 146 - For Flexi Plan, contact 800.146.146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Contact 800.44.99.88
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>ROADSIDE ASSISTANCE - ABROAD</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> +39 055 5329600 - For Mid Term, contact 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> 02 66165560 - For Flexi Plan, contact 800.146.146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Contact +39 02.66.16.53.02
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>BOOK A REPLACEMENT VEHICLE</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> At the Service Point of the intervention or independently at 800 16 55 00 - For Mid Term, contact 800 894506
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> At the Service Point of the intervention or independently at 800 146 146
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Request directly at the Service Point performing the vehicle repair or independently at 800.44.99.88
                        </div>
                    </div>
                </div>
            </div>

            <div class="assistance-container">
                <div class="assistance-item">
                    <h2>Accident, Theft, Vandalism, Kasco Damage, Insurance Certificate</h2>
                </div>

                <div class="assistance-item">
                    <h3>REPORT AN ACCIDENT</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> Write to <a href="mailto:sinistri@arval.it">sinistri@arval.it</a> with cc to <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Fill out the online form on the <a href="https://www.ayvens.com/it-it/assistenza-clienti/denuncia-sinistri/">Rental Provider's portal</a> and email <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Fill out the online form on the <a href="https://www.vwfs.it/denuncia-sinistri.html">Rental Provider's portal</a> with cc to <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a>
                        </div>
                    </div>
                </div>

                <div class="assistance-item">
                    <h3>INSURANCE CERTIFICATE</h3>
                    <div class="assistance-details">
                        <div class="brand arval">
                            <strong>Arval:</strong> Write to <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> or call <a href="tel:800165500">800.16.55.00</a>
                        </div>
                        <div class="brand leaseplan">
                            <strong>Leaseplan:</strong> Write to <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> or call <a href="tel:800146146">800.146.146</a>
                        </div>
                        <div class="brand vwleasing">
                            <strong>Volkswagen Leasing:</strong> Write to <a href={"mailto:" + customerSupportEmail}>{customerSupportEmail}</a> or call <a href="tel:0233027582">02 33027582</a>
                        </div>
                    </div>
                </div>
            </div>
        </Box>

    )

}

export default DriverHelpEn;


