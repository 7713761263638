import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/joy'
import { Button, InputTextElement } from 'src/mtska-frontend-app-component'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export const SplitRecordRow = ({ canSave, config, editing, inputRef, onAdd, onChange: callback, onDelete, options, rowFields = {}, saving, setIsInputFocused }) => {

    const { idFE } = rowFields

    const { t } = useTranslation()

    const buttonProps = {
        fullWidth: true,
        disabled: !canSave || saving,
        label: editing ? t('Delete') : t('add'),
        onBlur: () => setIsInputFocused(false),
        onClick: editing ? () => onDelete({ idFE }) : onAdd,
        onFocus: () => setIsInputFocused(true)
    }

    const onChange = ({ e, field, type }) => {
        const { value: stringValue } = e.target
        const value = type === 'number' ? Number(stringValue) : stringValue
        callback({ field, idFE, value })
    }

    return (
        <Box display='grid' gap='10px' gridTemplateColumns='calc(33% - 40px) calc(33% - 40px) calc(33% - 40px) calc(1% + 90px)'>
            {Object.keys(config).map((field, index) => {
                const { label, optionId, optionLabel, type } = config[field]
                const buttonKey = editing ? `deleteButton${index}` : `addButton${index}`
                return (
                    <>
                        {field !== 'idFE' && (
                            type === 'select'
                                ? (
                                    <Box>
                                        <InputLabel sx={{ color: 'var(--neutral-400)', fontSize: '14px', marginBottom: '6px' }}>
                                            {label}
                                        </InputLabel>
                                        <Select
                                            disabled={!editing || !options}
                                            fullWidth
                                            onChange={(e) => onChange({ e, field, type })}
                                            size='small'
                                            sx={{ padding: '2px 0px' }}
                                            value={rowFields[field]}
                                        >
                                            {(options || []).map(option => <MenuItem key={option[optionId]} value={option[optionId]}>{option[optionLabel]}</MenuItem>)}
                                        </Select>
                                    </Box>
                                )
                                : (
                                    <InputTextElement
                                        editing={editing}
                                        key={field + index}
                                        label={label}
                                        onChange={(e) => onChange({ e, field, type })}
                                        ref={inputRef}
                                        type={type}
                                        value={rowFields[field]}
                                    />
                                )
                        )}
                        {index + 1 === Object.keys(config).length && (
                            <Box alignContent="end" key={buttonKey} paddingBottom='5px' textAlign="center" >
                                <Button {...buttonProps} />
                            </Box>
                        )}
                    </>
                )
            })}
        </Box>
    )
}
