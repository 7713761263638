import React from 'react'
import { FullRow } from './fullRow/FullRow'
import { Row } from './row/Row'

export const Rows = ({ columns, rows, sectionIndex }) => {
    return (
        rows.map((row = {}, rowIndex) => {
            const { fields = {} } = row
            const firstField = Object.values(fields)[0]
            const { fullRow, value } = firstField || {}
            return (
                fullRow
                    ? (
                        <FullRow
                            key={`section${sectionIndex}row${rowIndex}`}
                            value={value}
                        />
                    )
                    : (
                        <Row
                            columns={columns}
                            key={`section${sectionIndex}row${rowIndex}`}
                            row={row}
                            rowIndex={rowIndex}
                            sectionIndex={sectionIndex}
                        />
                    )
            )
        })
    )
}
