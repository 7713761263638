import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { ExpirationTermometer, Termometer, Tag, LegalEntityAvatar } from '../Snippet';
import { useFormatter } from '../../utils';
import { Icon } from 'src/mtska-frontend-app-component/assets';

const RelationshipService = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatCurrency, formatDate } = useFormatter();
    const tagcolors = {
        active: "success",
        inactive: "danger"
    }

    const consumptionPercentage = (v?.monthlyLimit) ? v?.monthlyCosts / parseFloat(v?.monthlyLimit) : 0;

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content " + v?.type}>
                <Box className={"coupled"}>
                    <span className={"label"} >{t(v?.name)}</span>
                    <span className={"value"}>{t(v?.identifier)}</span>
                </Box>
                <LegalEntityAvatar name={v?.issuer?.name} url={v?.issuer?.logo?.url} withname={true} detail={t(v?.type) + " | " + t(v?.assignedModel)} error={v?.issuer?.name ? false : t('Missing issuer')}/>
                <Box className={"status"}>
                    <Tag
                        color={(v?.status && tagcolors[v.status.toLowerCase()])}
                        size="small"
                        label={t(v?.status && v?.status.toLowerCase())}
                    />
                </Box>

                {
                    v?.type == 'fuelcard' && (
                        <Box className={"coupled"}>
                            <span className={"label"}>{t('pin')}</span>
                            <span className={"value"}>{v?.pin ? t(v?.pin) : '-'}</span>
                        </Box>
                    )
                }
                {
                    v?.type == 'tollsystem' && (
                        <Box className={"coupled"}>
                            <span className={"value"}><Icon icon="faCalendarHeart" />{formatDate(v?.assignment_start_at)}</span>
                            <span className={"value"}><Icon icon="faCalendarXmark" />{formatDate(v?.assignment_start_at)}</span>
                        </Box>
                    )
                }
                {
                    // !['tyre', 'courtesycar'].includes(v?.type) && (
                    //     <Box className={"analysis termometer norightborder"}>
                    //         <Termometer startvalue={formatCurrency(0)} endvalue={(v?.monthlyLimit) ? formatCurrency(v?.monthlyLimit) : t('Limit missing')} percentage={consumptionPercentage} color={(v?.monthlyLimit && v?.monthlyCosts) ? ((consumptionPercentage < 0.7) ? "secondary" : "warning") : "danger"} hint={v?.monthlyCosts ? formatCurrency(v?.monthlyCosts) : t('Missing monthly costs')} hintposition={"top"} />
                    //         {/* <ExpirationTermometer installationDate={v?.installationDate} expirationDate={v?.expirationDate} orderDate={v?.orderDate} expectedInstallationDate={v?.expectedInstallationDate} /> */}
                    //     </Box>
                    // )
                }
                {
                    v?.type == 'courtesycar' && (
                        <Box className={"coupled norightborder"}>
                            {
                                v?.courtesycarDescription.length > 0 ? (
                                    <>
                                        <span className={"value"}><Icon icon="faInputText" />{t(v?.courtesycarDescription)}</span>
                                    </>
                                ) : (
                                    <>
                                        <span className={"label"}><Icon icon="faCar" />{t(v?.courtesycarSegment)}</span>
                                        <span className={"value"}><Icon icon="faHourglass" />{t(v?.courtesycarAvailability)}</span>
                                    </>
                                )
                            }
                        </Box>
                    )
                }
                {
                    v?.type == 'tyre' && (
                        <Box className={"coupled norightborder"}>
                            <span className={"label"}><Icon icon="faChartNetwork" />{t(v?.tyreCategory)}</span>
                            <span className={"value"}><Icon icon="faInputNumeric" />{t(v?.tyreNumber)}</span>
                        </Box>
                    )
                }
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipService



