import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const Elletre = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [filters, setFilters] = useState(() => {
        const params = new URLSearchParams(location.search);
        const newFilters = { items: [] };
    
        params.forEach((value, field) => {
            newFilters.items.push({
                field: field,
                operator: "contains",
                value: value
            });
        });
    
        return { filterModel: newFilters };
    });    

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">

            <KPI
                title="Reports"
                icon="faChartSimple"
                dataProviderName={'statistics/total/countmine/bis/bi'}
            />
            <KPI
                title="Executive"
                icon="faGlobe"
                dataProviderName={'statistics/executive/countmine/bis/bi'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "scope",
                        operator: "contains",
                        value: "executive"
                    }]
                }}
            />
            <KPI
                title="Area"
                icon="faGlobeStand"
                dataProviderName={'statistics/area/countmine/bis/bi'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "scope",
                        operator: "contains",
                        value: "area"
                    }]
                }}
            />
            <KPI
                title="Shop"
                icon="faShop"
                dataProviderName={'statistics/area/countmine/bis/bi'}
                filterbutton={{
                    visible: true,
                    label: t("View"),
                    callback: (newfilters) => setFilters({ filterModel: { ...newfilters } })
                }}
                filters={{
                    items: [{
                        field: "scope",
                        operator: "contains",
                        value: "shop"
                    }]
                }}
            />
        </Box>
        
        <List filters={filters} />
    </>, [filters])

}

export default Elletre;


