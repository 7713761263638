import { useEffect, useRef, useState } from 'react'

export const useInputFocus = () => {

    const inputRef = useRef(null)
    const [isInputFocused, setIsInputFocused] = useState(false)

    useEffect(() => {
        if (isInputFocused && inputRef.current) {
            inputRef.current.focus()
        }
    })

    return { inputRef, isInputFocused, setIsInputFocused }
}