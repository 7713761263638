import React, { useState, useRef, useEffect} from 'react';
import Button from './Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Icon } from '../../assets';
//Old
export default function SplitButton({ actions }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        const firstEnabledIndex = actions.findIndex(action => action.disabled === false);
        if (firstEnabledIndex !== -1) {
            setSelectedIndex(firstEnabledIndex);
        }
    }, [actions]);    

    const handleClick = () => {
        actions[selectedIndex].onClick();
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        if (actions[index].onClick) actions[index].onClick();
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        selectedIndex != null &&
        <React.Fragment>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                className="buttongroup"
            >
                <Button onClick={handleClick} icon={actions[selectedIndex].icon}>{actions[selectedIndex].label}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className="buttongroup_action_positioner"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem className="buttongroup_action_wrapper">
                                    {actions.map((action, index) => (
                                        action.disabled === false &&
                                        <MenuItem
                                            key={action.label}
                                            selected={index === selectedIndex}
                                            //onClick={action.onClick}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            className="buttongroup_action"
                                            disabled={action.disabled}
                                        >
                                            <Icon icon={action.icon} />
                                            {action.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}