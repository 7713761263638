import React from 'react'
import { styles } from './sections.styles'
import { Columns } from './columns/Columns'
import { Rows } from './rows/Rows'

export const Sections = ({ data }) => {

    const { key } = data || {}

    const { sectionS, sectionsS } = styles

    return (
        <div style={{ ...sectionsS }}>
            {Object.values(data[key]).map((section, sectionIndex) => {
                const { columns = [], rows = [] } = section || {}
                return (
                    <div key={`section${sectionIndex}`} style={{ ...sectionS }}>
                        <Columns columns={columns} sectionIndex={sectionIndex} />
                        <Rows columns={columns} rows={rows} sectionIndex={sectionIndex} />
                    </div>
                )
            })}
        </div>
    )
}
