import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Tag, LegalEntityAvatar } from '../Snippet';

const RelationshipSaltEdgeAccount = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();

    const tagcolors = {
        active: "success",
        inactive: "warning",
        disabled: "danger"
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="name">
                    {v?.name}
                </Box>
                <Box className="provider">
                    <LegalEntityAvatar name={v?.saltEdgeConnection?.saltEdgeProvider?.name} url={v?.saltEdgeConnection?.saltEdgeProvider?.logoUrl} withname={true}/>
                </Box>
                <Box className={"status"}>
                    <Tag color={(tagcolors[v?.saltEdgeConnection?.saltEdgeProvider?.status ? v?.saltEdgeConnection?.saltEdgeProvider?.status : 'missing_timeframe'])} size="small" label={t(v?.saltEdgeConnection?.saltEdgeProvider?.status ? v?.saltEdgeConnection?.saltEdgeProvider?.status : 'missing_timeframe')} />
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipSaltEdgeAccount



