import React from 'react'
import { useFormatter } from 'src/mtska-frontend-app-component'
import { getStyles } from './cell.styles'
import { styles } from '../../../../excelPreview.styles'
import { SubCell } from './subCell/SubCell'

export const Cell = ({ column, index, row = {} }) => {

    const { formatCurrency, formatPercentage } = useFormatter()

    const getFormattedValue = ({ formatType, value }) => {
        const isNumber = (value || value === 0) && !isNaN(Number(value))
        const formattedValue = isNumber
            ? formatType === 'currency'
                ? formatCurrency(value)
                : formatType === 'percentage'
                    ? formatPercentage(value)
                    : value
            : value
        return formattedValue
    }

    const { children, fieldKey: columnFieldKey, formatType: columnFormatType, style: customColumnS = {} } = column || {}

    const childrenLength = (children && children.length) || 1

    const { fields = {}, style: customRowS = {} } = row
    const cell = fields[columnFieldKey] || {}
    const { style: customCellS = {}, value } = cell

    const isNumber = (value || value === 0) && !isNaN(Number(value))
    const formattedValue = getFormattedValue({ formatType: columnFormatType, value })

    const { containerRowCellS } = getStyles({ childrenLength, index })
    const { cellContainerS, cellS } = styles

    return (
        <div style={{ ...containerRowCellS, ...customColumnS, ...customRowS }}>
            {children
                ? (
                    <div style={{ ...cellContainerS }}>
                        {children.map((child = {}) => (
                            <SubCell
                                cell={cell}
                                child={child}
                                getFormattedValue={getFormattedValue}
                                key={`${columnFieldKey}${child.fieldKey}${index}`}
                            />
                        ))}
                    </div>
                )
                : (
                    <div style={{ ...cellS, ...customCellS, textAlign: isNumber ? 'right' : 'left' }} title={formattedValue}>
                        {formattedValue}
                    </div>
                )
            }
        </div>
    )
}
