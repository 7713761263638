import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'companies/businessunit';
const model = 'companies/businessUnit';
const modelExposedName = 'Business Unit';
const wizardAction = 'create';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const {
        doAction,
        item,
        save,
        loadOne,
        loadAll,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
            "tabs": [
                {
                    "name": "create_new_business_unit",
                    "fieldsets": [
                        {
                            "name": "businessunit_information",
                            "fields": {
                                "name": {
                                    "type": "text",
                                    "required": true,
                                    "className": "col-6",
                                    "icon": "faInputText"
                                },
                                "status": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "active",
                                            "value": "active"
                                        },
                                        {
                                            "label": "inactive",
                                            "value": "inactive"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-6",
                                    "icon": "faChartPyramid"
                                }, 
                                "disabled_at": {
                                    "type": "date",
                                    "className": "col-12",
                                    "icon": "faCalendarXmark",
                                    "visibilitycondition": [
                                        [
                                            "status",
                                            "=",
                                            "inactive"
                                        ]
                                    ]
                                },
                                "legalEntity": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "filter": {
                                            "items": [
                                                {
                                                    "field": "type",
                                                    "operator": "equals",
                                                    "value": "customer"
                                                }
                                            ]
                                        }
                                    },
                                    "view": "legalEntity",
                                    "label": "customer"
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        }

    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    
    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


