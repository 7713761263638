import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, SectionTitleEmbedded, Tag } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';

const EmploymentsOfPerson = (params) => {

    const { t } = useTranslation();
    const { existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {
        item,
        save,
        loadOne,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "company_data",
                "fieldsets": [
                    {
                        "name": "company_data",
                        "fields": {
                            "employments": {
                                "type": "relationship",
                                "relation": {
                                    "drawerRoute": "employments",
                                    "inverseRelation": "person",
                                    "inverseRelationType": "relationship",
                                    "limit": 1000
                                },
                                "view": "employments"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    useEffect(() => {
        loadOne(params.id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    return <>
        {
            item &&
            <>
                <SectionTitleEmbedded 
                    label={ item?.data?.updated_at ? 
                        t('Assign a role to') + ' ' + item?.data?.firstname + ' ' + item?.data?.lastname +' @ ' +item?.data?.legalEntity?.name
                        :
                        t('Assign a role to the new person') 
                    } 
                    className={'col-12'}
                />
                <DetailComponent
                    values={{ ...item?.data }}
                    modelName={modelName}
                    detailConfig={detailConfig}
                    onEditing={setEditing}
                    save={handleSave}
                    saving={saving}
                    errors={errors}
                    errorMessage={errorMessage}
                    isEmbedded={true}
                    {...params}
                />
            </>
        }
    </>
}

export default EmploymentsOfPerson;