import React from 'react'
import { styles } from '../../excelPreview.styles'
import { Column } from './column/Column'

export const Columns = ({ columns, sectionIndex }) => {

    const { cellContainerS } = styles

    return (
        <div style={{ ...cellContainerS }}>
            {columns.map((column, columnIndex) => (
                <Column
                    column={column}
                    index={columnIndex}
                    key={`section${sectionIndex}column${columnIndex}`}
                />
            ))}
        </div>
    )
}
