import React, { useState,  useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {  List as ListComponent, useDrawer, } from 'src/mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';

import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'notifications/model';
const drawerRouteBase = 'notificationmodels';
const moduleName = "Notification Models";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();

    const { doAction, loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }



    return (
        <>
            {/* {userCapabilities && userCapabilities[dataProviderName + '.list'] ? ( */}
            {true ? (
                <ListComponent
                    {...params}
                    // onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onRowDoubleClick={ handleRowDoubleclick }
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection={false}
                    rightButtons={[
                    ]}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    doAction={doAction}
                />
            ) : (
                <>No user capabilities {dataProviderName + '.list'}</>
            )}
        </>
    );
};

export default List;


