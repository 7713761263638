import React, { useContext, useEffect } from 'react'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { useGridApiRef } from '@mui/x-data-grid'
import { AppContext } from 'src/mtska-frontend-app-scaffolding'
import { List as ListComponent } from 'src/mtska-frontend-app-component'

const dataProviderName = 'incomestatements/contoeconomicorecord'
const currentDataProviderName = `${dataProviderName}/previewExecuteRule`
const moduleName = 'Preview Conto Economico'

const Previewer = (params) => {

    const {massiveActionProps, selected = []} = params

    const { doMassiveAction } = massiveActionProps || {}

    const { userCapabilities } = useContext(AppContext)

    const { loadAll, items, total, loading } = useDataProvider(currentDataProviderName)

    const apiRef = useGridApiRef()

    const ids = `${selected.join(',')}`

    useEffect(() => {
        loadAll(undefined, undefined, undefined, undefined, {ids})
    }, [])

    return (
        userCapabilities && userCapabilities[dataProviderName + '.create'] && (
            <ListComponent
                {...params}
                apiRef={apiRef}
                dataProviderName={dataProviderName}
                doMassiveAction={doMassiveAction}
                headerFilters={false}
                hideTableFooter
                hideTableToolbar
                mainButtonLabel='conferma'
                items={items}
                loading={loading}
                total={total}
                title={moduleName}
            />
        )
    )
}

export default Previewer
