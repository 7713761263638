import React from "react"
import CellDrawer from "./CellDrawer";
import CellEmail from "./CellEmail";
import CellSimple from "./CellSimple";
import CellPeople from "./CellPeople";
import CellPerson from "./CellPerson";
import CellLegalEntities from "./CellLegalEntities";
import CellLegalEntity from "./CellLegalEntity";
import CellBis from "./CellBis";
import CellCount from "./CellCount";
import CellDate from "./CellDate";
import CellDatetime from "./CellDatetime";
import CellFilesize from "./CellFilesize";
import CellCurrency from "./CellCurrency";
import CellCarSetup from "./CellCarSetup";
import CellBoolean from "./CellBoolean";
import CellStatus from "./CellStatus";
import CellCars from "./CellCars";
import CellDrivers from "./CellDrivers";
import CellTranslatable from "./CellTranslatable";
import CellEmployees from "./CellEmployees";
import CellEmployments from "./CellEmployments";
import CellTraceKmsLast from "./CellTraceKmsLast";
import CellContract from "./CellContract";
import CellGenericList from "./CellGenericList";
import CellUrl from "./CellUrl";
import CellUser from "./CellUser"
import CellPercentage from "./CellPercentage";
import CellLegalEntityFlat from "./CellLegalEntityFlat";
import CellGeneric from "./CellGeneric";
import { CellRelationship } from "./CellRelationship"
import { CellSelect } from "./CellSelect";
import { pick } from "dot-object";
import CellUserName from "./CellUserName";

const Cell = ({
    action,
    columnRendered,
    configFilters,
    detailStack,
    drawerRoute,
    field,
    fieldDataProviderName,
    fieldKey,
    optionKey,
    optionLabel,
    optionsDataProviderName,
    row,
    processRowUpdate = () => { },
    type,
    typeEditable,
    value,
    valueOptions = []
}) => {

    if (typeEditable === 'relationship') return (
        <CellRelationship
            action={action}
            configFilters={configFilters}
            field={field}
            fieldKey={fieldKey}
            optionKey={optionKey}
            optionLabel={optionLabel}
            optionsDataProviderName={optionsDataProviderName}
            processRowUpdate={processRowUpdate}
            row={row}
            type={type}
            value={value}
        />
    )

    if (typeEditable === 'select') return (
        <CellSelect
            action={action}
            field={field}
            optionKey={optionKey}
            optionLabel={optionLabel}
            processRowUpdate={processRowUpdate}
            row={row}
            type={type}
            value={value}
            valueOptions={valueOptions}
        />
    )


    if (type === 'detail') return (
        <CellDrawer
            drawerRoute={drawerRoute}
            value={value}
            filters={row}
            detailStack={detailStack}
        />
    )

    if (type === 'relation-list') return (
        <CellDrawer
            dataProviderName={fieldDataProviderName}
            value={value}
            filters={row}
            detailStack={detailStack}
        />
    )

    if (type === 'relation-detail') return (
        <CellDrawer
            dataProviderName={fieldDataProviderName}
            value={value}
            filters={row}
            showDetail
            detailStack={detailStack}

        />
    )

    if (type === 'email') return (
        <CellEmail
            value={value}
        />
    )
    if (type === 'url') return (
        <CellUrl
            value={value}
        />
    )

    if (type === 'cars') return (
        <CellCars
            value={value}
        />
    )

    if (type === 'drivers') return (
        <CellDrivers
            value={value}
        />
    )

    if (type === 'traceKmsLast') return (
        <CellTraceKmsLast
            value={value}
        />
    )

    if (type === 'date') return (
        <CellDate
            value={value}
        />
    )

    if (type === 'datetime') return (
        <CellDatetime
            value={value}
        />
    )
    if (type === 'filesize') return (
        <CellFilesize
            value={value}
        />
    )

    if (type === 'percentage') return (
        <CellPercentage
            value={value / 100}
        />
    )

    if (type === 'boolean') return (
        <CellBoolean
            action={action}
            field={field}
            processRowUpdate={processRowUpdate}
            row={row}
            value={value}
        />
    )

    if (type === 'people') return (
        <CellPeople
            value={value}
        />
    )

    if (type === 'person') return (
        <CellPerson
            value={value}
        />
    )

    if (type === 'legalentities') return (
        <CellLegalEntities
            value={value}
        />
    )

    if (type === 'employees') return (
        <CellEmployees
            value={value}
        />
    )

    switch (type) {
        case 'nameAndIcon':

            return (
                <CellLegalEntityFlat
                    nameField={pick(columnRendered.nameField, row)}
                    iconField={pick(columnRendered.iconField, row)}
                />
            )

        case 'genericList':

            return (
                <CellGenericList
                    type={type}
                    value={value}
                    field={columnRendered?.field ?? "name"}
                    limit={columnRendered?.limit ?? 2}
                />
            )
        case 'legalEntity':
        case 'holding':
            return (
                <CellLegalEntity
                    value={value}
                />
            )
        case 'legalEntitySelf':
            return (
                <CellLegalEntity
                    value={row}
                />
            )
        case 'currency':
            return (
                <CellCurrency
                    value={value}
                />
            )
        case 'bis':
            return (
                <CellBis
                    value={value}
                />
            )
        case 'count':
        case 'cfattributionrule':
        case 'ceattributionrule':
            return (
                <CellCount
                    value={value}
                    columnRendered={columnRendered}
                />
            )
        case 'carSetup':
            return (
                <CellCarSetup
                    value={value}
                />
            )
        case 'status':
            return (
                <CellStatus
                    value={value}
                />
            )
        case 'translatable':
            return (
                <CellTranslatable
                    value={value}
                />
            )
        case 'employment':
        case 'employments':
            return (
                <CellEmployments
                    type={type}
                    value={value}
                />
            )
        case 'location':
        case 'locations':
        case 'costCenter':
        case 'costCenters':
        case 'profitCenter':
        case 'profitCenters':
        case 'pool':
        case 'pools':
        case 'businessUnit':
        case 'businessUnits':
        case 'service':
        case 'services':
        case 'source':
        case 'area':
        case 'areasource':
        case 'shop':
        case 'permission':
        case 'role':
        case 'saltedgeconnections':
        case 'saltedgeaccounts':
        case 'cashflowrecordgroups':
            return (
                <CellGenericList
                    type={type}
                    value={value}
                    field={"name"}
                    limit={2}
                />
            )
        case 'document':
        case 'documents':
            return (
                <CellGenericList
                    type={type}
                    value={value}
                    field={"filename"}
                    limit={2}
                />
            )
        case 'saltedgeconnection':
        case 'saltedgeaccount':
        case 'saltedgeprovider':
        case 'pianoconticf':
        case 'pianocontice':
            return (
                <CellGeneric
                    type={type}
                    value={value}
                    field={"name"}
                />
            )
        case 'vehiclePlate':
        case 'car':
            if (value?.plate) {
                return (
                    <CellGenericList
                        type={type}
                        value={value}
                        field={'plate'}
                        limit={2}
                    />
                );
            } else if (value?.status) {
                return (
                    <CellStatus
                        type={'carstatus'}
                        value={value.status}
                    />
                );
            }
            break
        case 'contract':
        case 'contracts':
            return (
                <CellContract
                    type={type}
                    value={value}
                    limit={2}
                />
            )
        case 'costs':
            return (
                <CellGenericList
                    type={type}
                    value={value}
                    field={"valueTotal"}
                    limit={2}
                />
            )
        case 'user':
            return (
                <CellUser
                    type={type}
                    value={value}
                />
            )
        case 'user-name':
            return (
                <CellUserName
                    type={type}
                    value={value}
                />
            )
        case 'fringebenefitvalue':
            return (
                <CellGeneric
                    type={type}
                    value={value}
                    field={"year"}
                />
            )
        case 'fringebenefitvalues':
            return (
                <CellGenericList
                    type={type}
                    value={value}
                    field={"year"}
                    limit={10}
                />
            )
        default:
            return (
                <CellSimple
                    value={(typeof value === 'object' && value) ? JSON.stringify(value) : value}
                    type={type}
                />
            )
    }

};

export default Cell
