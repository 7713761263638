import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/joy'

const CellStatus = ({ type = 'carstatus', value }) => {

    const { t } = useTranslation()

    return (
        <Box className={`list-element-cell cell-${type}`}>
            <span className={`status status-${value}`}>
                {t(value)}
            </span>
        </Box>
    )
}

export default CellStatus