import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { List as ListComponent, useDrawer } from 'src/mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';

import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import SplitButton from 'src/mtska-frontend-app-component/components/Button/SplitButton';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';
const moduleName = "Cars";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const { doAction, loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        <SplitButton
                            selectedItems={selected}
                            actions={[
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.create'],
                                    icon: "faAdd",
                                    label: t('New'),
                                    onClick: () => {
                                        activate(drawerRouteBase + '/create', {
                                            detailStack: params.detailStack ?? {},
                                            relatedElements: params.relatedElements ?? {}
                                        });
                                    }
                                },
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.import'],
                                    icon: "faDownload",
                                    label: t('Import'),
                                    onClick: () => {
                                        activate('imports/list', {
                                            filters: {
                                                items: [{
                                                    operator: "equals",
                                                    field: "model",
                                                    value: dataProviderName,
                                                }]
                                            },
                                            currentDataProviderName: dataProviderName,
                                            moduleName: moduleName,
                                        });
                                    }
                                },
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.export'],
                                    disabledIfNoItemselected: false,
                                    icon: "faUpload",
                                    label: t('Export'),
                                    onClick: () => {
                                        activate(
                                            'wizards/download',
                                            {
                                                dataProviderName: dataProviderName
                                            },
                                        )
                                    }
                                },
                                {
                                    disabled: userCapabilities && !userCapabilities['persons/person.notification'],
                                    disabledIfNoItemselected: true,
                                    icon: "faEnvelope",
                                    label: t('Notify'),
                                    onClick: () => {
                                        activate(
                                            'wizards/notify',
                                            {
                                                items: selected,
                                                dataProviderName: dataProviderName
                                            },
                                            (data) => {
                                                if (!data?.success) return;
                                                setSelected([]);
                                            }
                                        )
                                    }
                                },
                                {
                                    disabled: userCapabilities && !userCapabilities[dataProviderName + '.delete'],
                                    disabledIfNoItemselected: true,
                                    icon: "faTrash",
                                    label: t('Delete'),
                                    onClick: () => {
                                        activate(
                                            'wizards/delete',
                                            {
                                                items: selected,
                                                dataProviderName: dataProviderName
                                            },
                                            (data) => {
                                                if (!data?.success) return;
                                                setSelected([]);
                                            }
                                        )
                                    }
                                },
                            ]}
                        />,
                    ]}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                    selectionModel={selected}
                    doAction={doAction}
                />
            )}
        </>
    );
};

export default List;


