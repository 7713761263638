import { useEffect, useMemo } from 'react'
import { getEndAt, getTotalLeaseFee, getEmissionsForFringeBenefitByProvider, getEmissionsForFringeBenefitByManual, getEmissionsForFringeBenefitBySetup, getHybridizationForFringeBenefitByProvider, getHybridizationForFringeBenefitByManual, getHybridizationForFringeBenefitBySetup, getFringeBenefitFieldsByProvider, getFringeBenefitFieldsByCar, getFringeBenefitFieldsByEmission } from './detail.utils'

export const useFieldWatcher = ({ setValue, watchedFields, editing }) => {

    const {
        advanceFee = 0,
        contractDuration,
        deposit = 0,
        financialFee = 0,
        fuelFee = 0,
        fuelIncluded,
        insuranceFee = 0,
        insuranceIncluded,
        serviceFee = 0,
        start_at,
        taxFee = 0,
        taxIncluded,

        registered_at,
        usage,

        totalLeaseFee,

        emissionsProvider,
        emissionsManual,
        hybridizationProvider,
        hybridizationManual,
        setup,

        fbProvider,
        emissionsFinal,
        hybridizationFinal,
        fbFringeBenefitCar,
        fbModelSetupProvider,
        fbMonthly,
        fbAnnual,
        fbCurrency,

        buyerCommissionValue,
        buyerCommissionPercentage,
        sellerCommissionValue,
        sellerCommissionPercentage,
        status,
        requestValue,
        offerValue,
        finalValue,

        action,
        quantity,

        netValue,
        vatPercentage,
        vatValue

    } = watchedFields

    const totalLeaseFeeWatchedFields = {
        advanceFee,
        deposit,
        financialFee,
        fuelFee,
        fuelIncluded,
        insuranceFee,
        insuranceIncluded,
        serviceFee,
        taxFee,
        taxIncluded
    }

    useEndAt({ setValue, watchedFields: { contractDuration, start_at } })
    useTotalLeaseFee({ setValue, watchedFields: totalLeaseFeeWatchedFields, editing })
    useLifetimeLeaseFee({ setValue, watchedFields: { totalLeaseFee, contractDuration } })

    useInspectionAt({ setValue, watchedFields: { registered_at, usage } })

    useEmissionsForFringeBenefitByProvider({ setValue, watchedFields: { emissionsProvider }, emissionsManual, setup })
    useEmissionsForFringeBenefitByManual({ setValue, watchedFields: { emissionsManual }, emissionsProvider, setup })
    useEmissionsForFringeBenefitBySetup({ setValue, watchedFields: { setup }, emissionsProvider, emissionsManual })

    useHybridizationForFringeBenefitByProvider({ setValue, watchedFields: { hybridizationProvider }, hybridizationManual, setup })
    useHybridizationForFringeBenefitByManual({ setValue, watchedFields: { hybridizationManual }, hybridizationProvider, setup })
    useHybridizationForFringeBenefitBySetup({ setValue, watchedFields: { setup }, hybridizationProvider, hybridizationManual })

    useFringeBenefitFieldsByProvider({ setValue, watchedFields: { fbProvider }, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
    useFringeBenefitFieldsByCar({ setValue, watchedFields: { fbFringeBenefitCar }, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
    useFringeBenefitFieldsByEmission({ setValue, watchedFields: { emissionsFinal }, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })

    useProposalFinalValueGuess({ setValue, watchedFields: { status }, offerValue, finalValue })
    useProposalFinalValueChange({ setValue, watchedFields: { finalValue }, buyerCommissionPercentage, sellerCommissionPercentage })
    useProposalDelta({ setValue, watchedFields: { offerValue }, requestValue })
    useProposalBuyerCommission({ setValue, watchedFields: { buyerCommissionValue }, finalValue })
    useProposalBuyerCommissionFromValue({ setValue, watchedFields: { buyerCommissionPercentage }, finalValue })
    useProposalSellerCommission({ setValue, watchedFields: { sellerCommissionValue }, finalValue })
    useProposalSellerCommissionFromValue({ setValue, watchedFields: { sellerCommissionPercentage }, finalValue })

    useQuantityByAction({ setValue, watchedFields: { action }, quantity })

    useTotalValue({ setValue, watchedFields: { netValue, vatValue } })
    useVatPercentage({ setValue, watchedFields: { vatPercentage } })
    useVatValue({ setValue, watchedFields: { netValue, vatPercentage } })
}

const useEndAt = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const endAt = getEndAt(watchedFields)
        setValue('end_at', endAt)
    }, [...Object.values(watchedFields)])
}

const useTotalLeaseFee = ({ setValue, watchedFields, editing }) => {
    useEffect(() => {
        if (editing) {
            const totalLeaseFee = getTotalLeaseFee(watchedFields)
            setValue('totalLeaseFee', totalLeaseFee)
        }
    }, [...Object.values(watchedFields)])
}

const useInspectionAt = ({ setValue, watchedFields }) => {
    // useEffect(() => {
    //     const {
    //         registered_at,
    //         usage
    //     } = watchedFields

    //     if (registered_at !== undefined) {
    //         let firstInspectionAt = new Date(registered_at);
    //         let firstDelta = (usage !== 'specialVehicles' ? 48 : 24)

    //         firstInspectionAt.setMonth(firstInspectionAt.getMonth() + firstDelta);
    //         setValue('firstInspection_at', firstInspectionAt.toISOString());

    //         firstInspectionAt.setMonth(firstInspectionAt.getMonth() + 24);
    //         setValue('nextInspection_at', firstInspectionAt.toISOString());
    //     }

    // }, [...Object.values(watchedFields)])
}

const useLifetimeLeaseFee = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const {
            totalLeaseFee,
            contractDuration
        } = watchedFields

        setValue('lifetimeLeaseFee', parseFloat((totalLeaseFee * contractDuration).toFixed(2)));

    }, [...Object.values(watchedFields)])
}

const useEmissionsForFringeBenefitByProvider = ({ setValue, watchedFields, emissionsManual, setup }) => {

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitByProvider(watchedFields, emissionsManual, setup);
        setValue('emissionsFinal', emissionsFinal)
    }, [...Object.values(watchedFields)])
}

const useEmissionsForFringeBenefitByManual = ({ setValue, watchedFields, emissionsProvider, setup }) => {

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitByManual(watchedFields, emissionsProvider, setup);
        setValue('emissionsFinal', emissionsFinal)
    }, [...Object.values(watchedFields)])
}

const useEmissionsForFringeBenefitBySetup = ({ setValue, watchedFields, emissionsProvider, emissionsManual }) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitBySetup(watchedFields, emissionsProvider, emissionsManual);
        setValue('emissionsFinal', emissionsFinal)
    }, [memoizedWatchedFields])
}


const useHybridizationForFringeBenefitByProvider = ({ setValue, watchedFields, hybridizationManual, setup }) => {

    useEffect(() => {
        const hybridizationFinal = getHybridizationForFringeBenefitByProvider(watchedFields, hybridizationManual, setup);
        setValue('hybridizationFinal', hybridizationFinal)
    }, [...Object.values(watchedFields)])
}

const useHybridizationForFringeBenefitByManual = ({ setValue, watchedFields, hybridizationProvider, setup }) => {

    useEffect(() => {
        const hybridizationFinal = getHybridizationForFringeBenefitByManual(watchedFields, hybridizationProvider, setup);
        setValue('hybridizationFinal', hybridizationFinal)
    }, [...Object.values(watchedFields)])
}

const useHybridizationForFringeBenefitBySetup = ({ setValue, watchedFields, hybridizationProvider, hybridizationManual }) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const hybridizationFinal = getHybridizationForFringeBenefitBySetup(watchedFields, hybridizationProvider, hybridizationManual);
        setValue('hybridizationFinal', hybridizationFinal)
    }, [memoizedWatchedFields])
}

const useFringeBenefitFieldsByProvider = ({ setValue, watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency }) => {

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByProvider(watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields == undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [...Object.values(watchedFields)])

}

const useFringeBenefitFieldsByCar = ({ setValue, watchedFields, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency }) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByCar(watchedFields, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields == undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [memoizedWatchedFields])

}

const useFringeBenefitFieldsByEmission = ({ setValue, watchedFields, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency }) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByEmission(watchedFields, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields == undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [memoizedWatchedFields])
}

const useProposalFinalValueGuess = ({ setValue, watchedFields, offerValue, finalValue }) => {
    useEffect(() => {
        const {
            status
        } = watchedFields

        if (!finalValue) {
            setValue('finalValue', offerValue)
        }
    }, [...Object.values(watchedFields)])
}

const useProposalFinalValueChange = ({ setValue, watchedFields, buyerCommissionPercentage, sellerCommissionPercentage }) => {
    useEffect(() => {
        const {
            finalValue
        } = watchedFields

        setValue('buyerCommissionValue', parseInt(finalValue) * parseFloat(buyerCommissionPercentage) / 100);
        setValue('sellerCommissionValue', parseInt(finalValue) * parseFloat(sellerCommissionPercentage) / 100);

    }, [...Object.values(watchedFields)])
}

const useProposalDelta = ({ setValue, watchedFields, requestValue }) => {
    useEffect(() => {
        const {
            offerValue
        } = watchedFields

        setValue('deltaValue', parseInt(requestValue) - parseInt(offerValue));
        setValue('deltaPercentage', (100 - parseFloat(parseInt(offerValue) * 100 / parseInt(requestValue)).toFixed(2)).toFixed(2));

    }, [...Object.values(watchedFields)])
}

const useProposalBuyerCommission = ({ setValue, watchedFields, finalValue }) => {
    useEffect(() => {
        const {
            buyerCommissionValue
        } = watchedFields

        if (document.activeElement.name === 'buyerCommissionValue') {
            setValue('buyerCommissionPercentage', parseFloat(parseInt(buyerCommissionValue) * 100 / parseInt(finalValue)).toFixed(2));
        }

    }, [...Object.values(watchedFields)])
}

const useProposalBuyerCommissionFromValue = ({ setValue, watchedFields, finalValue }) => {
    useEffect(() => {
        const {
            buyerCommissionPercentage
        } = watchedFields

        if (document.activeElement.name === 'buyerCommissionPercentage') {
            setValue('buyerCommissionValue', parseInt(finalValue) * parseFloat(buyerCommissionPercentage) / 100);
        }
    }, [...Object.values(watchedFields)])
}

const useProposalSellerCommission = ({ setValue, watchedFields, finalValue }) => {
    useEffect(() => {
        const {
            sellerCommissionValue
        } = watchedFields

        if (document.activeElement.name === 'sellerCommissionValue') {
            setValue('sellerCommissionPercentage', parseFloat(parseInt(sellerCommissionValue) * 100 / parseInt(finalValue)).toFixed(2));
        }
    }, [...Object.values(watchedFields)])
}

const useProposalSellerCommissionFromValue = ({ setValue, watchedFields, finalValue }) => {
    useEffect(() => {
        const {
            sellerCommissionPercentage
        } = watchedFields

        if (document.activeElement.name === 'sellerCommissionPercentage') {
            setValue('sellerCommissionValue', parseInt(finalValue) * parseFloat(sellerCommissionPercentage) / 100);
        }
    }, [...Object.values(watchedFields)])
}

const useQuantityByAction = ({ setValue, watchedFields, quantity }) => {
    useEffect(() => {
        const {
            action
        } = watchedFields

        setValue('quantity', (() => {
            switch (action) {
                case 'rebate':
                    return 0;
                case 'discharge_acceptance':
                case 'discharge_end_of_agreement':
                case 'discharge_area_change':
                case 'discharge_end_of_employment':
                    return -1;
                case 'start':
                case 'charge':
                case 'charge_for_takeover':
                    return 1;
                default:
                    return null;
            }
        })());

    }, [...Object.values(watchedFields)])
}

const useTotalValue = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { netValue, vatValue } = watchedFields
        const totalValue = Math.round((parseFloat(netValue) + parseFloat(vatValue)) * 100) / 100
        setValue('totalValue', totalValue)
    }, [...Object.values(watchedFields)])
}

const useVatPercentage = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { vatPercentage } = watchedFields
        if (vatPercentage > 100) {
            setValue('vatPercentage', 100)
        }
    }, [...Object.values(watchedFields)])
}

const useVatValue = ({ setValue, watchedFields }) => {
    useEffect(() => {
        const { netValue, vatPercentage } = watchedFields
        const vatValue = Math.round((netValue * vatPercentage / 100) * 100) / 100
        setValue('vatValue', vatValue)
    }, [...Object.values(watchedFields)])
}