import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { Cell } from '.'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// todo ==> editMode momentaneamente sempre a true, capire se serve gestirla
export const CellSelect = (props) => {

    const {
        action,
        field,
        optionKey,
        optionLabel,
        processRowUpdate,
        row = {},
        type,
        value = {},
        valueOptions
    } = props

    const [currentValue, setCurrentValue] = useState(value || {})
    const [editMode, setEditMode] = useState(true)

    const onChange = (e) => {
        const { value } = e.target
        setCurrentValue(value)
        const updatedRow = { ...row, [field]: value }
        processRowUpdate(updatedRow, row, action)
        /* setEditMode(false) */
    }

    return (
        <Box className={"list-element-cell cell-contract cell-contract-" + type}>
            {editMode
                ? (
                    <Select
                        disabled={!valueOptions}
                        fullWidth
                        onChange={onChange}
                        size='small'
                        sx={{ padding: '6px 6px 2px 6px' }}
                        value={currentValue}
                        variant='standard'
                    >
                        {(valueOptions || []).map(option => (
                            <MenuItem key={optionKey ? optionKey : option} value={option}>
                                {optionLabel ? option[optionLabel] : option}
                            </MenuItem>
                        ))}
                    </Select>
                )
                : (
                    <div onClick={() => setEditMode(true)} style={{ height: '100%', width: '100%' }}>
                        <Cell {...props} />
                    </div>
                )
            }
        </Box>
    )
}
