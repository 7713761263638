import React from 'react'
import { styles as commonStyles } from '../../../excelPreview.styles'
import { styles } from './fullRow.styles'

export const FullRow = ({ value }) => {

    const { cellS } = commonStyles
    const { fullRowS } = styles

    return (
        <div style={{ ...cellS, ...fullRowS }}>
            {value}
        </div>
    )
}
