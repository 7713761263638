
export const useXMLMapping = () => {
    const mapItalianInvoiceXml = () => {
        return {
            invoice_country: "country",
            invoice_code: "code",
            invoice_progressive_id: "progressiveID",
            invoice_type: "type",
            invoice_currency: "currency",
            invoice_at: "document_at",
            invoice_number: "documentID",
            invoice_total_value: "totalValue",
            invoice_net_value: "netValue",
            invoice_vat_value: "vatValue",
            invoice_vat_percentage: "vatPercentage",
            invoice_vat_appliable: "vatAppliable",
            invoice_part_line: "contoEconomicoRecord.line",
            invoice_part_description: "contoEconomicoRecord.description",
            invoice_part_quantity: "contoEconomicoRecord.units",
            invoice_part_unitofmeasure: "contoEconomicoRecord.unitOfMeasure",
            invoice_part_price_unit: "contoEconomicoRecord.unitValue",
            invoice_part_price_total: "contoEconomicoRecord.totalValue",
            invoice_part_vat_percentage: "contoEconomicoRecord.vatPercentage",
            customer_vat_country: "legalEntity.vatCountry",
            customer_vat_code: "legalEntity.vatCode",
            customer_fiscalcode: "legalEntity.taxCode",
            customer_name: "legalEntity.name",
            customer_location_address: "legalEntity.registeredOfficeAddress",
            customer_location_zip: "legalEntity.registeredOfficeZip",
            customer_location_city: "legalEntity.registeredOfficeCity",
            customer_location_province: "legalEntity.registeredOfficeProvince",
            customer_location_country: "legalEntity.registeredOfficeCountry",
            customer_contacts_telephone: "legalEntity.telephone",
            supplier_vat_country: "supplier.vatCountry",
            supplier_vat_code: "supplier.vatCode",
            supplier_fiscalcode: "supplier.taxCode",
            supplier_name: "supplier.name",
            supplier_location_address: "supplier.registeredOfficeAddress",
            supplier_location_zip: "supplier.registeredOfficeZip",
            supplier_location_city: "supplier.registeredOfficeCity",
            supplier_location_province: "supplier.registeredOfficeProvince",
            supplier_location_country: "supplier.registeredOfficeCountry",
            supplier_contacts_telephone: "supplier.telephone",
            payment_condition: "paymentCondition",
            payment_mode: "paymentMode",
            payment_total_value: "paymentValueTotal",
            payment_iban: "paymentIban"
        }
    }

    return {
        mapItalianInvoiceXml
    }
}