import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DrawerButton, List as ListComponent, useDrawer } from 'src/mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'imports/job';
const drawerRouteBase = 'imports';
const wizardAction = 'destroy';

const List = (params) => {

    const { importModel, jobDataProviderName } = params || {}

    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getselected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
    }

    const newDataProviderName = jobDataProviderName || dataProviderName
    const { doAction, loadAll, items, total, loading, download, downloading, reloadCounter } = useDataProvider(newDataProviderName)

    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleClick = (row) => {
        activate(
            drawerRouteBase + '/detail',
            {
                dataProviderProps: {
                    clickable: true,
                    dataProviderName,
                    dynamicColumns: false,
                    field: 'job_wrapper_id',
                    title: 'File list'
                },
                id: row.id
            }
        )
    }

    return (
        <>
            <ListComponent
                {...params}
                onRowDoubleClick={handleRowDoubleClick}
                onFilterPageSortChange={handleFilterPageSortChange}
                items={items}
                total={total}
                loading={loading}
                apiRef={apiRef}
                enableSelection
                rightButtons={[
                    // <Button disabled={!userCapabilities[dataProviderName + '.archive']}
                    //     icon={"faTrash"}
                    //     onClick={() => {
                    //         activate('imports/archive', { filters: getselected() });
                    //     }} 
                    // />,
                    <DrawerButton
                        drawerRoute={'imports/create'}
                        params={{
                            detailStack: params.detailStack ?? {},
                            dataProviderName: params.currentDataProviderName,
                            filters: params.filters,
                            importModel,
                            moduleName: params.moduleName,
                        }}
                        callback={handleFilterPageSortChange}
                        label={t("New Import")}
                    />,
                ]}
                dataProviderName={newDataProviderName}
                title={t("Import")}
                doAction={doAction}
            />
        </>
    );
}

export default List;


