import React from "react";
import { Box } from '@mui/joy';
import { KPI, Pie, Timeline, Table } from 'src/mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';
import { RouterAnchor } from "src/mtska-frontend-app-component/components/RouterAnchor";

const FleetManager = () => {
    const { t } = useTranslation();

    return (
        <Box className="dashboard-menu dashboard-menu-1col">
            <Box className="dashboard-section-title">
                <h2>{t('Savings')}</h2>
            </Box>
            <Box className="dashboard-menu-itemgroup">
                <KPI
                    title="Bid saving average annual"
                    icon="faMoneyBill"
                    dataProviderName={'statistics/monthly/avgannualfinancial/cars/contract/bidsaving'}
                    format="currency"
                    className="dashboard-menu-item"
                />
                <KPI
                    title="Budget saving average annual"
                    icon="faInputNumeric"
                    dataProviderName={'statistics/monthly/avgannualfinancial/cars/contract/budgetsaving'}
                    format="currency"
                    className="dashboard-menu-item"
                />
                <KPI
                    title="Recalculation saving"
                    icon="faCalculator"
                    dataProviderName={'statistics/monthly/sumfinancial/cars/contract/recalculationsaving'}
                    format="currency"
                    className="dashboard-menu-item"
                />
                {/* <KPI
                    title="Renewal saving"
                    icon="faRecycle"
                    dataProviderName={'statistics/monthly/sumfinancial/cars/contract/renewalsaving'}
                    format="currency"
                    className="dashboard-menu-item"
                /> */}
                {/* <Box>
                    [somma_di_tutti_i_saving – (costo_a_vettura_fleet_software_per_holding * numero_vetture_gestite_in_marcia) / (costo_a_vettura_fleet_software_per_holding * numero_vetture_gestite_in_marcia)] x 100 = ROI <br />
                    <i>ROI: mostrare se ----&rt; Ottimo per valori pari/superiori 10% - 12% o Buono per valori compresi tra 8% - 9%</i>
                </Box> */}
            </Box>

            <Box className="dashboard-section-title">
                <h2>{t('Fleet status')}</h2>
                <RouterAnchor icon={'faCar'} href={'/cars/car'} underline="none" className="buttonized MuiButton-variantSolid input-button tiny">
                    {t('Fleet')}
                </RouterAnchor>
            </Box>
            <Box className="dashboard-menu-itemgroup">
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByMake"
                        title={t("Fleet by make")}
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByFuelType"
                        title={t("Fleet by fuel type")}
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByRenter"
                        title={t("Fleet by Renter")}
                    />
                </Box>
            </Box>
            <Box className="dashboard-menu-itemgroup">
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByStatus"
                        title={t("Fleet by vehicle status")}
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByUsage"
                        title={t("Fleet by vehicle type")}
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Pie
                        dataProviderName="statistics/fleetByEnvironmentalClass"
                        title={t("Fleet by environmental class")}
                    />
                </Box>
            </Box>

            <Box className="dashboard-section-title">
                <h2>{t('Order process')}</h2>
            </Box>
            <Box className="dashboard-menu-itemgroup">
                <Box className="dashboard-menu-item">
                    <Timeline
                        dataProviderName="statistics/ordersByDeliveryDate"
                        title={t("Orders by delivery date")}
                    />
                </Box>
            </Box>

            <Box className="dashboard-section-title">
                <h2>{t('Costs')}</h2>
                <RouterAnchor icon={'faMoneyBill'} href={'/cars/cost'} underline="none" className="buttonized MuiButton-variantSolid input-button tiny">
                    {t('Costs')}
                </RouterAnchor>
            </Box>
            <Box className="dashboard-menu-itemgroup">
                <Box className="dashboard-menu-item">
                    <Timeline
                        dataProviderName="statistics/rentByDate"
                        title={t("Monthly fee")}
                        format="currency"
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Timeline
                        dataProviderName="statistics/refuelingByDate"
                        title={t("Fuel fee")}
                        format="currency"
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Timeline
                        dataProviderName="statistics/tollByDate"
                        title={t("Toll fee")}
                        format="currency"
                    />
                </Box>
                <Box className="dashboard-menu-item">
                    <Timeline
                        dataProviderName="statistics/fineByDate"
                        title={t("Fine fee")}
                        format="currency"
                    />
                </Box>
            </Box>

            <Box className="dashboard-section-title">
                <h2>{t('Actionables')}</h2>
            </Box>
            <Box className="dashboard-menu-itemgroup">

                <Box className="dashboard-menu-item autoheight">
                    <Table
                        title={t("Missing mileage")}
                        dataProviderName={'statistics/cars/missingKms'}
                        columns={[
                            { field: 'plate', headerName: t('plate'), width: 150 },
                            { field: '_related_drivers_drivers_readable', headerName: t('driver'), flex: 1  },
                        ]}
                        drawerRouteBase='cars'
                    />
                </Box>

                <Box className="dashboard-menu-item autoheight">
                    <Table
                        title={t("Inspection expired")}
                        dataProviderName={'statistics/cars/expiredInspection'}
                        columns={[
                            { field: 'plate', headerName: t('plate'), width: 150 },
                            { field: '_related_drivers_drivers_readable', headerName: t('driver'), flex: 1  },
                            { field: 'nextInspectionAt_readable', headerName: t('date'), flex: 1  },
                        ]}
                        drawerRouteBase='cars'
                    />
                </Box>

                <Box className="dashboard-menu-item autoheight">
                    <Table
                        title={t("Contract close to expiration")}
                        dataProviderName={'statistics/cars/contractExpiring'}
                        columns={[
                            { field: 'plate', headerName: t('plate'), width: 150 },
                            { field: '_related_drivers_drivers_readable', headerName: t('driver'), flex: 1  },
                        ]}
                        drawerRouteBase='cars'
                    />
                </Box>


                {/* <Box className="dashboard-menu-item">CONTRATTI IN SCADENZA <button>NOTIFICA</button></Box>
                <Box className="dashboard-menu-item">PATENTI IN SCADENZA  <button>NOTIFICA</button></Box> */}
            </Box>
            {
            // <Box className="dashboard-menu-itemgroup">
            //     <Box className="dashboard-menu-item autoheight">
            //         <Table
            //             title={t("Expiring insurance")}
            //             dataProviderName={'statistics/cars/insuranceExpiring'}
            //             columns={[
            //                 { field: 'plate', headerName: t('plate'), width: 150 },
            //                 { field: '_related_drivers_drivers_readable', headerName: t('driver'), flex: 1  },
            //             ]}
            //             drawerRouteBase='cars'
            //         />
            //     </Box>
            // </Box> 
            }
        </Box>

    )

}

export default FleetManager;


