import React from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './excelPreview.styles'
import { CircularProgress } from '@mui/joy'
import { DownloadButton } from 'src/mtska-frontend-app-component'
import { Sections } from './sections/Sections'

export const ExcelPreview = ({ data, dataProviderName, download = () => { }, downloading, userCapabilities }) => {

    const { company, key, title } = data || {}

    const { t } = useTranslation()

    const { containerButtonsS, containerS, footerS, headerCellS, titleS } = styles

    return (
        !data
            ? <div style={{ ...headerCellS }}><CircularProgress /></div>
            : data[key]
                ? (
                    <div style={{ ...containerS }}>
                        <div style={{ ...titleS }}>
                            <div>
                                {company}: {title}
                            </div>
                            <div style={{ ...containerButtonsS }}>
                                <DownloadButton
                                    dataProviderName={dataProviderName}
                                    onClick={download}
                                    disabled={/* (userCapabilities && !userCapabilities[dataProviderName + '.export']) || */ downloading}
                                    downloading={downloading}
                                />
                            </div>
                        </div>
                        <Sections data={data} />
                        <div style={{ ...footerS }}>

                        </div>
                    </div>
                )
                : <div style={{ ...headerCellS }}>{t('No rows')}</div>
    )
}
