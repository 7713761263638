import React from "react"

import { Controller } from "react-hook-form";
import InputDropzoneElement from "./InputDropzoneElement";
//https://react-dropzone.js.org/#section-basic-example

const InputDropzoneControl = ({ editing, required, name, control, label, hint, haserror, multiple, disabled, readonly, className = "", beforeOnClick = () => {} }) => {
    if (!editing) readonly = true;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                return (
                    <InputDropzoneElement
                        id={name}
                        name={name}
                        defaultValue={value}
                        label={label}
                        hint={hint}

                        error={error}
                        invalid={(error) ? invalid : haserror}
                        editing={editing}
                        required={required}
                        multiple={multiple}
                        disabled={disabled}
                        readonly={readonly}

                        className={className}
                        onDrop={files => {
                            console.log('Files', files)
                            onChange(files);
                        }}
                        beforeOnClick={beforeOnClick}
                    />
                )
            }}
        />
    )
}

export default InputDropzoneControl;

