import React from "react"
import { Box, Card, CardActions, CardContent, CircularProgress, ModalClose } from "@mui/joy"
import { Button, DetailTitle, InfoHeader, useDrawer } from "src/mtska-frontend-app-component";
import { useTranslation } from 'react-i18next';
import { useDataProvider } from "src/mtska-frontend-data-provider";

const Delete = (params) => {
   const { t } = useTranslation();
   const { close } = useDrawer();
   const { doAction, getOne, saving } = useDataProvider(params.dataProviderName);

   return <>
      {params.isDrawer && (
         <Box className="drawer-section-header" >
            <ModalClose />
            <DetailTitle title={t("Items elimination")} subtitle={t("delete")} />

         </Box>
      )}
      <Box className='drawer-section-content drawer-section-content-embedded form-element col-12'>
         <InfoHeader view="wizard" title={t("Are you sure to remove the selected elements?")} />
         <Card className="form-wrapper">
            <CardContent>
               {params.items.map((id) => {
                  const item = getOne(id);
                  return item?.plate ?? item?.name;
               }).join(', ')}
            </CardContent>
            <CardActions>
               <Button variant={"soft"} onClick={() => { close(); }}>{t("NO")}</Button>
               {saving ? (
                  <CircularProgress></CircularProgress>
               ) : (<Button variant={"solid"}
                  onClick={() => {
                     doAction({
                        action: 'destroy',
                        model: params.dataProviderName,
                        data: params.items
                     }).then(close({ success: true }))
                  }} >{t("YES")}</Button>
               )}
            </CardActions>

         </Card>
      </Box>
   </>
}

export default Delete;

