import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Contracts"
                icon="faFileSignature"
                dataProviderName={'statistics/item/count/cars/contract'}
            />
            <KPI
                title="totalLeaseFee"
                icon="faMoneyBill"
                dataProviderName={'statistics/item/sumfinancial/cars/contract/totalLeaseFee'}
                format="currency"
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


