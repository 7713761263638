import React, { useState, useContext } from 'react';
import { InfoHeader, Detail as DetailComponent,  useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import SplitButton from 'src/mtska-frontend-app-component/components/Button/SplitButton';
import { useLoadOne } from 'src/mtska-frontend-data-provider/hooks/useLoadOne.hooks';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const Detail = (params) => {
    const { t } = useTranslation();
    const { activate, close } = useDrawer();

    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { item, save, loadOne, errorMessage, errors, saving, reloadCounter } = useDataProvider(dataProviderName, params.multipart);
    const [assignedServices, setAssignedServices] = useState([])

    const { modelName, detailConfig } = existingModules[dataProviderName]

    // Ricerco i servizi collegati per poi passare il filtro al bottone di aggiunta costi
    const loadAssignedServices = (item) => {
        let services = [];
        const direct = item?.data?.services?.map((service) => service._id).flat(Infinity);
        const contract = item?.data?.contract?.services?.map((service) => service._id).flat(Infinity);
        const employment = item?.data?.pools?.map((pool) => pool.employments?.map((employment) => employment.services?.map((service) => service._id))).flat(Infinity);

        if (typeof direct != 'undefined') services.push(...direct);
        if (typeof contract != 'undefined') services.push(...contract);
        if (typeof employment != 'undefined') services.push(...employment);

        setAssignedServices(services);
    }

    useLoadOne({ loadOne, params, reloadCounter, onSuccess: loadAssignedServices })


    const [editing, setEditing] = useState(false)

    const handleSave = (data) => {
        save({ ...data, filters: params.filters }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }



    return (
        <>
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName + '.edit']}
                title={(item?.data?.plate) ? item?.data?.plate : t('No plate yet assigned')}
                header={
                    <InfoHeader
                        view="vehicle"
                        item={item?.data}
                    />
                }
                rightButtons={[
                    <SplitButton
                        actions={[
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.statusmanagement'],
                                icon: "faChartNetwork",
                                label: t('Manage Status'),
                                onClick: () => { activate(drawerRouteBase + '/statusManagement', { detailStack: params.detailStack ?? {}, legalEntity: item?.data?.legalEntity, multipart: true }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.platemanagement'],
                                icon: "faInputNumeric",
                                label: t('Manage Plate'),
                                onClick: () => { activate(drawerRouteBase + '/plateManagement', { detailStack: params.detailStack ?? {}, legalEntity: item?.data?.legalEntity }); }
                            },
                            {
                                disabled: item?.data?.isPool != 'true' && userCapabilities && !userCapabilities['cars/carcalendar.calendarmanagement'],
                                icon: "faCalendar",
                                label: t('Manage Car Calendar'),
                                onClick: () => { activate(drawerRouteBase + '/carCalendarManagement', { detailStack: params.detailStack ?? {}, car: item?.data }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['costs.create'],
                                icon: "faMoneyBill",
                                label: t('Add Cost'),
                                onClick: () => {
                                    activate('costs/create', {
                                        detailStack: params.detailStack ?? {},
                                        legalEntity: item?.data?.legalEntity,
                                        detailConfigOverride: {
                                            "tabs": [
                                                {
                                                    "fieldsets": [
                                                        {
                                                            "fields": {
                                                                "service": {
                                                                    "type": "servicelist",
                                                                    "className": "col-12",
                                                                    "hideTableToolbar": true,
                                                                    "filters": {
                                                                        "items": [
                                                                            {
                                                                                "field": "_id",
                                                                                "operator": "isAnyOf",
                                                                                "value": assignedServices
                                                                            }
                                                                        ]
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]

                                        }
                                    },
                                        () => { loadOne(params.id); });
                                }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['tracekms.create'],
                                icon: "faRoad",
                                label: t('Add Trace Km'),
                                onClick: () => { activate('tracekms/create', { detailStack: params.detailStack ?? {}, car: item?.data, legalEntity: item?.data?.legalEntity, traceKmsLast: item?.data?.traceKmsLast }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['inspections.create'],
                                icon: "faRoad",
                                label: t('Add Inspection'),
                                onClick: () => { activate('cars/inspectionAccomplished', { detailStack: params.detailStack ?? {}, carId: item?.data?._id, legalEntity: item?.data?.legalEntity }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.contractassociation'],
                                icon: "faFileSignature",
                                label: t('Assign a Contract'),
                                onClick: () => { activate(drawerRouteBase + '/contractAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.contractdissociation'],
                                icon: "faFileSignature",
                                label: t('Remove a Contract'),
                                onClick: () => { activate(drawerRouteBase + '/contractDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['cars/contract.contractextension'],
                                icon: "faCalculator",
                                label: t('Contract adjustment'),
                                onClick: () => {
                                    const reajustableContract = item?.data?.contracts?.find(contract => !contract.hasOwnProperty('contractReajustmentEnd_at'));
                                    //hack to fix stoopid problem
                                    reajustableContract.id = reajustableContract._id;

                                    if (!reajustableContract) alert(t('No reajustable contract found'));

                                    activate('contracts/contractExtension', {
                                        id: reajustableContract.id,
                                        item: reajustableContract,
                                        detailStack: params.detailStack ?? {}
                                    });
                                }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.serviceassociation'],
                                icon: "faCreditCard",
                                label: t('Assign a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.servicedissociation'],
                                icon: "faCreditCard",
                                label: t('Remove a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.personassociation'],
                                icon: "faPerson",
                                label: t('Assign to a Driver'),
                                onClick: () => { activate(drawerRouteBase + '/personAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.persondissociation'],
                                icon: "faPerson",
                                label: t('Remove a Driver'),
                                onClick: () => { activate(drawerRouteBase + '/personDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['documents/document.deliveryreportassociation'],
                                icon: "faPlay",
                                label: t('Add Delivery Report'),
                                onClick: () => { activate(drawerRouteBase + '/deliveryReportAssociation', { carId: item?.data?._id }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['documents/document.returnreportassociation'],
                                icon: "faStop",
                                label: t('Add Return Report'),
                                onClick: () => { activate(drawerRouteBase + '/returnReportAssociation', { car: item?.data, multipart: true }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['documents/document.carpictureassociation'],
                                icon: "faImage",
                                label: t('Add Car Picture'),
                                onClick: () => { activate(drawerRouteBase + '/carPictureAssociation', { car: item?.data, multipart: true }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['documents/document.genericfileassociation'],
                                icon: "faFile",
                                label: t('Add Car Document'),
                                onClick: () => { activate(drawerRouteBase + '/genericFileAssociation', { car: item?.data, multipart: true }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities['persons/person.notification'],
                                icon: "faEnvelope",
                                label: t('Notify'),
                                onClick: () => {
                                    activate(
                                        'wizards/notify',
                                        {
                                            items: [item.data.id],
                                            dataProviderName: dataProviderName
                                        },
                                        (data) => {
                                            if (!data?.success) return;
                                        }
                                    )
                                }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.delete'],
                                icon: "faTrash",
                                label: t('Delete'),
                                onClick: () => {
                                    activate(
                                        'wizards/delete',
                                        {
                                            items: [item.data.id],
                                            dataProviderName: dataProviderName
                                        },
                                        (data) => {
                                            if (!data?.success) return;
                                            close();
                                        }
                                    )
                                }
                            },


                        ]}
                    />
                ]}
                {...params}
            />

        </>
    )
}

export default Detail;



