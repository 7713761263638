import React, { useState, useEffect, useContext } from "react";
import { AppMenu, ActiveAppName, UserMenu, Debugger, LegalEntitySelector } from '../../../mtska-frontend-app-component';
import { AppContext } from '../../contexts';
import Minimizer from "./Minimizer";
import { Box } from '@mui/joy'

const Aside = ({ onOpenLayout }) => {

    const { filterByLegalEntity, innerWidth, userCapabilities } = useContext(AppContext)
    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        setIsOpen((sessionStorage.getItem('prefersAsideOpen') === 'true') ?? true);
        onOpenLayout();
    }, [])

    const [isMouseInside, setIsMouseInside] = useState(false);

    const handleIsOpen = () => {
        sessionStorage.setItem('prefersAsideOpen', !isOpen)
        setIsOpen(!isOpen);
        onOpenLayout();
    }

    const handleMouseEnter = () => {
        setIsMouseInside(true);
    }

    const handleMouseLeave = () => {
        setIsMouseInside(false);
    }

    return (
        <aside className={(isOpen) ? 'open' : 'closed'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ActiveAppName />
            <Debugger />

            {
                !userCapabilities['auth/role.is_driver'] && (
                    <LegalEntitySelector />
                )
            }

            <AppMenu />
            {innerWidth > 600 && (
                <Box sx={{ bottom: 20, position: 'absolute', width: '100%' }}>
                    <UserMenu />
                </Box>
            )}
            <Minimizer onClick={handleIsOpen} visible={isMouseInside} open={isOpen} />
        </aside>
    );
}

export default Aside;