import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { Box, CircularProgress } from "@mui/joy";
import Tooltip from '@mui/material/Tooltip';
import { PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { BookingFormDialog } from "../Dialog";
import { Backdrop } from "@mui/material";

const WidgetCalendarBooking = (params) => {
    const { t } = useTranslation();
    const { item: calendar, loadOne } = useDataProvider("calendars/carcalendar");
    const { items: bookings, loadAll } = useDataProvider("calendars/carcalendarrecord");

    const [dateRange, setDateRange] = useState([null, null]);
    const [hasSelectionCompleted, setHasSelectionCompleted] = useState(false);
    const [drawable, setDrawable] = useState(false);
    const [bookabilityStart, setBookabilityStart] = useState(null);
    const [bookabilityEnd, setBookabilityEnd] = useState(null);
    const [minDays, setMinDays] = useState(null);
    const [maxDays, setMaxDays] = useState(null);
    const [recoveryTime, setRecoveryTime] = useState(null);
    const [requiredStartDay, setRequiredStartDay] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);
    const [hasError, setHasError] = useState(false);

    const [optionedDates, setOptionedDates] = useState(new Set());
    const [recoveryDates, setRecoveryDates] = useState(new Set());
    const [bookingsData, setBookingsData] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [possibleDrivers, setPossibleDrivers] = useState({});

    useEffect(() => {
        loadOne(params.calendar_id);
    }, [params.calendar_id]);

    useEffect(() => {
        if (!calendar?.data) return;

        setBookabilityStart(prev => prev || dayjs(calendar.data.bookabilityStart_at));
        setBookabilityEnd(prev => prev || dayjs(calendar.data.bookabilityEnd_at));
        setMinDays(prev => prev ?? parseInt(calendar.data.minimumDaysBookable, 10));
        setMaxDays(prev => prev ?? parseInt(calendar.data.maximumDaysBookable, 10));
        setRecoveryTime(prev => prev ?? parseInt(calendar.data.recoveryTimeAtReenter, 10));
        setRequiredStartDay(prev => prev ?? parseInt(calendar.data.requiredStartDay, 10));
        setPossibleDrivers(calendar.data.car.pools);

        loadAll({
            items: [{
                field: "car_calendar_id",
                operator: "equals",
                value: params.calendar_id
            }]
            /**
             * @todo: aggiungere il filtro per recuperare le voci di calendario comprese tra il precedente mese e il successivo
             */
        });
    }, [calendar?.data]);


    useEffect(() => {
        if (!bookings || !Array.isArray(bookings) ) return;

        const newOptionedDates = new Set();
        const newRecoveryDates = new Set();
        const newBookingsData = {};

        bookings.forEach((booking) => {
            const startDate = dayjs(booking.start_at);
            const endDate = dayjs(booking.end_at);

            let currentDate = startDate;
            while (currentDate.isBefore(endDate, "day") || currentDate.isSame(endDate, "day")) {
                const dateStr = currentDate.format("YYYY-MM-DD");
                newOptionedDates.add(dateStr);
                newBookingsData[dateStr] = {
                    firstname: booking.employment?.person?.firstname,
                    lastname: booking.employment?.person?.lastname,
                    created_at: dayjs(booking.created_at)
                };
                currentDate = currentDate.add(1, "day");
            }

            if (recoveryTime > 0) {
                let recoveryStart = endDate.add(1, "day");
                let recoveryEnd = recoveryStart.add(recoveryTime - 1, "day");

                let recoveryDate = recoveryStart;
                while (recoveryDate.isBefore(recoveryEnd, "day") || recoveryDate.isSame(recoveryEnd, "day")) {
                    newRecoveryDates.add(recoveryDate.format("YYYY-MM-DD"));
                    recoveryDate = recoveryDate.add(1, "day");
                }
            }
        });

        setOptionedDates(newOptionedDates);
        setRecoveryDates(newRecoveryDates);
        setBookingsData(newBookingsData);
        setDrawable(true);
    }, [bookings]);

    const handleOpenBookingDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);


    const handleSavedCalendar = useCallback(() => {
        setDialogOpen(false);
        loadAll({
            items: [{
                field: "car_calendar_id",
                operator: "equals",
                value: params.calendar_id
            }]
        });
    }, []);

    const isDateDisabled = (date) => {
        return date.isBefore(bookabilityStart) || date.isAfter(bookabilityEnd)
    };

    const onChange = (newValue) => {
        setWarningMessage(null);
        setHasError(false);

        if (hasSelectionCompleted && newValue[0]) {
            setDateRange([newValue[0], null]);
            setHasSelectionCompleted(false);
            return;
        }

        if (newValue[0] && newValue[1]) {
            // 1. Controllo date occupate nella selezione iniziale
            const isInvalidSelection = newValue.some(date =>
                date && (optionedDates.has(date.format("YYYY-MM-DD")) ||
                    recoveryDates.has(date.format("YYYY-MM-DD")))
            );

            if (isInvalidSelection) {
                setWarningMessage(t('Selected dates include unavailable periods'));
                setHasError(true);
                return;
            }

            // 2. Calcola differenza giorni
            let diffDays = newValue[1].diff(newValue[0], "day") + 1;
            let adjustedEndDate = newValue[1];

            // 3. Controllo minimo giorni
            if (diffDays < minDays) {
                const proposedEnd = newValue[0].add(minDays - 1, "day");

                // Verifica che tutti i giorni aggiunti siano disponibili
                let allDaysValid = true;
                let checkDate = newValue[0];

                while (checkDate.isBefore(proposedEnd) || checkDate.isSame(proposedEnd, "day")) {
                    if (optionedDates.has(checkDate.format("YYYY-MM-DD")) ||
                        recoveryDates.has(checkDate.format("YYYY-MM-DD"))) {
                        allDaysValid = false;
                        break;
                    }
                    checkDate = checkDate.add(1, "day");
                }

                if (allDaysValid) {
                    adjustedEndDate = proposedEnd;
                    setWarningMessage(t('Minimum {{minDays}} days required', { minDays }));
                } else {
                    setWarningMessage(t('Cannot extend to {{minDays}} days - unavailable dates in range', { minDays }));
                    setHasError(true);
                    return;
                }
            }
            // 4. Controllo massimo giorni
            else if (diffDays > maxDays) {
                const proposedEnd = newValue[0].add(maxDays - 1, "day");

                // Normalmente la riduzione non dovrebbe incontrare date occupate
                adjustedEndDate = proposedEnd;
                setWarningMessage(t('Maximum {{maxDays}} days allowed', { maxDays }));
            }

            // 5. Verifica giorni di recovery dopo la fine
            if (recoveryTime > 0) {
                const recoveryStart = adjustedEndDate.add(1, "day");
                const recoveryEnd = recoveryStart.add(recoveryTime - 1, "day");

                let recoveryDate = recoveryStart;
                let recoveryConflict = false;

                while (recoveryDate.isBefore(recoveryEnd) || recoveryDate.isSame(recoveryEnd, "day")) {
                    if (optionedDates.has(recoveryDate.format("YYYY-MM-DD")) ||
                        recoveryDates.has(recoveryDate.format("YYYY-MM-DD"))) {
                        recoveryConflict = true;
                        break;
                    }
                    recoveryDate = recoveryDate.add(1, "day");
                }

                if (recoveryConflict) {
                    setWarningMessage(
                        t('The vehicle needs {{recoveryTime}} recovery days after use, but these days are unavailable',
                            { recoveryTime })
                    );
                    setHasError(true);
                    return;
                }
            }

            // 6. Applica la modifica solo se tutto è valido
            setDateRange([newValue[0], adjustedEndDate]);
            setHasSelectionCompleted(true);

            if (newValue[0] && adjustedEndDate && !warningMessage) {
                setDialogOpen(true);
            }
        } else {
            // Caso selezione parziale (primo click)
            setDateRange(newValue);
        }
    }

    return (
        !drawable ? <Box className="form-element form-element-calendar-booking-widget col-12"><CircularProgress /></Box> :
            <Box className="form-element form-element-calendar-booking-widget col-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                        value={dateRange}
                        onChange={onChange}
                        shouldDisableDate={isDateDisabled}
                        minDate={bookabilityStart}
                        maxDate={bookabilityEnd}
                        slots={{ day: CustomPickersDay }}
                        slotProps={{
                            day: (ownerState) => {
                                return {
                                    selectedDays: ownerState.selectedDays || [],
                                    optionedDates: optionedDates,
                                    recoveryDates: recoveryDates,
                                    bookingsData: bookingsData,
                                    requiredStartDay: requiredStartDay,
                                    disabled: isDateDisabled(ownerState.day),
                                    hasError: hasError,
                                    onOpenBookingDialog: handleOpenBookingDialog
                                }
                            },
                        }}
                        disablePast
                        calendars={3}
                        sx={{
                            "& .MuiDateRangeCalendar-root": {
                                justifyContent: "center",
                            },

                            "& .MuiPickersSlideTransition-root": {
                                minHeight: "210px",
                            },

                            "& .MuiPickersDay-root": {
                                margin: "0px",
                            },

                        }}
                    />
                    <Box>{warningMessage}</Box>
                </LocalizationProvider>

                <BookingFormDialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onSave={handleSavedCalendar}
                    dateRange={dateRange}
                    warningMessage={warningMessage}
                    possibleDrivers={possibleDrivers}
                    calendar={calendar}
                />
            </Box>

    );
};

const CustomPickersDay = React.memo((props) => {
    const { t } = useTranslation();
    const {
        day,
        outsideCurrentMonth,
        selected,
        selectedDays = [],
        optionedDates = new Set(),
        recoveryDates = new Set(),
        bookingsData = {},
        disabled,
        hasError,
        onOpenBookingDialog,
        requiredStartDay = null,
        ...other
    } = props;

    const dateStr = useMemo(() => day.format("YYYY-MM-DD"), [day]);
    const bookingInfo = useMemo(() => bookingsData[dateStr], [bookingsData, dateStr]);
    const isOptioned = optionedDates.has(dateStr);
    const isRecovery = recoveryDates.has(dateStr);
    const [start, end] = useMemo(() => selectedDays.map(d => dayjs(d)), [selectedDays]);
    const isCompleteSelection = start && end;

    // Calcolo stati
    const isRangeStart = useMemo(() => selected && start?.isSame(day, 'day'), [selected, start, day]);
    const isRangeEnd = useMemo(() => selected && end?.isSame(day, 'day'), [selected, end, day]);
    const isInRange = useMemo(() => start && end && day.isAfter(start, 'day') && day.isBefore(end, 'day'), [start, end, day]);

    const canStartOnThisDay = useMemo(() => {
        if (isOptioned || isRecovery || requiredStartDay === null || isNaN(requiredStartDay) || (selectedDays[0] !== null && selectedDays[1] === null && !isInRange)) return true;
        if (disabled) return false;

        return day.day() === requiredStartDay;
    }, [day, selectedDays, requiredStartDay, isInRange]);

    const styles = useMemo(() => ({
        base: {
            borderRadius: 0,
            border: '1px solid transparent',
            '&&.Mui-selected': {},
            '&.Mui-disabled': {
                opacity: 1
            }
        },
        occupied: {
            color: 'white',
            backgroundColor: 'rgba(245, 124, 0, 0.8)',
            borderColor: 'rgba(245, 124, 0, 0.42)',
            pointerEvents: 'none'
        },
        recovering: {
            backgroundColor: 'rgba(245, 124, 0, 0.12)',
            borderColor: 'rgba(245, 124, 0, 0.12)',
            pointerEvents: 'none'
        },
        startForbidden: {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            color: 'rgba(0, 0, 0, 0.20)',
            pointerEvents: 'none'
        },
        errorRange: {
            backgroundColor: 'rgba(244, 67, 54, 0.12)',
            borderColor: 'rgba(244, 67, 54, 0.12)'
        },
        errorStartEnd: {
            backgroundColor: 'rgba(244, 67, 54, 1)',
            color: 'white'
        },
        normalRange: {
            backgroundColor: 'rgba(25, 118, 210, 0.12)',
            borderColor: 'rgba(25, 118, 210, 0.12)'
        },
        normalStartEnd: {
            backgroundColor: 'rgba(25, 118, 210, 1)',
            color: 'white'
        }
    }), []);

    const getStyle = useCallback((conditions) => {
        const { hasError, isInRange, isRangeStart, isRangeEnd, isOccupied, isRecovering, canStartOnThisDay } = conditions;
        const style = { ...styles.base };

        if (isOccupied) {
            Object.assign(style, styles.occupied);
        } else if (isRecovering) {
            Object.assign(style, styles.recovering);
        } else if (isRangeStart || isRangeEnd) {
            Object.assign(style, hasError ? styles.errorStartEnd : styles.normalStartEnd, {
                '&&.Mui-selected': {
                    backgroundColor: hasError ? styles.errorStartEnd.backgroundColor : styles.normalStartEnd.backgroundColor,
                    color: 'white',
                    borderTopLeftRadius: isRangeStart ? '50%' : 0,
                    borderBottomLeftRadius: isRangeStart ? '50%' : 0,
                    borderTopRightRadius: isRangeEnd ? '50%' : 0,
                    borderBottomRightRadius: isRangeEnd ? '50%' : 0
                }
            });
        } else if (isInRange) {
            Object.assign(style, hasError ? styles.errorRange : styles.normalRange);
        } else if (!canStartOnThisDay) {
            Object.assign(style, styles.startForbidden);
        }

        return style;
    }, [styles]);

    const tooltipContent = useMemo(() => {
        if (isOptioned && bookingInfo) {
            return (
                <Box>
                    <Box fontWeight="bold">
                        {bookingInfo.firstname} {bookingInfo.lastname}
                    </Box>
                    <Box fontSize="0.7rem" mt={0.5}>
                        {t('Booked at: {{date}}', { date: dayjs(bookingInfo.created_at).format("DD/MM/YYYY") })}
                    </Box>
                    {bookingInfo.notes && (
                        <Box fontSize="0.7rem" mt={1} fontStyle="italic">
                            {bookingInfo.notes}
                        </Box>
                    )}
                </Box>
            );
        }
        return null;
    }, [isOptioned, bookingInfo, t]);

    const handleDayClick = useCallback(() => {
        if (isOptioned || isRecovery || !canStartOnThisDay) return;

        if (isCompleteSelection && selected && !disabled) {
            onOpenBookingDialog();
        }
    }, [isCompleteSelection, selected, disabled, onOpenBookingDialog]);

    return (
        <Tooltip
            title={tooltipContent}
            arrow
            placement="top"
            enterDelay={300}
            leaveDelay={200}
            disableInteractive
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        bgcolor: 'rgba(97, 97, 97, 0.9)',
                        fontSize: '0.8rem',
                        maxWidth: '200px'
                    }
                }
            }}
        >
            <span>
                <PickersDay
                    {...other}
                    day={day}
                    outsideCurrentMonth={outsideCurrentMonth}
                    selected={selected}
                    disabled={disabled}
                    onClick={handleDayClick}
                    sx={{
                        ...getStyle({
                            hasError,
                            isInRange,
                            isRangeStart,
                            isRangeEnd,
                            isOccupied: isOptioned && !disabled,
                            isRecovering: isRecovery && !disabled,
                            canStartOnThisDay: canStartOnThisDay
                        }),
                        ...(isCompleteSelection && !disabled && {
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.05)'
                            }
                        }),
                    }}
                />
            </span>
        </Tooltip>
    );
});

export default WidgetCalendarBooking;