import React, { useState, useEffect, useContext } from 'react';
import { Detail as DetailComponent, Confirm, useDrawer, SplitButton } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {useLoadOne} from "src/mtska-frontend-data-provider/hooks/useLoadOne.hooks";

const dataProviderName = 'auth/user';

const Detail = (params) => {
    const { t } = useTranslation();
    const { close: closeDrawer } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success, reloadCounter } = useDataProvider(dataProviderName, params.multipart);

    const {modelName, detailConfig} = existingModules[dataProviderName]

    useLoadOne({loadOne, params, reloadCounter})

    const [editing, setEditing] = useState(false)

    const handleSave = (data) => {
        save({...data, filters: params.filters}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return (
        <>
            <DetailComponent
                values={{ ...item?.data }} 
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName+'.edit']}
                title={item?.data?.email ? item?.data?.email : t('New user')}
                rightButtons={[]}
                {...params}
            />
        </>
    )
}

export default Detail;


