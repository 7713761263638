import * as Views from "./views";
import * as Wizards from "./wizards";

const Module = {
    name: "cars",
    defaultRoutePath: "/cars/car",
    menuItemScope: "fleet",
    menuItemName: "vehicles",

    routes: [
        {
            path: "/cars/car",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
                {
                    path: "calculator",
                    Component: Views.Quotation,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        {
            path: "install",
            Component: Wizards.Install,
        },
        {
            path: "create",
            Component: Wizards.Create,
        },
        {
            path: "personAssociation",
            Component: Wizards.PersonAssociation,
        },
        {
            path: "personDissociation",
            Component: Wizards.PersonDissociation,
        },
        {
            path: "carCalendarManagement",
            Component: Wizards.CarCalendarManagement,
        },
        {
            path: "poolAssociation",
            Component: Wizards.PoolAssociation,
        },
        {
            path: "poolDissociation",
            Component: Wizards.PoolDissociation,
        },
        {
            path: "serviceAssociation",
            Component: Wizards.ServiceAssociation,
        },
        {
            path: "serviceDissociation",
            Component: Wizards.ServiceDissociation,
        },
        {
            path: "contractAssociation",
            Component: Wizards.ContractAssociation,
        },
        {
            path: "contractDissociation",
            Component: Wizards.ContractDissociation,
        },
        {
            path: "plateManagement",
            Component: Wizards.PlateManagement,
        },
        {
            path: "statusManagement",
            Component: Wizards.StatusManagement,
        },
        {
            path: "notificationSend",
            Component: Wizards.NotificationSend,
        },
        {
            path: "inspectionAccomplished",
            Component: Wizards.InspectionAccomplished,
        },
        {
            path: "carPictureAssociation",
            Component: Wizards.CarPictureAssociation,
        },
        {
            path: "deliveryReportAssociation",
            Component: Wizards.DeliveryReportAssociation,
        },
        {
            path: "returnReportAssociation",
            Component: Wizards.ReturnReportAssociation,
        },
        {
            path: "genericFileAssociation",
            Component: Wizards.GenericFileAssociation,
        },
        
    ],

}

export default Module;
